import React from 'react'
import Auth from '../../views/auth/Auth'

function AuthController() {
    return (
        <Auth />
    )
}

export default AuthController
