import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../instanceapi";

const initialState = {

}

export const getOperasionalBisnis = createAsyncThunk('operasional-bisnis/getOperasionalBisnis', async(page) => {
    try {
        const response = await instanceapi.get(`/operasional/bisnis?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const storeOperasionalBisnis = createAsyncThunk('operasional-bisnis/storeOperasionalBisnis', async(data) => {
    try {
        const response = await instanceapi.post('/operasional/bisnis/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const operasional_bisnis_m = createSlice({
    name: 'operasional-bisnis',
    initialState
})

export default operasional_bisnis_m.reducer;