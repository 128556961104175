import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from '../instanceapi'

const initialState = {

}

export const getFinancial = createAsyncThunk('financial/getFinancial', async(year) => {
    try {
        const response = await instanceapi.get(`/financial/year/${year}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getFinancialGrafikPendapatan = createAsyncThunk('financial/getFinancialGrafikPendapatan', async(year) => {
    try {
        const response = await instanceapi.get(`/financial/grafik/pendapatan/${year}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getFinancialAllData = createAsyncThunk('financial/getFinancialAllData', async(page) => {
    try {
        const response = await instanceapi.get(`/financial/all/data?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const financial_m = createSlice({
    name: 'financial',
    initialState
})

export default financial_m.reducer;