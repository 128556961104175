import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from '../instanceapi'


const initialState = {

}

export const storeReseller = createAsyncThunk('reseller/storeReseller', async(data) => {
    try {
        const response = await instanceapi.post('/reseller/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const reseller_m = createSlice({
    name: 'reseller',
    initialState
})

export default reseller_m.reducer;