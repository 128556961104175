import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../instanceapi";

const initialState = {

}

export const storeRkap = createAsyncThunk('rkap/storeRkap', async(data) => {
    try {
        const response = await instanceapi.post('/rkap/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getRkapStatusBisnisBiaya = createAsyncThunk('rkap/getRkapStatusBisnisBiaya', async(status_bisnis) => {
    try {
        const response = await instanceapi.get(`/rkap/get-status-bisnis-biaya/${status_bisnis}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const rkap_m = createSlice({
    name: 'rkap',
    initialState
})

export default rkap_m.reducer;