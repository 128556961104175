import React, { useEffect, useState } from 'react'
import Sales from '../../Sales'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ReplyAll } from '@mui/icons-material';
import { getSalesMarketingDetailTempo, storeSalesMarketingTempo } from '../../../../models/sales/marketing/sales_marketing_m';
import toast from 'react-hot-toast';
import Loading from '../../../loading/Loading';
import Moment from 'react-moment';

import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { Button, TextField } from '@mui/material';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="Rp. "
        />
    );
});

NumericFormatCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const SalesMarketingTempoDetail = () => {
    const dispatch = useDispatch();
    const { username, code } = useParams();
    const url_back = `/${username}/sales/marketing/tempo`

    const onBack = () => {
        return window.location.href = url_back
    }

    const [loading, setLoading] = useState(false);
    const [sales, setSales] = useState([]);
    const [salesTempo, setSalesTempo] = useState([]);

    const [dataList, setDataList] = useState([])

    const getDetail = async() => {
        try {
            const response = await dispatch(getSalesMarketingDetailTempo(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setSales(response.data['sales']);
                const list = response.data['sales_tempo'];
                setSalesTempo(list.map((data) => ({...data, nominal_bayar: 0})));
            }

            if (response.status === 404) {
                setLoading(true);
                toast.error('Data yang Anda Cari Tidak Ditemukan')
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }

        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail()
    }, [])

    const onBayarData = (id) => {
        const data = salesTempo.map(obj => {
            if (obj.id === id) {
                setDataList([...dataList, {...obj, nominal_bayar: obj.nominal}])
                return {...obj, status: true, nominal_bayar: obj.nominal, terbayar: obj.nominal}
            } else {
                return {...obj, status: false}
            }
        })
        setSalesTempo(data)
    }


    const total = salesTempo.reduce((i, j) => i + parseFloat(j.nominal_bayar), 0)
    const terbayar_total = salesTempo.reduce((i, j) => i + parseFloat(j.terbayar), 0);
    const nominal_total = salesTempo.reduce((i, j) => i + parseFloat(j.nominal), 0);

    const [input, setInput] = useState({
        nominal_bayar: '', file: ''
    });

    const [validation, setValidation] = useState({
        status: false, message: ''
    })
    const handleChangeNominalBayar = (e) => {
        if (e.target.value !== '') {
            const nominal = e.target.value;

            if (total === parseFloat(nominal)) {
                setInput({...input, nominal_bayar: nominal})
                setValidation({status: false, message: ''})
            } else {
                setInput({...input, nominal_bayar: ''})
                setValidation({
                    status: true, message: `Nominal yang Harus Dibayarkan ${total}`
                })
            }
        }
    }

    const onSubmit = async(e) => {
        e.preventDefault();

        setLoading(true);

        let status_pembayaran;
        if (nominal_total === terbayar_total) {
            status_pembayaran = 'lunas'
        } else {
            status_pembayaran = 'belum'
        }

        const data = {
            list: {...dataList},
            code: code,
            file: input.file,
            status_pembayaran: status_pembayaran,
            nominal_bayar: input.nominal_bayar
        }

        try {
            const response = await dispatch(storeSalesMarketingTempo(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Pembayaran Tempo Penjualan Berhasil')
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const onCancelData = () => {
        setLoading(true)
        toast.success('Tempo Penjualan Berhasil Dibatalkan')
        setTimeout(() => {
            return window.location.href = url_back
        }, 1200);
    }

    return (
        <Sales>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3 className="text-uppercase">Detail Tempo Penjualan { code }</h3>
                                <span>Detail Tempo Penjualan Marketing</span>
                            </div>
                            <div>
                                <span onClick={onBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onSubmit} className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control fw-bold">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">Tanggal Pembayaran</span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">Nominal</span>
                                                </th>
                                                {
                                                    sales.status_pembayaran === 'lunas' ? 
                                                    <>
                                                         <th>
                                                            <span className="form-control fw-bold">Terbayar</span>
                                                        </th>
                                                        <th className="table-border-end text-center" style={{width: '10%'}}>
                                                            <span className="form-control fw-bold">Lihat File</span>
                                                        </th>
                                                    </> : 
                                                    <>
                                                        <th className="table-border-end">
                                                            <span className="form-control fw-bold">Terbayar</span>
                                                        </th>
                                                        <th className="table-border-end text-center" style={{width: '10%'}}>
                                                            <span className="form-control fw-bold">Action</span>
                                                        </th>
                                                    </>
                                                }
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                salesTempo.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{ index + 1 }</td>
                                                        <td>
                                                            <Moment format="dddd, DD MMMM YYYY">{ data.tgl_pembayaran }</Moment>
                                                        </td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.nominal } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            /> 
                                                        </td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.terbayar } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            /> 
                                                        </td>
                                                        {
                                                            sales.status_pembayaran === 'lunas' ? 
                                                                <td className="text-center">
                                                                    <a href={ data.file } target="_blank">Lihat File</a>
                                                                </td>
                                                            :
                                                            <td className="text-center">
                                                                <Button onClick={() => onBayarData(data.id)} disabled={ data.nominal === data.terbayar || data.status ? true : false } size="small" variant="contained" color="primary">
                                                                    Bayar
                                                                </Button>
                                                            </td>
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-price mb-3">
                                    <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                        <span>
                                            Total Transaksi
                                        </span>
                                        <span>
                                            { code }
                                        </span>
                                    </div>

                                    <div className="text-center price-total">
                                        <NumericFormat 
                                            value={ sales.status_pembayaran ? sales.nominal_bayar : total } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </div>
                                </div>

                                {
                                    sales.status_pembayaran === 'belum' ? 
                                    <>
                                        <div className="mb-3">
                                            <label className="form-label">Nominal</label>
                                            <TextField
                                                error={ validation.status ? true : false }
                                                fullWidth
                                                size="small"
                                                value={input.nominal}
                                                onChange={handleChangeNominalBayar}
                                                placeholder="Rp. "
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom,
                                                }}
                                                required
                                            />
                                            <small className="text-danger">{ validation.status ? validation.message : '' }</small>
                                        </div>
                                        
                                        <div className="mb-3">
                                            <label className="form-label">Upload File</label>
                                            <input type="file" className="form-control" onChange={(e) => setInput({...input, file: e.target.files[0]})} required />
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <Button onClick={onCancelData} fullWidth variant="contained" color="error">
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <Button fullWidth disabled={ parseFloat(input.nominal_bayar) === total ? false : true } variant="contained" color="success" type="submit">
                                                    Bayar
                                                </Button>
                                            </div>
                                        </div>
                                    </> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Sales>
    )
}

export default SalesMarketingTempoDetail
