import React, { useEffect, useState } from 'react'
import MitraMarketing from '../MitraMarketing'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, Button, MenuItem, Select, TextField } from '@mui/material';
import Loading from '../../../loading/Loading';
import { imageResizer } from '../../../../utils';
import { storeMarketing } from '../../../../models/marketing/marketing_m';
import toast from 'react-hot-toast';
import { getEmployeeByName } from '../../../../models/employee/employee_m';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const MitraMarketingForm = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);
    const { username } = useParams();

    const [employee, setEmployee] = useState([]);
    const getDataKaryawan = async(name) => {
        try {
            const response = await dispatch(getEmployeeByName(name)).unwrap().catch((err) => {});
            setEmployee(response.data.map((i) => ({ ...i, label: i.name })))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataKaryawan('a');
    }, []);

    const code_marketing= `MRT${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}`
    const [input, setInput] = useState({
        code: code_marketing, status: '', join_date: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)}-${new Date().getDate()}`, 
        name: '', no_identity: '', address: '', email: '',
        website: '', contact_primary: '', contact_name: '', contact_secondary: '', image: '',
        desc: '', docs: ''
    })

    const handleSearchEmployee = (e) => {
        if (e.target.value !== '') {
            return getDataKaryawan(e.target.value)
        } else {
            return getDataKaryawan('a')
        }
    }

    const handleChangeEmployee = (_, event) => {
        if (event) {
            setInput({
                ...input, name: event.label, address: event.address, 
                email: `${event.email ? event.email : ''}`, contact_primary: event.phone, 
                no_identity: event.nip
            })
        }
    }

    const handleChangeStatus = (e) => {
        if (e.target.value !== '') {
            setInput({
                code: code_marketing, status: e.target.value, join_date: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)}-${new Date().getDate()}`, 
                name: '', no_identity: '', address: '', email: '',
                website: '', contact_primary: '', contact_name: '', contact_secondary: '', image: '',
                desc: '', docs: ''
            })
        }
    } 

    const handlePhoto = async(e) => {
        const file = e.target.files[0];
        const resizer = await imageResizer(file, 240, 240);
        setInput({...input, image: resizer});
    }

    const nameKaryawan = (
        <>
            <div>
                <label className="form-label form-label-m-0">Nama Karyawan <span className="text-danger">*</span></label>
                <Autocomplete 
                    error={ error.name ? true : false }
                    size="small"
                    disableClearable
                    fullWidth
                    options={employee}
                    value={input.name}
                    renderInput={(params) => <TextField {...params} onChange={handleSearchEmployee} required placeholder="Cari karyawan" />}
                    onChange={handleChangeEmployee}
                />
                <small className="text-danger">{ error.name ? error.name : '' }</small>
            </div>
        </>
    )

    const nameCompany = (
        <>
            <div>
                <label className="form-label form-label-m-0">Nama Perusahaan <span className="text-danger">*</span></label>
                <TextField 
                    error={ error.name ? true : false }
                    size="small"
                    fullWidth
                    placeholder="Tulis Nama Perusahaan"
                    onChange={(e) => setInput({...input, name:e.target.value})}
                    required
                />
                <small className="text-danger">{ error.name ? error.name : '' }</small>
            </div>
        </>
    )

    const nameIndividu = (
        <>
            <div>
                <label className="form-label form-label-m-0">Nama Marketing <span className="text-danger">*</span></label>
                <TextField 
                    error={ error.name ? true : false }
                    size="small"
                    fullWidth
                    placeholder="Tulis Nama Marketing"
                    onChange={(e) => setInput({...input, name:e.target.value})}
                    required
                />
                <small className="text-danger">{ error.name ? error.name : '' }</small>
            </div>
        </>
    )

    // form
    const formKaryawan = (
        <>
            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Nama Karyawan</label>
                            <TextField 
                                size="small"
                                fullWidth
                                value={input.name}
                                disabled
                            />
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Alamat</label>
                            <TextField 
                                size="small"
                                fullWidth
                                value={input.address}
                                disabled
                            />
                        </div>
                        <div>
                            <label className="form-label form-label-m-0">Nomor Telephone</label>
                            <TextField 
                                size="small"
                                fullWidth
                                value={input.contact_primary}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">NIP</label>
                            <TextField 
                                size="small"
                                fullWidth
                                value={input.no_identity}
                                disabled
                            />
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Email</label>
                            <TextField 
                                size="small"
                                fullWidth
                                type="email"
                                value={input.email}
                                disabled={ input.email ? true : false }
                            />
                        </div>
                        <div>
                            <label className="form-label form-label-m-0">Website</label>
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Tulis Website"
                                onChange={(e) => setInput({...input, website: e.target.value})}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Nama Kontak Darurat <span className="text-danger">*</span></label>
                            <TextField 
                                error={ error.contact_name ? true : false }
                                size="small"
                                fullWidth
                                placeholder="Tulis Nama Kontak Darurat"
                                value={input.contact_name}
                                onChange={(e) => setInput({...input, contact_name: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.contact_name ? error.contact_name : '' }</small>
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Kontak Darurat <span className="text-danger">*</span></label>
                            <PhoneInput
                                country={'id'}
                                onChange={phone => setInput({...input, contact_secondary: phone})}
                                inputProps={{
                                    required: true,
                                    autoFocus: true
                                }}
                                placeholder='+62 858 *** *** ***'
                            />
                            <small className="text-danger">{ error.contact_secondary ? error.contact_secondary: '' }</small>
                        </div>
                        <div>
                            <label className="form-label form-label-m-0">Upload Foto</label>
                            <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={handlePhoto} className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const formCompany = (
        <>
            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Tanggal Bergabung</label>
                            <TextField 
                                size="small"
                                fullWidth
                                value={`${new Date().toLocaleString() + ''}`}
                                disabled
                            />
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">NIB <span className="text-danger">*</span></label>
                            <TextField 
                                error={ error.no_identity ? true : false }
                                size="small"
                                fullWidth
                                value={input.no_identity}
                                placeholder="Tulis NIB Perusahaan"
                                onChange={(e) => setInput({...input, no_identity: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.no_identity ? error.no_identity : '' }</small>
                        </div>
                        <div>
                            <label className="form-label form-label-m-0">Alamat <span className="text-danger">*</span></label>
                            <TextField 
                                error={ error.address ? true : false }
                                size="small"
                                fullWidth
                                value={input.address}
                                placeholder="Tulis Alamat Perusahaan"
                                onChange={(e) => setInput({...input, address: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.address ? error.address : '' }</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Email <span className="text-danger">*</span></label>
                            <TextField 
                                error={ error.email ? true : false }
                                size="small"
                                fullWidth
                                type="email"
                                value={input.email}
                                placeholder="Tulis Email Perusahaan"
                                onChange={(e) => setInput({...input, email: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.email ? error.email : '' }</small>
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Website</label>
                            <TextField 
                                size="small"
                                fullWidth
                                value={input.website}
                                placeholder="Tulis Website Perusahaan"
                                onChange={(e) => setInput({...input, website: e.target.value})}
                            />
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Kontak Perusahaan <span className="text-danger">*</span></label>
                            <PhoneInput
                                country={'id'}
                                onChange={phone => setInput({...input, contact_primary: phone})}
                                inputProps={{
                                    required: true,
                                    autoFocus: true
                                }}
                                placeholder='+62 858 *** *** ***'
                            />
                            <small className="text-danger">{ error.contact_primary ? error.contact_primary : '' }</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Nama PIC <span className="text-danger">*</span></label>
                            <TextField 
                                error={ error.contact_name ? true : false }
                                size="small"
                                fullWidth
                                placeholder="Tulis Nama PIC"
                                value={input.contact_name}
                                onChange={(e) => setInput({...input, contact_name: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.contact_name ? error.contact_name : '' }</small>
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Kontak PIC <span className="text-danger">*</span></label>
                            <PhoneInput
                                country={'id'}
                                onChange={phone => setInput({...input, contact_secondary: phone})}
                                inputProps={{
                                    required: true,
                                    autoFocus: true
                                }}
                                placeholder='+62 858 *** *** ***'
                            />
                            <small className="text-danger">{ error.contact_secondary ? error.contact_secondary: '' }</small>
                        </div>
                        <div>
                            <label className="form-label form-label-m-0">Upload Foto</label>
                            <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={handlePhoto} className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const formIndividu = (
        <>
            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Tanggal Bergabung</label>
                            <TextField 
                                size="small"
                                fullWidth
                                value={`${new Date().toLocaleString() + ''}`}
                                disabled
                            />
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">NIK <span className="text-danger">*</span></label>
                            <TextField 
                                error={ error.no_identity ? true : false }
                                size="small"
                                fullWidth
                                value={input.no_identity}
                                placeholder="Tulis NIK"
                                onChange={(e) => setInput({...input, no_identity: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.no_identity ? error.no_identity : '' }</small>
                        </div>
                        <div>
                            <label className="form-label form-label-m-0">Alamat <span className="text-danger">*</span></label>
                            <TextField 
                                error={ error.address ? true : false }
                                size="small"
                                fullWidth
                                value={input.address}
                                placeholder="Tulis Alamat"
                                onChange={(e) => setInput({...input, address: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.address ? error.address : '' }</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Email </label>
                            <TextField 
                                error={ error.email ? true : false }
                                size="small"
                                fullWidth
                                type="email"
                                value={input.email}
                                placeholder="Tulis Email"
                                onChange={(e) => setInput({...input, email: e.target.value})}
                            />
                            <small className="text-danger">{ error.email ? error.email : '' }</small>
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Website</label>
                            <TextField 
                                size="small"
                                fullWidth
                                value={input.website}
                                placeholder="Tulis Website"
                                onChange={(e) => setInput({...input, website: e.target.value})}
                            />
                        </div>
                        <div>
                            <label className="form-label form-label-m-0">Kontak Umum <span className="text-danger">*</span></label>
                            <PhoneInput
                                country={'id'}
                                onChange={phone => setInput({...input, contact_primary: phone})}
                                inputProps={{
                                    required: true,
                                    autoFocus: true
                                }}
                                placeholder='+62 858 *** *** ***'
                            />
                            <small className="text-danger">{ error.contact_primary ? error.contact_primary : '' }</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Nama Kontak Darurat <span className="text-danger">*</span></label>
                            <TextField 
                                error={ error.contact_name ? true : false }
                                size="small"
                                fullWidth
                                placeholder="Tulis Nama Kontak Darurat"
                                value={input.contact_name}
                                onChange={(e) => setInput({...input, contact_name: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.contact_name ? error.contact_name : '' }</small>
                        </div>
                        <div className="mb-2">
                            <label className="form-label form-label-m-0">Kontak Darurat <span className="text-danger">*</span></label>
                            <PhoneInput
                                country={'id'}
                                onChange={phone => setInput({...input, contact_secondary: phone})}
                                inputProps={{
                                    required: true,
                                    autoFocus: true
                                }}
                                placeholder='+62 858 *** *** ***'
                            />
                            <small className="text-danger">{ error.contact_secondary ? error.contact_secondary : '' }</small>
                        </div>
                        <div>
                            <label className="form-label form-label-m-0">Upload Foto</label>
                            <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={handlePhoto} className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        const data = {...input}

        try {
            const response = await dispatch(storeMarketing(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Data berhasil di simpan')
                setTimeout(() => {
                    return window.location.href = `/${username}/mitra/marketing/data`
                }, 1200);
            }

            if (response.status === 400) {
                setLoading(false)
                toast.error('Periksa kembali data Anda')
                setError(response.message);
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }

    return (
        <MitraMarketing>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Kode Marketing <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            value={input.code}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Status Marketing <span className="text-danger">*</span></label>
                                        <Select
                                            size="small"
                                            fullWidth
                                            value={input.status}
                                            onChange={handleChangeStatus}
                                            required
                                            displayEmpty
                                        >
                                            <MenuItem value="" disabled>Pilih Status</MenuItem>
                                            <MenuItem value="company">Perusahaan</MenuItem>
                                            <MenuItem value="individu">Perorangan</MenuItem>
                                            <MenuItem value="employee">Karyawan</MenuItem>
                                        </Select>
                                    </div>
                                    {
                                        input.status === 'company' ? 
                                        nameCompany : 
                                        input.status === 'individu' ? 
                                        nameIndividu : 
                                        input.status === 'employee' ? 
                                        nameKaryawan : ''
                                    }
                                </div>
                            </div>
                        </div>

                        {
                            input.status === 'employee' ? formKaryawan :
                            input.status === 'company' ? formCompany : 
                            input.status === 'individu' ? formIndividu : ''
                        }

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Upload Dokumen</label>
                                        <input type="file" className="form-control" onChange={(e) => setInput({...input, docs: e.target.files[0]})} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Keterangan</label>
                                        <textarea placeholder="Tulis Keterangan" style={{height: '50px'}} onChange={(e) => setInput({...input, desc: e.target.value})} className="form-control"></textarea>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button variant="contained" color="primary" type="submit">
                                            Simpan Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MitraMarketing>
    )
}

export default MitraMarketingForm
