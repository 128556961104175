import React, { useEffect, useState } from 'react'
import RkapVariable from '../RkapVariable'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getRkapVariable } from '../../../../models/rkap/rkap_variable_m';
import Paginate from '../../../pagination/Paginate';

const RkapVariableData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [variable, setVariable] = useState([]);
    const [pagination, setPagination] = useState([]);

    const getData = async(page) => {
        try {
            const response = await dispatch(getRkapVariable(page)).unwrap().catch((err) => {});
            setVariable(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);

    const handlePagination = (_, page) => {
        return getData(page);
    }

    const onDetail = (code) => {
        return navigate(`${code}`)
    }
    
    return (
        <RkapVariable>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DATA VARIABEL RKAP</h3>
                                <span>Data Variabel RKAP</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <div className="table-responsive mb-3">
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                        <span className="form-control fw-bold">No</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Kode Variabel</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Nama Variabel</span>
                                    </th>
                                    <th className="text-center" style={{width: '15%'}}>
                                        <span className="form-control fw-bold">Status Bisnis</span>
                                    </th>
                                    <th className="table-border-end text-center" style={{width: '15%'}}>
                                        <span className="form-control fw-bold">Status Kas</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    variable.length > 0 ?
                                    variable.map((data) => (
                                        <tr key={data.no}>
                                            <td className="text-center">{ data.no }</td>
                                            <td>{data.code}</td>
                                            <td>{data.name}</td>
                                            <td className="text-center">
                                                {
                                                    data.status_bisnis === 'bisnis' ? 
                                                    <span className="badge bg-green">Bisnis</span> :
                                                    <span className="badge bg-primary">Non Bisnis</span>
                                                }
                                            </td>
                                            <td className="text-center">
                                                {
                                                    data.status_kas === 'masuk' ? 
                                                    <span className="badge bg-green">Uang Masuk</span> :
                                                    <span className="badge bg-orange">Uang Keluar</span>
                                                }
                                            </td>
                                        </tr>
                                    )) : 
                                    <tr>
                                        <th colSpan={5} className="text-center">DATA TIDAK DITEMUKAN</th>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* pagination */}
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <span>Halaman : { pagination.current_page }</span>
                        </div>
                        {
                            pagination.pagination ? 
                            <Paginate count={ pagination.total } page={pagination.current_page} onChange={ handlePagination } /> : ''
                        }
                        <div>
                            <span>Total : { pagination.total } Data Variabel</span>
                        </div>
                    </div>
                </div>
            </div>
        </RkapVariable>
    )
}

export default RkapVariableData
