import React, { useState } from 'react'
import RkapVariable from '../RkapVariable'
import { Autocomplete, Button, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { storeRkapVariable } from '../../../../models/rkap/rkap_variable_m'
import toast from 'react-hot-toast'
import Loading from '../../../loading/Loading'

const status_bisnis = [
    { code: 'bisnis', label: 'Bisnis' },
    { code: 'non', label: 'Non Bisnis' }
]

const status_kas = [
    { code: 'masuk', label: 'Uang Masuk' },
    { code: 'keluar', label: 'Uang Keluar' }
]

const RkapVariableForm = () => {
    const dispatch = useDispatch();
    const { username } = useParams();

    const date = new Date();
    const code = `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);
    const [input, setInput] = useState({
        code: `VR${code}`, name: '', status_kas: '', status_bisnis: ''
    })

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data = {...input}
            const response = await dispatch(storeRkapVariable(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Variabel RKAP berhasil ditambahkan')
                setTimeout(() => {
                    return window.location.href = `/${username}/rkap/variable/data`
                }, 1200);
            }

            if (response.status === 400) {
                toast.error('Periksa kembali data Anda')
                setLoading(false);
                setError(response.message)
                setTimeout(() => {
                    setError([]);
                }, 1500);
            }
        } catch (error) {
            
        }

    }

    return (
        <RkapVariable>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>INPUT VARIABEL RKAP</h3>
                                <span>Input Variabel RKAP</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onSubmit} className="mt-3">
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Kode Variabel</label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        value={input.code}
                                        disabled
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Nama Variabel</label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Nama Variabel"
                                        value={input.name}
                                        onChange={(e) => setInput({...input, name: e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Status Bisnis</label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={status_bisnis}
                                        isOptionEqualToValue={(options, value) => options.label === value.label}
                                        renderInput={(params) => <TextField {...params} placeholder="Status Bisnis" required/>}
                                        onChange={(_, event) => setInput({...input, status_bisnis: event.code})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Status Kas</label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        options={status_kas}
                                        disableClearable
                                        isOptionEqualToValue={(options, value) => options.label === value.label}
                                        renderInput={(params) => <TextField {...params} placeholder="Status Kas" required />}
                                        onChange={(_, event) => setInput({...input, status_kas: event.code})}
                                    />
                                </div>

                                <div className="d-flex justify-content-end">
                                    <Button variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </RkapVariable>
    )
}

export default RkapVariableForm
