import { Close } from '@mui/icons-material';
import { Backdrop, Box, Fade, Modal, TextField } from '@mui/material';
import React from 'react'

import { NumericFormat } from 'react-number-format';

const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    outline: 0,
};

const SalesReturChart = ({ open, onClose, returList, onUpdateQty, onCancelCart }) => {
    return (
        <Modal
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box style={style}>
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="d-flex justify-content-between align-items-start mb-3">
                                    <div>
                                        <h3>List Retur Penjualan</h3>
                                    </div>
                                    <div onClick={onClose} style={{cursor: 'pointer'}}>
                                        <Close sx={{color: '#d63939'}} />
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start">
                                                    <span className="form-control">Kode Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Nama Produk</span>
                                                </th>
                                                <th style={{width: '10%'}}>
                                                    <span className="form-control">Jumlah Retur</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Harga Satuan</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Sub Total</span>
                                                </th>
                                                <th className="table-border-end text-center" style={{width: '8%'}}>
                                                    <span className="form-control">Action</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                returList.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>{data.code_product}</td>
                                                        <td>{data.product}</td>
                                                        <td>
                                                            <TextField 
                                                                size="small"
                                                                fullWidth
                                                                defaultValue={data.qty}
                                                                type="number"
                                                                onChange={(e) =>onUpdateQty(e, data.code_product)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.unit_price } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.sub_total } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <button onClick={() =>onCancelCart(data.code_product, index)} className="btn btn-danger btn-sm">Cancel</button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default SalesReturChart
