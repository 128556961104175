import React, { useEffect, useState } from 'react'
import WarehouseSpoil from '../WarehouseSpoil'
import { useDispatch } from 'react-redux'
import { Autocomplete, Button, TextField } from '@mui/material';
import { getSearchCategoryByCodeName } from '../../../../models/product/product_category_m';
import { getSearchProductByCategoryCodeName } from '../../../../models/product/product_m';

import { imageResizer } from '../../../../utils'
import { storeWarehouseSpoil } from '../../../../models/warehouse/warehouse_spoil_m';
import { getEmployeeByNameJabatan } from '../../../../models/employee/employee_m';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import Loading from '../../../loading/Loading'

const WarehouseSpoilForm = () => {
    const dispatch = useDispatch();
    const { username } = useParams();

    const [category, setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [employee, setEmployee] = useState([]);

    const getDataCategory = async(search) => {
        try {
            const response = await dispatch(getSearchCategoryByCodeName(search)).unwrap().catch((err) => {});
            setCategory(response.data.map((data) => ({ code: data.code, label: data.name })))
        } catch (error) {
            
        }
    }

    const getDataProduct = async(data) => {
        try {
            const response = await dispatch(getSearchProductByCategoryCodeName(data)).unwrap().catch((err) => {});
            setProduct(response.data.map((data) => ({ code: data.code, label: data.name, unit_price: data.harga_beli, status_ppn: data.status_ppn })))
        } catch (error) {
            
        }
    }

    const getDataEmployee = async(name) => {
        try {
            const data = {
                jabatan: 'Staf Sub Pergudangan',
                name: name
            }
            const response = await dispatch(getEmployeeByNameJabatan(data)).unwrap().catch((err) => {});
            setEmployee(response.data.map((i) => ({ code: i.code, label: i.name })));
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataCategory('-');
    }, []);

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const time = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
    const waktu = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

    const date_now = date.toLocaleString() + '';

    const [input, setInput] = useState({
        code: `SPL${day}${month}${year}${time}`, tgl: `${year}-${month}-${day}`,
        code_employee: '', employee: '',
        desc: '', file: ''
    })
    const [search, setSearch] = useState({
        code_category: '', category: '', code_product: '', product: '',
        qty: '', unit_price: '', status_ppn: ''
    })

    const [loading, setLoading] = useState(false);

    // handle search
    const handleSearchCategory = (e) => {
        if (e.target.value !== '') {
            return getDataCategory(e.target.value);
        }
    }
    const handleSearchEmployee = (e) => {
        if (e.target.value !== '') {
            return getDataEmployee(e.target.value);
        }
    } 

    // handle change
    const handleChangeCategory = (_, event) => {
        if (event) {
            setSearch({...search, code_category: event.code, category: event.label, code_product: '', product: ''})
            const data = {
                code_category: event.code,
                code_name_search: '-'
            }
            return getDataProduct(data)
        }
    }

    const handleChangeProduct = (_, event) => {
        setSearch({...search, code_product: event.code, product: event.label, unit_price: event.unit_price, status_ppn: event.status_ppn});
    }
    const handleChangeFile = async(e) => {
        const file = e.target.files[0];
        const resizer = await imageResizer(file, 480, 480);
        setInput({...input, file: resizer})
    } 

    // add
    const [list, setList] = useState([]);
    const onAddData = () => {
        const isFound = list.some(obj => {
            if (obj.code_product === search.code_product) {
                return true
            }
            return false
        })
        
        if (isFound === true) {
            alert('Data sudah ada');
        } else {
            let sub_total;
            let ppn_price;
            if (search.status_ppn === 'ppn') {
                ppn_price = (11 / 100) * (search.qty * search.unit_price);
                sub_total = (search.qty * search.unit_price) + ppn_price;
            } else {
                sub_total = search.qty * search.unit_price;
                ppn_price = 0;
            }
            setList([
                ...list,
                {
                    code_product: search.code_product,
                    code_category: search.code_category,
                    product: search.product,
                    category: search.category,
                    qty: search.qty,
                    unit_price: search.unit_price,
                    sub_total: sub_total,
                    status_ppn: search.status_ppn,
                    ppn_price: ppn_price
                }
            ])
            setSearch({
                code_category: '', category: '', code_product: '', product: '', qty: '', unit_price: '',
                status_ppn: ''
            })
            
        }
    }

    const onDelete = (index) => {
        const newLists = Array.from(list);
        newLists.splice(index, 1);
        setList(newLists);
    }

    let nominal;
    if (list.length > 0) {
        nominal = list.reduce((i, j) => i + j.sub_total, 0);
    }

    const onSubmit = async(e) => {
        e.preventDefault();

        setLoading(true);
        const data = {
            code: input.code,
            date: input.tgl,
            code_employee: input.code_employee,
            employee: input.employee,
            desc: input.desc,
            file: input.file,
            nominal: nominal,
            spoil_lists: {...list}
        }

        try {
            const response = await dispatch(storeWarehouseSpoil(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Data berhasil disimpan');
                setTimeout(() => {
                   return window.location.href = `/${username}/warehouse/spoil/validasi` 
                }, 1200);
            }

            if (response.status === 400) {
                setLoading(false);
                toast.error('Periksa kembali data Anda')
            }
        } catch (error) {
            
        }
    }

    return (
        <WarehouseSpoil>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>KASIR SPOIL PERGUDANGAN</h3>
                                <span>Kasir Spoil Pergudangan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-9 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-in mb-2">
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <label className="form-label form-label-m-0">Kode Spoil</label>
                                                <TextField 
                                                    size="small"
                                                    fullWidth
                                                    value={input.code}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <label className="form-label form-label-m-0">Tanggal</label>
                                                <TextField 
                                                    size="small"
                                                    fullWidth
                                                    value={date_now}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <label className="form-label form-label-m-0">Petugas</label>
                                                <Autocomplete 
                                                    size="small"
                                                    fullWidth
                                                    disableClearable
                                                    options={employee}
                                                    renderInput={(params) => <TextField {...params} placeholder="Pegawai" onChange={handleSearchEmployee} required />}
                                                    onChange={(_, event) => setInput({...input, code_employee: event.code, employee: event.label})}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-in mb-3">
                                        <div className="row align-items-end">
                                            <div className="col-md-3 mb-2">
                                                <label className="form-label form-label-m-0">Kategori Produk</label>
                                                <Autocomplete 
                                                    size="small"
                                                    fullWidth
                                                    options={category}
                                                    value={search.category}
                                                    renderInput={(params) => <TextField {...params} onChange={handleSearchCategory} placeholder="Kategori" />}
                                                    disableClearable
                                                    onChange={handleChangeCategory}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label form-label-m-0">Produk</label>
                                                <Autocomplete 
                                                    size="small"
                                                    fullWidth
                                                    options={product}
                                                    value={search.product}
                                                    disableClearable
                                                    renderInput={(params) => <TextField {...params} placeholder="Produk" />}
                                                    onChange={handleChangeProduct}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-2">
                                                <label className="form-label form-label-m-0">Jumlah</label>
                                                <TextField 
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                    placeholder="Jml"
                                                    value={search.qty}
                                                    onInput={(e) => {
                                                        const target = e.target;
                                                        target.value = e.target.value.replace(/[^0-9]/g, "");
                                                    }}
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0,
                                                        },
                                                    }}
                                                    onChange={(e) => setSearch({...search, qty: e.target.value})}
                                                />
                                            </div>

                                            <div className="col-md-1 mb-2">
                                                <Button onClick={onAddData} type="button" sx={{padding: '8.5px 14px'}} fullWidth color="primary" variant="contained">
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                                        <span className="form-control">No</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Kode Produk</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Nama Produk</span>
                                                    </th>
                                                    <th style={{width: '10%'}} className="text-center">
                                                        <span className="form-control">Total Spoil</span>
                                                    </th>
                                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                                        <span className="form-control">Action</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{index + 1}</td>
                                                            <td>{data.code_product}</td>
                                                            <td>{data.product}</td>
                                                            <td className="text-center">{data.qty}</td>
                                                            <td className="text-center">
                                                                <button onClick={() => onDelete(index)} className="btn btn-danger btn-sm">Cancel</button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Keterangan</label>
                                        <textarea style={{height: '80px'}} className="form-control" onChange={(e) => setInput({...input, desc: e.target.value})} placeholder="Tulis Keterangan"></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Bukti File</label>
                                        <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={handleChangeFile} required className="form-control" />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button disabled={ list.length === 0 ? true : false } variant="contained" color="primary" type="submit">
                                            Simpan Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </WarehouseSpoil>
    )
}

export default WarehouseSpoilForm
