import React, { useEffect, useState } from 'react'
import SalesRetur from '../SalesRetur'
import { useParams } from 'react-router-dom'
import { ReplyAll } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { getSalesMarketingReturDetail, postSalesMarketingReturCancel, updateSalesMarketingValidasi } from '../../../../models/sales/marketing/sales_marketing_retur_m';
import toast from 'react-hot-toast';

import { NumericFormat } from 'react-number-format';

import Loading from '../../../loading/Loading'
import Moment from 'react-moment';
import { Button } from '@mui/material';

const SalesReturValidasiDetail = () => {
    const { username, code } = useParams();
    const dispatch = useDispatch();

    const handleBack = () => {
        return window.location.href = `/${username}/sales/retur/validasi`
    }

    const [retur, setRetur] = useState([]);
    const [returLists, setReturLists] = useState([]);
    const [loading, setLoading] = useState(false);


    const getDetail = async() => {
        try {
            const response = await dispatch(getSalesMarketingReturDetail(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setRetur(response.retur);
                setReturLists(response.retur_lists.map((data) => ({...data, sub_total: data.qty * data.unit_price})))
            }

            if (response.status === 404) {
                toast.error('Data tidak ditemukan')
                setLoading(true);
                setTimeout(() => {
                    return window.location.href = `/${username}/sales/retur/validasi`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail();
    }, []);

    let ppn_price;
    let sub_total = returLists.reduce((i, j) => i + j.sub_total, 0);
    if (retur.status_ppn === 'ppn') {
        ppn_price = (11 / 100) * sub_total;
    } else {
        ppn_price = 0;
    }

    const [input, setInput] = useState({
        desc_cancel: ''
    })

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const onValidasi = async() => {
        setLoading(true)
        try {
            const data = {
                code: code,
                month: month,
                year: year
            }
            const response = await dispatch(updateSalesMarketingValidasi(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Retur berhasil di validasi')
                setTimeout(() => {
                    return window.location.href = `/${username}/sales/retur/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const onCancel = async() => {
        setLoading(true)
        if (input.desc_cancel === '') {
            setLoading(false)
            alert('Jika di tolak silahkan masukkan keterangan')
        } else {
            try {
                const data = {
                    code: code,
                    desc_cancel: input.desc_cancel
                }
                const response = await dispatch(postSalesMarketingReturCancel(data)).unwrap().catch((err) => {});
                if (response.status === 200) {
                    toast.success('Retur berhasil di tolak')
                    setTimeout(() => {
                        return window.location.href = `/${username}/sales/retur/data`
                    }, 1200);
                }
            } catch (error) {
                
            }
        }
    }

    return (
        <SalesRetur>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>VALIDASI RETUR { code }</h3>
                                <span>Detail Validasi Retur</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    <table className="table align-middle table-sm table-borderless">
                                        <tbody>
                                            <tr>
                                                <th>Kode Retur</th>
                                                <td>: { code }</td>

                                                <th>Bukti Retur</th>
                                                <td>
                                                    : <a href={ retur.file } target="_blank">Lihat Bukti Retur</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Tanggal</th>
                                                <td>
                                                    : <Moment format="dddd, DD MMMM YYYY" >{ retur.date }</Moment>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Marketing</th>
                                                <td>: { retur.marketing }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Kode Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Produk</span>
                                                </th>
                                                <th className="text-center">
                                                    <span className="form-control">Jumlah Retur</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Harga Satuan</span>
                                                </th>
                                                <th className="table-border-end text-center">
                                                    <span className="form-control">Sub Total</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                returLists.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{data.code_product}</td>
                                                        <td>{data.product}</td>
                                                        <td className="text-center">{data.qty}</td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.unit_price } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.sub_total } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                            <tr>
                                                <th colSpan={5}>SUB TOTAL RETUR</th>
                                                <th>
                                                    <NumericFormat 
                                                            value={ sub_total } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-price mb-2">
                                    <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                        <span>
                                            Total Transaksi
                                        </span>
                                        <span>
                                            { code }
                                        </span>
                                    </div>

                                    <div className="text-center price-total">
                                        <NumericFormat 
                                            value={ retur.nominal } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </div>
                                </div>

                                <div className="card-in mb-2">
                                    <div style={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-center">
                                        <div>
                                            PPN
                                        </div>
                                        <div className="text-orange">
                                            <NumericFormat 
                                                value={ ppn_price } 
                                                allowLeadingZeros 
                                                thousandSeparator="," 
                                                valueIsNumericString
                                                prefix="Rp. "
                                                displayType="text"
                                                renderText={(value) => <span>{value}</span>}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">Keterangan</label>
                                    <span className="form-control">{retur.desc}</span>
                                </div>
                                
                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">Keterangan Ditolak ( Jika tolak )</label>
                                    <textarea placeholder="Keterangan ditolak" onChange={(e) => setInput({...input, desc_cancel: e.target.value})} style={{height: '100px'}} className="form-control"></textarea>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <Button onClick={onCancel} fullWidth variant="contained" color="error">
                                            Tolak
                                        </Button>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Button onClick={onValidasi} fullWidth variant="contained" color="primary">
                                            validasi
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SalesRetur>
    )
}

export default SalesReturValidasiDetail
