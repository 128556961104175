import { Close, ReplyAll } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getFinancial } from '../../../../models/financial/financial_m';

import { NumericFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import RkapRealisasi from '../RkapRealisasi';

const RkapComparasiDetail = () => {
    const dispatch = useDispatch();
    const { username, year } = useParams();

    const [detail, setDetail] = useState([]);

    const getDetail = async() => {
        try {
            const response = await dispatch(getFinancial(year)).unwrap().catch((err) => {});
            setDetail(response.data);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail();
    }, []);

    const handleBack = () => {
        return window.location.href = `/${username}/rkap/realisasi/compare`
    }

    return (
        <RkapRealisasi>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        
                        <div className="row">
                            <div className="col-md-2 mb-3"></div>
                            <div className="col-md-8 mb-3">
                                <div className="mb-3 text-center">
                                    <h2>REALISASI RKAP PERUMDA ANEKA USAHA <br /> KABUPATAN JEPARA <br /> TAHUN { year }</h2>
                                </div>
                            </div>
                            <div className="col-md-2 mb-3">
                                <div className="text-end">
                                    <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                        <ReplyAll  sx={{color: '#ffffff'}} />
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="card-in">
                                    <div className="table-responsive">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th className="table-border-start" style={{width: '50%'}}>
                                                        <span className="form-control fw-bold">Keterangan</span>
                                                    </th>
                                                    <th className="table-border-end">
                                                        <span className="form-control fw-bold">Nominal</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th colSpan={2}>
                                                        Pendapatan
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="ms-4">Pendapatan</span>
                                                    </td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ detail.pendapatan } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Total Pendapatan
                                                    </th>
                                                    <th>
                                                        <NumericFormat 
                                                            value={ detail.pendapatan } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </th>
                                                </tr>

                                                {/* hpp */}
                                                <tr>
                                                    <th colSpan={2}>
                                                        HPP Produk
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="ms-4">HPP Produk</span>
                                                    </td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ detail.hpp_product } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Total HPP Produk</th>
                                                    <th>
                                                        <NumericFormat 
                                                            value={ detail.hpp_product } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </th>
                                                </tr>

                                                <tr>
                                                    <th style={{backgroundColor: '#80808070'}}>TOTAL LABA KOTOR</th>
                                                    <th style={{backgroundColor: '#80808070'}}>
                                                        <NumericFormat 
                                                            value={ detail.pendapatan - detail.hpp_product } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mb-3">
                                <div className="card-in">
                                    <div className="table-responsive">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th className="table-border-start" style={{width: '50%'}}>
                                                        <span className="form-control fw-bold">Keterangan</span>
                                                    </th>
                                                    <th className="table-border-end">
                                                        <span className="form-control fw-bold">Nominal</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th colSpan={2}>
                                                        Beban Operasional
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="ms-4">HPP Operasional</span>
                                                    </td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ detail.hpp_operasional } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="ms-4">Biaya Operasional</span>
                                                    </td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ detail.biaya_operasional } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="ms-4">Biaya Lain Lain</span>
                                                    </td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ detail.biaya_lain } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Total Beban Operasional</th>
                                                    <th>
                                                        <NumericFormat 
                                                            value={ detail.hpp_operasional + detail.biaya_operasional + detail.biaya_lain } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>Pendapatan Lain</th>
                                                    <th>
                                                        <NumericFormat 
                                                            value={ detail.pendapatan_lain } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </th>
                                                </tr>

                                                <tr>
                                                    <th style={{backgroundColor: '#80808070'}}>TOTAL LABA BERSIH</th>
                                                    <th style={{backgroundColor: '#80808070'}}>
                                                        <NumericFormat 
                                                            value={ (detail.pendapatan - detail.hpp_product) - (detail.biaya_lain + detail.biaya_operasional + detail.hpp_operasional) + detail.pendapatan_lain } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </RkapRealisasi>
    )
}

export default RkapComparasiDetail
