import React, { useState } from 'react'
import MatserProductCategory from '../MatserProductCategory'
import { Button, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { storeProductCategory } from '../../../../../models/product/product_category_m'
import toast, { Toaster } from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import Loading from '../../../../loading/Loading'

const MasterProductCategoryForm = () => {
    const dispacth = useDispatch();
    const { username } = useParams();

    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);

    const [input, setInput] = useState({
        code: '', name: ''
    })

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data = {...input};
            const response = await dispacth(storeProductCategory(data)).unwrap().catch((err) => {});
            if (response) {
                setLoading(false);
                if (response.status === 400) {
                    toast.error('Periksa kembali data Anda');
                    setError(response.message);
                    setTimeout(() => {
                       setError([]);
                    }, 1500);
                }

                if (response.status === 201) {
                    toast.success('Data berhasil disimpan');
                    setTimeout(() => {
                        return window.location.href = `/${username}/product/master/category/data`
                    }, 1200);
                }
            }
        } catch (error) {
            
        }
    }

    return (
        <MatserProductCategory>
            <Toaster position="top-center" />
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Kode Kategori <span className="text-danger">*</span></label>
                                        <TextField 
                                            error={ error.code ? true : false }
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Kode Kategori"
                                            value={input.code}
                                            onChange={(e) => setInput({...input, code: e.target.value})}
                                            required
                                        />
                                        <small className="text-danger">{ error.code ? error.code: '' }</small>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Nama Kategori Produk <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Nama Kategori Produk"
                                            value={input.name}
                                            onChange={(e) => setInput({...input, name: e.target.value})}
                                            required
                                        />
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <Button variant="contained" color="primary" type="submit">
                                            Simpan Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MatserProductCategory>
    )
}

export default MasterProductCategoryForm
