import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../../instanceapi";

const initialState = {

}

export const storeSalesMarketingSpoil = createAsyncThunk('sales-marketing-spoil/storeSalesMarketingSpoil', async(data) => {
    try {
        const response = await instanceapi.post('/sales/marketing/spoil/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingSpoilDetail = createAsyncThunk('sales-marketing-spoil/getSalesMarketingSpoilDetail', async(code) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/spoil/detail/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingSpoilByValidasi = createAsyncThunk('sales-marketing-spoil/getSalesMarketingSpoilByValidasi', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/spoil/validasi/${data.status}?page=${data.page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const updateSalesMarketingSpoilValidasi = createAsyncThunk('sales-marketing-spoil/updateSalesMarketingSpoilValidasi', async(data) => {
    try {
        const response = await instanceapi.post(`/sales/marketing/spoil/update/validasi/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const sales_marketing_spoil_m = createSlice({
    name: 'sales-marketing-spoil',
    initialState
})

export default sales_marketing_spoil_m.reducer;