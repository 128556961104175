import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { Autocomplete, Button, TextField } from '@mui/material';
import RkapInput from '../RkapInput';
import { getRkapVariableByStatusVariable } from '../../../../models/rkap/rkap_variable_m';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { storeRkap } from '../../../../models/rkap/rkap_m';
import Loading from '../../../loading/Loading';
import { getProductCategory } from '../../../../models/product/product_category_m';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="Rp. "
        />
    );
});

NumericFormatCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const status_bisnis = [
    { code: 'bisnis', label: 'Bisnis' },
    { code: 'non', label: 'Non Bisnis' }
]

const status_biaya = [
    { code: 'true', label: 'Iya' },
    { code: 'false', label: 'Tidak' }
]

const RkapInputForm = () => {
    const dispatch = useDispatch();
    const { username } = useParams();

    const [variable, setVariable] = useState([]);
    const getVariableByStatusBisnis = async(status_bisnis) => {
        try {
            const response = await dispatch(getRkapVariableByStatusVariable(status_bisnis)).unwrap().catch((err) => {});
            setVariable(response.data.map((data) => ({code: data.code, label: data.name, status_kas: data.status_kas})))
        } catch (error) {
            
        }
    }

    const date = new Date();
    const code = `RKAP${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([]);

    const [input, setInput] = useState({
        code: '', year: date.getFullYear(), status_bisnis: '', code_variable: '', variable: '',
        uraian: '', total_target: '', status_biaya: '', biaya: ''
    })
    const [list, setList] = useState([]);
    const total_target_variable = list.reduce((i, j) => i + j.total_target, 0);

    // handle change
    const handleChangeStatusBisnis = (_, event) => {
        if (event) {
            setInput({...input, status_bisnis: event.code, 
                code_variable: '', variable: '', uraian: '', total_target: '',
                status_kas: ''
            })
            setList([])
            getVariableByStatusBisnis(event.code)
        }
    }

    const handleChangeVariable = (_, event) => {
        if (event) {
            setInput({
                ...input, code_variable: event.code, variable: event.label, 
                status_kas: event.status_kas, uraian: '', total_target: ''
            })
            setList([]);
        }
    }

    const checkDataList = list.some(obj => {
        if (obj.uraian === input.uraian) {
            return true
        }
        return false
    })

    const onAddData = () => {
        if (checkDataList === true) {
            toast.error('Nama uraian sudah dimasukkan')
            setInput({...input, uraian: '', total_target: '', status_biaya: '', biaya: ''})
        } else {
            if (input.uraian !== '' && input.total_target !== '') {
                setList([...list, {...input, code: code}])
                setInput({...input, uraian: '', total_target: '', status_biaya: '', biaya: ''})
            } else {
                toast.error('Silahkan isi uraian dan target RKAP')
                setInput({...input, uraian: '', total_target: '', status_biaya: '', biaya: ''})
            }
        }
    }

    const onDeleteData = (index) => {
        const array = Array.from(list)
        array.splice(index, 1);
        setList(array)
    }

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const data = {
                list: {...list},
                total_target: total_target_variable
            }


            const response = await dispatch(storeRkap(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('RKAP berhasil ditambahkan')
                setTimeout(() => {
                    return window.location.href = `/${username}/rkap/input/data`
                }, 1200);
            }

            if (response.status === 400) {
                setLoading(false)
                toast.error('Periksa kembali data Anda')
                setError(response.message);
                setTimeout(() => {
                    setError([]);
                }, 1500);
            }
        } catch (error) {
            
        }
    }

    return (
        <RkapInput>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>INPUT RKAP BIDANG PERDAGANGAN</h3>
                                <span>Input RKAP Bidang Perdagangan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onSubmit} className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-in mb-2">
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Tahun</label>
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                value={input.year}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Status Bsinis</label>
                                            <Autocomplete 
                                                size="small"
                                                fullWidth
                                                disableClearable
                                                options={status_bisnis}
                                                isOptionEqualToValue={(options, value) => options.label === value.label}
                                                renderInput={(params) => <TextField {...params} placeholder="Status Bisnis" required />}
                                                onChange={handleChangeStatusBisnis}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Variabel RKAP</label>
                                            <Autocomplete 
                                                disabled={ input.status_bisnis ? false : true }
                                                size="small"
                                                fullWidth
                                                options={variable}
                                                value={input.variable}
                                                disableClearable
                                                isOptionEqualToValue={(options, value) => options.label === value}
                                                renderInput={(params) => <TextField {...params} placeholder="Variable RKAP" required />}
                                                onChange={handleChangeVariable}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="card-in mb-2">
                                    <div className="row align-items-end">
                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Uraian</label>
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                placeholder="Tulis Uraian RKAP"
                                                value={input.uraian}
                                                onChange={(e) => setInput({...input, uraian: e.target.value})}
                                            />
                                        </div>
                                        
                                        <div className="col-md-2 mb-2">
                                            <label className="form-label">Kategori Biaya</label>
                                            <Autocomplete 
                                                size="small"
                                                fullWidth
                                                disableClearable
                                                options={status_biaya}
                                                value={input.biaya}
                                                isOptionEqualToValue={(options, value) => options.label === value}
                                                renderInput={(params) => <TextField {...params} placeholder="Kategori Biaya" />}
                                                onChange={(_, event) => setInput({...input, status_biaya: event.code, biaya: event.label})}
                                            />
                                        </div>

                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Target RKAP</label>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                placeholder="Target RKAP ( Rp. )"
                                                value={input.total_target}
                                                onChange={(e) => setInput({...input, total_target: parseFloat(e.target.value)})}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom,
                                                }}
                                            />
                                        </div>

                                        <div className="col-md-2 mb-2">
                                            <Button disabled={ input.status_bisnis && input.code_variable ? false : true } onClick={onAddData} sx={{padding: '8.5px 14px'}} fullWidth variant="contained" color="primary">
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Uraian</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Target RKAP</span>
                                                </th>
                                                <th className="table-border-end text-center" style={{width: '10%'}}>
                                                    <span className="form-control">Action</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                list.length > 0 ?
                                                list.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center"> { index + 1 } </td>
                                                        <td>{ data.uraian }</td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.total_target } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <Button onClick={() => onDeleteData(index)} size="small" variant="contained" color="error">
                                                                Cancel
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )) :
                                                <tr>
                                                    <th colSpan={4} className="text-center">DATA TIDAK DITEMUKAN</th>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-price mb-3">
                                    <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                        <span>
                                            Total Target RKAP { input.variable }
                                        </span>
                                    </div>

                                    <div className="text-center price-total" style={{fontSize: '20px'}}>
                                        <NumericFormat 
                                            value={ total_target_variable } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <Button disabled={ input.status_bisnis && input.variable && total_target_variable ? false : true } variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </RkapInput>
    )
}

export default RkapInputForm
