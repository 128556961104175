import React from 'react'
import SalesMarketingForm from '../../views/sales/marketing/form/SalesMarketingForm'
import SalesMarketingData from '../../views/sales/marketing/data/SalesMarketingData'
import SalesMarketingTempo from '../../views/sales/marketing/tempo/SalesMarketingTempo'
import SalesMarketingDataDetail from '../../views/sales/marketing/data/SalesMarketingDataDetail'
import SalesMarketingTempoDetail from '../../views/sales/marketing/tempo/SalesMarketingTempoDetail'

function SalesMarketingController({ view }) {
    if (view === 'form') {
        return (
            <SalesMarketingForm />
        )
    } else if (view === 'data-detail') {
        return (
            <SalesMarketingDataDetail />
        )
    } else if (view === 'tempo') {
        return (
            <SalesMarketingTempo />
        )
    } else if (view === 'tempo-detail') {
        return (
            <SalesMarketingTempoDetail />
        )
    } else {
        return (
            <SalesMarketingData />
        )
    }
}

export default SalesMarketingController
