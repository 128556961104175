import React, { useEffect, useState } from 'react'
import Warehouse from '../../Warehouse'
import { ReplyAll } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { getDetailSupplier } from '../../../../models/supplier/supplier_m'
import { getDetailReportPenerimaan } from '../../../../models/penerimaan/report_penerimaan_m'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { storePurchaseRetur } from '../../../../models/purchase/purchase_retur_m'
import Loading from '../../../loading/Loading'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Button } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import Moment from 'react-moment'

const WarehouseReturValidationDetail = () => {
    const dispatch = useDispatch();
    const { username, code } = useParams();

    const handleBack = () => {
        window.location.href = `/${username}/warehouse/retur/validasi`
    }

    const [retur, setRetur] = useState([]);
    const [list, setList] = useState([]);
    const [supplier, setSupplier] = useState([]);

    const [loading, setLoading] = useState(false);

    const getSupplier = async(code_supplier) => {
        try {
            const response = await dispatch(getDetailSupplier(code_supplier)).unwrap().catch((err) => {});
            setSupplier(response.data);
        } catch (error) {
            
        }
    }

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailReportPenerimaan(code)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setRetur(response.report_penerimaan)
                setList(response.penerimaan_list.map((i) => ({...i, retur: false})))
                getSupplier(response.report_penerimaan.code_supplier)
            }

            if (response.status === 404) {
                setLoading(true)
                toast.error('Data tidak ada')
                setTimeout(() => {
                    return window.location.href = `/${username}/warehouse/retur/validasi`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail();
    }, []);

    const [listRetur, setListRetur] = useState([]);
    const handleClickRetur = (code_product) => {
        const data = list.map((data) => {
            if (data.code_product === code_product) {
                setListRetur([...listRetur, {...data, jml_retur: data.qty - data.accept_qty, sub_total: (data.qty - data.accept_qty) * data.unit_price}])
                return {...data, retur: true}
            }
            return data;
        })
        setList(data)
    }

    const handleClickCancelRetur = (code_product, index) => {
        const neswList = Array.from(listRetur)
        neswList.splice(index, 1);
        setListRetur(neswList);

        const data = list.map((data) => {
            if (data.code_product === code_product) {
                return {...data, retur: false}
            }
            return data;
        })
        setList(data)
    }

    const sub_total = listRetur.reduce((i, j) => i + j.sub_total, 0);

    let total_pembayaran;
    let ppn_price
    if (retur.ppn !== 0) {
        ppn_price = (11 / 100) * sub_total;
        total_pembayaran = sub_total + ppn_price;
    } else {
        total_pembayaran = sub_total
        ppn_price = 0;
    }

    const total_pembelian = list.reduce((i, j) => i + j.qty, 0);
    const total_diterima = list.reduce((i, j) => i + j.accept_qty, 0);
    const total_retur = listRetur.reduce((i, j) => i + j.jml_retur, 0)
    
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const time = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
    const waktu = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

    const [input, setInput] = useState({
        code: `RTN${day}${month}${year}${time}`, code_purchase_total: code, tgl_validasi: `${year}-${month}-${day}`,
        file: '', desc: ''
    })


    const handleChangeFile = (e) => {
        const file = e.target.files[0];
        setInput({...input, file: file});
    }

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            ...input,
            retur_lists: {...listRetur},
            code_supplier: retur.code_supplier,
            supplier: retur.supplier,
            nominal: total_pembayaran,
            ppn: ppn_price
        }

        try {
            const response = await dispatch(storePurchaseRetur(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Retur pembelian berhasil divalidasi')
                setTimeout(() => {
                    return window.location.href = `/${username}/warehouse/retur/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    } 
    return (
        <Warehouse>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>Pembelian { code }</h3>
                                <span>Detail Data Retur Pembelian</span>
                            </div>

                            <div className="d-flex">
                                <div onClick={handleBack} className="text-center me-2" style={{cursor: 'pointer'}}>
                                    <span className="btn btn-icon btn-orange" style={{padding: '.4rem .4rem', borderRadius: '100%',}}>
                                        <ReplyAll sx={{width: '1.25rem', height: '1.25rem'}} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-9 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive mb-3">
                                        <table className="table-borderless table-sm">
                                            <tbody>
                                                <tr>
                                                    <th>Kode Transaksi</th>
                                                    <td> : { code }</td>

                                                    <th>Nama Supplier</th>
                                                    <td>: { supplier.name }</td>
                                                </tr>
                                                <tr>
                                                    <th>Tanggal Penerimaan</th>
                                                    <td>
                                                        : <Moment format="dddd, DD MMMM YYYY">{ retur.tgl_penerimaan }</Moment>
                                                    </td>

                                                    <th>Alamat</th>
                                                    <td>: { supplier.address }</td>
                                                </tr>
                                                <tr>
                                                    <th>Petugas Penerima</th>
                                                    <td>: { retur.petugas }</td>

                                                    <th>Kontak Perusahaan</th>
                                                    <td>: { supplier.contact_primary }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="table-responsive mb-3">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                                        <span className="form-control">No</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Kode Produk</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Nama Produk</span>
                                                    </th>
                                                    <th className="text-center">
                                                        <span className="form-control">Jumlah Pembelian</span>
                                                    </th>
                                                    <th className="text-center">
                                                        <span className="form-control">Jumlah Diterima</span>
                                                    </th>
                                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                                        <span className="form-control">Action</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{index + 1}</td>
                                                            <td>{ data.code_product }</td>
                                                            <td>{ data.product }</td>
                                                            <td className="text-center">{data.qty}</td>
                                                            <td className="text-center">{data.accept_qty}</td>
                                                            <td className="text-center">
                                                                <Button sx={{backgroundColor: '#dd1cd1', ':hover' : { backgroundColor: '#b706ac' }}} onClick={() => handleClickRetur(data.code_product)} disabled={ data.retur ? true : false } size="small" variant="contained">
                                                                    Retur
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                                        <span className="form-control">No</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Kode Produk</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Nama Produk</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Jumlah Retur</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Harga Satuan</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Sub Total</span>
                                                    </th>
                                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                                        <span className="form-control">Action</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listRetur.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{index + 1}</td>
                                                            <td>{ data.code_product }</td>
                                                            <td>{ data.product }</td>
                                                            <td className="text-center">{data.jml_retur}</td>
                                                            <td>
                                                                <NumericFormat 
                                                                    value={ data.unit_price } 
                                                                    allowLeadingZeros 
                                                                    thousandSeparator="," 
                                                                    valueIsNumericString
                                                                    prefix="Rp. "
                                                                    displayType="text"
                                                                    renderText={(value) => <span>{value}</span>}
                                                                />
                                                            </td>
                                                            <td>
                                                                <NumericFormat 
                                                                    value={ data.sub_total } 
                                                                    allowLeadingZeros 
                                                                    thousandSeparator="," 
                                                                    valueIsNumericString
                                                                    prefix="Rp. "
                                                                    displayType="text"
                                                                    renderText={(value) => <span>{value}</span>}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <Button onClick={() => handleClickCancelRetur(data.code_product, index)} size="small" variant="contained" color="error">
                                                                    Cancel
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-price mb-3">
                                        <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                            <span>
                                                Total Nominal Retur
                                            </span>
                                        </div>
                                        <div className="text-center price-total">
                                            <NumericFormat 
                                                value={ total_pembayaran } 
                                                allowLeadingZeros 
                                                thousandSeparator="," 
                                                valueIsNumericString
                                                prefix="Rp. "
                                                displayType="text"
                                                renderText={(value) => <span>{value}</span>}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="mb-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label className="form-label form-label-m-0">PPN</label>
                                            <span className="text-orange">
                                                <NumericFormat 
                                                    value={ ppn_price } 
                                                    allowLeadingZeros 
                                                    thousandSeparator="," 
                                                    valueIsNumericString
                                                    prefix="Rp. "
                                                    displayType="text"
                                                    renderText={(value) => <span>{value}</span>}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Keterangan</label>
                                        <textarea className="form-control" onChange={(e) => setInput({...input, desc: e.target.value})} placeholder="Keterangan" style={{height: '80px'}}></textarea>
                                    </div>
                                    
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Bukti Transaksi Retur</label>
                                        <input type="file" className="form-control" onChange={handleChangeFile} required />
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <Button disabled={ total_pembelian !== total_diterima + total_retur ? true : false } variant="contained" color="primary" type="submit">
                                            Validasi
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Warehouse>
    )
}

export default WarehouseReturValidationDetail
