import React, { useEffect, useState } from 'react'
import Employee from '../Employee'
import { Autocomplete, Button, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'

import { imageResizer } from '../../../utils'
import { storeEmployee } from '../../../models/employee/employee_m'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import Loading from '../../loading/Loading'
import { getAllEmployeePosition } from '../../../models/employee/employee_position_m'
import { getAllEmployeeJabatanByPosisi } from '../../../models/employee/employee_jabatan_m'
import { DatePicker } from '@mui/x-date-pickers'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const EmployeeForm = () => {
    const dispacth = useDispatch();
    const { username } = useParams();

    const [posisi, setPosisi] = useState([]);
    const  [jabatan, setJabatan] = useState([]);

    const getDataPosisi = async() => {
        try {
            const response = await dispacth(getAllEmployeePosition()).unwrap().catch((err) => {});
            setPosisi(response.data.map((i) => ({ code: i.code, label: i.name })));
        } catch (error) {
            
        }
    }

    const getDataJabatan = async(code) => {
        try {
            const response  = await dispacth(getAllEmployeeJabatanByPosisi(code)).unwrap().catch((err) => {});
            setJabatan(response.data.map((i) => ({ code: i.code, label: i.name })))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataPosisi()
    }, []);


    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const time = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
    const waktu = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

    const [input, setInput] = useState({
        code: `KRY${day}${month}${year}${time}`, name: '', address: '', phone: '', last_education: '', username: '',
        posisi: '', jabatan: '', docs: '', photo: '', tmpt_lahir: '', tgl_lahir: '',
        email: '', nik: '', nip: '', code_posisi: '', code_jabatan: '', posisi: '', jabatan: '',
        username: '', password: ''
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);

    const handleChangePosisi = (_, event) => {
        if (event) {
            setInput({...input, code_posisi: event.code, posisi: event.label, code_jabatan: '', jabatan: ''});
            return getDataJabatan(event.code)
        }
    }

    const handleChangeJabatan = (_, event) => {
        if (event) {
            setInput({...input, code_jabatan: event.code, jabatan: event.label})
        }
    }

    const handleChangeImage = async(e) => {
        const file = e.target.files[0];
        const resizer = await imageResizer(file, 240, 240);
        setInput({...input, photo: resizer})
    }

    const handleChangeDocs = async(e) => {
        const file = e.target.files[0];
        setInput({...input, docs: file})
    } 

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true)
        const data = {...input}
        try {
            const response = await dispacth(storeEmployee(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Data berhasil disimpan')
                setTimeout(() => {
                    return window.location.href = `/${username}/employee/data`
                }, 1200);
            }

            if (response.status === 400) {
                setLoading(false)
                setError(response.message);
                toast.error('Periksa kembali data Anda');
                setTimeout(() => {
                    setError([]);
                }, 1500);
            }
        } catch (error) {
            
        }
    }

  return (
        <Employee>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div style={{lineHeight: '60%'}}>
                            <h3>INPUT DATA KARYAWAN</h3>
                            <span>Form Input Karyawan</span>
                        </div>
                    </div>  
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row row-deck">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Kode Karyawan <span className="text-danger">*</span></label>
                                        <TextField 
                                            error={ error.code ? true : false }
                                            size="small"
                                            fullWidth
                                            value={input.code}
                                            placeholder="Tulis Kode Karyawan"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">NIK</label>
                                        <TextField 
                                            error={ error.nik ? true : false }
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis NIK"
                                            onChange={(e) => setInput({...input, nik: e.target.value})}
                                        />
                                        <small className="text-danger">{ error.nik ? error.nik : '' }</small>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Nomor Induk Pegawai <span className="text-danger">*</span></label>
                                        <TextField 
                                            error={ error.nip ? true : false }
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Nomor Induk Pegawai"
                                            onChange={(e) => setInput({...input, nip: e.target.value})}
                                            required
                                        />
                                        <small className="text-danger">{ error.nip ? error.nip : '' }</small>
                                    </div>
                                    <div>
                                        <label className="form-label form-label-m-0">Nama Karyawan <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Nama Karyawan"
                                            onChange={(e) => setInput({...input, name: e.target.value})}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label form-label-m-0">Tempat Lahir <span className="text-danger">*</span></label>
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                placeholder="Tempat Lahir"
                                                onChange={(e) => setInput({...input, tmpt_lahir: e.target.value})}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label form-label-m-0">Tanggal Lahir <span className="text-danger">*</span></label>
                                            <DatePicker 
                                                slotProps={{textField: { size: 'small' }}}
                                                fullWidth
                                                onChange={(e) => setInput({...input, tgl_lahir: `${e['$y']}-${e['$M'] + 1}-${e['$D']}`})}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Alamat <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Alamat"
                                            onChange={(e) => setInput({...input, address: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Nomor Telephone <span className="text-danger">*</span></label>
                                        <PhoneInput
                                            country={'id'}
                                            value={input.phone}
                                            onChange={phone => setInput({...input, phone: phone})}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                                autoFocus: true
                                            }}
                                            placeholder='+62 858 *** *** ***'
                                        />
                                        <small className="text-danger">{ error.phone ? error.phone : '' }</small>
                                    </div>
                                    <div>
                                        <label className="form-label form-label-m-0">Email</label>
                                        <TextField 
                                            error={ error.email ? true : false }
                                            size="small"
                                            fullWidth
                                            type="email"
                                            placeholder="Tulis Email"
                                            onChange={(e) => setInput({...input, email: e.target.value})}
                                        />
                                        <small className="text-danger">{ error.email ? error.email : '' }</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Pendidikan Terakhir <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Pendidikan Terakhir"
                                            onChange={(e) => setInput({...input, last_education: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Posisi <span className="text-danger">*</span></label>
                                        <Autocomplete 
                                            size="small"
                                            disableClearable
                                            fullWidth
                                            options={posisi}
                                            renderInput={(params) => <TextField {...params} required placeholder="Posisi" />}
                                            onChange={handleChangePosisi}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Jabatan <span className="text-danger">*</span></label>
                                        <Autocomplete 
                                            size="small"
                                            fullWidth
                                            options={jabatan}
                                            disableClearable
                                            value={input.jabatan}
                                            renderInput={(params) => <TextField {...params} required placeholder="Jabatan" />}
                                            onChange={handleChangeJabatan}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label form-label-m-0">Username</label>
                                            <TextField  
                                                error={ error.username ? true : false }
                                                required={ input.password !== '' ? true : false }
                                                size="small"
                                                fullWidth
                                                value={input.username}
                                                placeholder="Username"
                                                onChange={(e) => setInput({...input, username: e.target.value.trim().toLowerCase()})}
                                            />
                                            <small className="text-danger">{ error.username ? error.username : '' }</small>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label form-label-m-0">Password</label>
                                            <TextField 
                                                required={ input.username !== '' ? true : false }
                                                size="small"
                                                fullWidth
                                                placeholder="Password"
                                                onChange={(e) => setInput({...input, password: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Upload Foto</label>
                                        <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={handleChangeImage} className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label formlabel-m-0">Upload Dokumen</label>
                                        <input type="file" className="form-control" onChange={handleChangeDocs} />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button variant="contained" color="primary" type="submit">
                                            Simpan Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>      
        </Employee>
    )
}

export default EmployeeForm
