import React from 'react'
import ScreenWelcome from '../../views/screen/ScreenWelcome'

function ScreenWelcomeController() {
    return (
        <ScreenWelcome />
    )
}

export default ScreenWelcomeController
