import React, { useEffect, useState } from 'react'
import Delivery from '../Delivery'
import { useNavigate, useParams } from 'react-router-dom'
import { ReplyAll } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { getDetailSalesMarketing } from '../../../models/sales/marketing/sales_marketing_m';
import toast from 'react-hot-toast';
import Loading from '../../loading/Loading';
import Moment from 'react-moment';
import { Button, TextField } from '@mui/material';
import { Camera, CameraResultType } from '@capacitor/camera';

// photo
import { b64toBlob, imageResizer } from '../../../utils';
import { updateDeliveryValidasiPenerimaan } from '../../../models/delivery/delivery_m';
import { getDetailMarketing } from '../../../models/marketing/marketing_m';

const DeliveryDetailSalesMarketing = () => {
    const { username, code, code_sales_marketing } = useParams();
    const dispatch = useDispatch();

    const handleBack = () => {
        return window.location.href = `/${username}/delivery/data/${code}`
    }

    const [loading, setLoading] = useState(false);
    const [sales, setSales] = useState([]);
    const [lists, setLists] = useState([]);
    const [marketing, setMarketing] = useState([]);

    const getMarketing = async(code) => {
        try {
            const response = await dispatch(getDetailMarketing(code)).unwrap().catch((err) => {});
            setMarketing(response.data)
        } catch (error) {
            
        }
    }


    const getDataDetail = async() => {
        try {
            const response = await dispatch(getDetailSalesMarketing(code_sales_marketing)).unwrap().catch((err) => {});
            if (response.status === 404) {
                setLoading(true);
                toast.error('Data tidak ditemukan')
                setTimeout(() => {
                    return window.location.href = `/${username}/delivery/data/${code}`
                }, 1200);
            }

            if (response.status === 200) {
                setSales(response.data['sales']);
                setLists(response.data['sales_list'])
                getMarketing(response.data['sales']['code_marketing'])
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataDetail();
    }, []);

    const [input, setInput] = useState({
        code_sale_marketing: code_sales_marketing, penerima: '',
        phone_penerima: '', photo: ''
    })

    const [picture, setPicture] = useState('');
    const takePicture = async() => {
        try {
            const image = await Camera.getPhoto({
                quality: 30,
                allowEditing: true,
                resultType: CameraResultType.Base64
            })

            const imageUrl = image.base64String;
            setPicture('data:image/png;base64,' + imageUrl);
            const blob = b64toBlob(imageUrl, 'image/png');
            const file = new File([blob], 'image.png', {
                type: blob.type,
                lastModified: new Date().getTime()
            })
            const resize = await imageResizer(file, 240, 240);

            setInput({...input, photo: resize})
        } catch (error) {
            
        }
    }

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...input,
            code: code
        }

        try {
            const response = await dispatch(updateDeliveryValidasiPenerimaan(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Data berhasil divalidasi')
                setTimeout(() => {
                    return window.location.href = `/${username}/delivery/data/${code}` 
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <Delivery>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>{ code }</h3>
                                <span>Detail Pengiriman</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-9 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive mb-3">
                                        <table className="table-sm table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th>Kode Transaksi</th>
                                                    <td>: { sales.code }</td>

                                                    <th>Marketing</th>
                                                    <td>: { marketing.name }</td>

                                                    <th>Nomor Telephone 2</th>
                                                    <td>: { marketing.contact_secondary }</td>
                                                </tr>
                                                <tr>
                                                    <th>Tanggal Transaksi</th>
                                                    <td>
                                                        : <Moment format="dddd, DD MMMM YYYY">{ sales.tgl_transaksi }</Moment>
                                                    </td>

                                                    <th>Alamat</th>
                                                    <td>: { marketing.address }</td>
                                                </tr>
                                                <tr>
                                                    <th>Petugas Kasir</th>
                                                    <td>: {sales.employee}</td>

                                                    <th>Nomor Telephone 1</th>
                                                    <td>: { marketing.contact_primary }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                                        <span className="form-control">No</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Kode Produk</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Nama Produk</span>
                                                    </th>
                                                    <th className="text-center" style={{width: '15%'}}>
                                                        <span className="form-control">Jumlah</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lists.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{index + 1}</td>
                                                            <td>{data.code_product}</td>
                                                            <td>{data.product}</td>
                                                            <td className="text-center">{data.qty}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Penerima</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Penerima"
                                            onChange={(e) => setInput({...input, penerima: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Nomor Telephone Penerima</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Nomor Handphone Penerima"
                                            onChange={(e) => setInput({...input, phone_penerima: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Upload Foto Penerima</label>
                                        <Button onClick={takePicture} size="small" fullWidth variant="contained" sx={{backgroundColor: '#e10ead', mb: 1, ':hover' : { backgroundColor: '#b7178f' }}}>
                                            Upload Foto
                                        </Button>
                                        {
                                            picture ? 
                                            <div className="mb-3">
                                                <img src={ picture } alt="" style={{width: '100%', height: '150px', borderRadius: '4px', boxShadow: '#00000059 1px 1px 5px'}} />
                                            </div> : ''
                                        }
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button variant="contained" color="primary" type="submit">
                                            Validasi Penerimaan
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Delivery>
    )
}

export default DeliveryDetailSalesMarketing
