import { combineReducers } from "@reduxjs/toolkit";
import product_category_m from "./product/product_category_m";
import product_m from "./product/product_m";
import product_sub_category_m from "./product/product_sub_category_m";
import product_sub_category_detail_m from "./product/product_sub_category_detail_m";
import auth_m from "./auth/auth_m";
import employee_m from "./employee/employee_m";
import supplier_m from "./supplier/supplier_m";
import employee_position_m from "./employee/employee_position_m";
import employee_jabatan_m from "./employee/employee_jabatan_m";
import marketing_m from "./marketing/marketing_m";
import product_merk_m from "./product/product_merk_m";
import purchase_total_m from "./purchase/purchase_total_m";
import purchase_void_m from "./purchase/purchase_void_m";
import purchase_credit_m from "./purchase/purchase_credit_m";
import report_penerimaan_m from "./penerimaan/report_penerimaan_m";
import opname_m from "./opname/opname_m";
import store_category_m from "./store/store_category_m";
import store_m from "./store/store_m";
import sales_marketing_m from "./sales/marketing/sales_marketing_m";
import delivery_m from "./delivery/delivery_m";
import other_income_m from "./other_income/other_income_m";
import operasional_bisnis_m from "./operasional/operasional_bisnis_m";
import operasional_non_bisnis_m from "./operasional/operasional_non_bisnis_m";
import financial_m from "./financial/financial_m";
import reseller_m from "./reseller/reseller_m";
import rkap_m from "./rkap/rkap_m";
import purchase_retur_m from "./purchase/purchase_retur_m";
import sales_marketing_retur_m from "./sales/marketing/sales_marketing_retur_m";
import sales_marketing_spoil_m from "./sales/marketing/sales_marketing_spoil_m";
import rkap_variable_m from "./rkap/rkap_variable_m";
import sales_request_m from "./sales/marketing/sales_request_m";

const model_r = combineReducers({
    auth : auth_m,
    // employee
    employee : employee_m,
    employee_position : employee_position_m,
    employee_jabatan : employee_jabatan_m,

    // toko
    store_category : store_category_m,
    store : store_m,

    // supplier
    supplier : supplier_m,

    // marketing
    marketing : marketing_m,

    // reseller
    reseller : reseller_m,

    // product
    product_category : product_category_m,
    product_sub_category : product_sub_category_m,
    product_sub_category_detail : product_sub_category_detail_m,
    product_merk : product_merk_m,
    product : product_m,

    // purchase
    purchase_total : purchase_total_m,
    purchase_void : purchase_void_m,
    purchase_credit : purchase_credit_m,
    purchase_retur : purchase_retur_m,

    // opname
    opname: opname_m,

    // sales marketing
    sales_marketing : sales_marketing_m,
    sales_marketing_retur : sales_marketing_retur_m,
    sales_marketing_spoil : sales_marketing_spoil_m,
    sales_request : sales_request_m,

    // delivery
    deliver : delivery_m,

    // other income
    other_income : other_income_m,

    // operasional bisnis
    operasional_bisnis : operasional_bisnis_m,
    operasional_non_bisnis : operasional_non_bisnis_m,

    // report
    report_penerimaan : report_penerimaan_m,

    // financial
    financial : financial_m,

    // rkap
    rkap : rkap_m,
    rkap_variable : rkap_variable_m,
});

export default model_r;