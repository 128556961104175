import React, { useEffect, useState } from 'react'
import Warehouse from '../../Warehouse'
import { Alarm, BackupTable, CloudDownload, CloudUpload } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getPurchaseTotal, getPurchaseTotalFilterData } from '../../../../models/purchase/purchase_total_m'

import { NumericFormat } from 'react-number-format';
import { Button, MenuItem, Select, TextField } from '@mui/material'
import Moment from 'react-moment'

import Paginate from '../../../pagination/Paginate'

import { listYearNext, listYearPrev } from '../../../sales/data/date/year'
import months from '../../../sales/data/date/month'

const PurchaseData = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const date = new Date();
    const year = date.getFullYear();
    const monthNow = date.getMonth();

    const { username } = useParams();

    const [purchase, setPurchase] = useState([]);
    const [pagination, setPagination] = useState([]);

    const [filter, setFilter] = useState({
        status: false, year: year, month: monthNow + 1, name: ' '
    })

    const onClickForm = () => {
        return navigate(`/${username}/warehouse/purchase/form`)
    }

    const onClickPurchaseTempo = () => {
        return navigate(`/${username}/warehouse/purchase/tempo/data`)
    }

    const getDataPurchase = async(page) => {
        try {
            const response = await dispatch(getPurchaseTotal(page)).unwrap().catch((err) => {});
            setPurchase(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    const getFilter = async(page) => {
        try {
            if (filter.year === 'all') {
                filter.year = year;
            }

            const data = {
                ...filter,
                page: page
            }

            const response = await dispatch(getPurchaseTotalFilterData(data)).unwrap().catch((err) => {});
            setPurchase(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataPurchase(1);
    }, []);

    const handlePagination = (_, page) => { 
        if (filter.status === true) {
            return getFilter(page);
        } else {
            return getDataPurchase(page);
        }
    }

    const handleDetailPurchase =(code) => {
        return navigate(`${code}`)
    }

    // filter
    const handleChangeYear = (e) => {
        if (e.target.value === 'all') {
            setFilter({...filter, year: 'all', month: 'all'})
        } else {
            setFilter({...filter, year: e.target.value,  month: 1})
        }
    }

    const onFilter = async(e) => {
        e.preventDefault();
        setFilter({...filter, status: true})
        return getFilter(1);
    }

    const unlockFilter = () => {
        return window.location.reload();
    }

    return (
        <Warehouse>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DATA PEMBELIAN</h3>
                                <span>List Data Pembelian</span>
                            </div>

                            <div className="d-flex">
                                <div onClick={onClickForm} className="text-center me-2" style={{cursor: 'pointer'}}>
                                    <span className="btn btn-icon btn-orange" style={{padding: '.4rem .4rem'}}>
                                        <BackupTable sx={{width: '1.25rem', height: '1.25rem'}} />
                                    </span>
                                    <span style={{fontSize: '12px'}} className="d-block">Kasir Pembelian</span>
                                </div>

                                <div onClick={onClickPurchaseTempo} className="text-center" style={{cursor: 'pointer'}}>
                                    <span className="btn btn-icon btn-info" style={{padding: '.4rem .4rem'}}>
                                        <Alarm sx={{width: '1.25rem', height: '1.25rem'}} />
                                    </span>
                                    <span style={{fontSize: '12px'}} className="d-block">Tempo</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-start mb-3">
                            <div></div>
                            <div>
                                <div style={{lineHeight: '60%'}} className="text-center">
                                    <h2>
                                        DATA PEMBELIAN PERUMDA ANEKA USAHA <br /> 
                                        KABUPATEN JEPARA <br />
                                        Tahun { filter.year }
                                    </h2>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="text-center me-2" style={{ cursor: 'pointer' }}>
                                    <span className="btn btn-icon btn-success" style={{ padding: '.4rem .4rem' }}>
                                        <CloudDownload sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </span>
                                    <span style={{ fontSize: '12px' }} className="d-block">Import</span>
                                </div>

                                <div className="text-center me-2" style={{ cursor: 'pointer' }}>
                                    <span className="btn btn-icon btn-pink" style={{ padding: '.4rem .4rem' }}>
                                        <CloudUpload sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </span>
                                    <span style={{ fontSize: '12px' }} className="d-block">Ekspor</span>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={onFilter}>
                            <div className="row align-items-end">
                                <div className="col-md-3 mb-3">
                                    <label className="form-label form-label-m-0">Tahun</label>
                                    <Select
                                        size="small"
                                        fullWidth
                                        value={filter.year}
                                        onChange={handleChangeYear}
                                        required
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {
                                            listYearPrev.map((year, index) => (
                                                <MenuItem key={index} value={year} >{year}</MenuItem>
                                            ))
                                        }
                                        {
                                            listYearNext.map((year, index) => (
                                                <MenuItem key={index} value={year} >{year}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="form-label form-label-m-0">Bulan</label>
                                    <Select
                                        size="small"
                                        fullWidth
                                        value={filter.month}
                                        onChange={(e) => setFilter({...filter, month: e.target.value})}
                                        disabled={ filter.status || filter.year === 'all' ? true : false }
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {
                                            months.map((month, index) => (
                                                <MenuItem key={index} value={month.id}>{ month.label }</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </div>

                                <div className="col-md-5 mb-3">
                                    <label className="form-label form-label-m-0">Cari Supplier</label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Cari Nama Supplier"
                                        onChange={(e) => setFilter({...filter, name: e.target.value})}
                                    />
                                </div>

                                <div className="col-md-1 mb-3">
                                    {
                                        filter.status ? 
                                        <Button onClick={unlockFilter} sx={{padding: '8.5px 14px'}} variant="contained" fullWidth color="error">
                                            Unlock
                                        </Button> : 
                                        <Button type="submit" sx={{padding: '8.5px 14px'}} variant="contained" fullWidth color="primary">
                                            Filter
                                        </Button>
                                    }
                                </div>
                            </div>
                        </form>

                        <div className="table-responsive mb-3">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Tanggal Transaksi</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Supplier</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Sistem</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Status</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nominal</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Terbayar</span>
                                        </th>
                                        <th style={{width: '15%'}} className="text-center table-border-end">
                                            <span className="form-control">Action</span>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        purchase.map((data) => {
                                            return (
                                                <tr key={data.no}>
                                                    <td>{data.no}</td>
                                                    <td>{data.code}</td>
                                                    <td>
                                                        <Moment format="dddd, DD MMMM YYYY">{data.tgl_transaksi}</Moment>
                                                    </td>
                                                    <td>{data.supplier}</td>
                                                    <td>
                                                        {
                                                            data.sistem_pembayaran === 'tempo' ? 'Tempo' : 'Tunai'
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            data.status_pembayaran === 'belum' ? 'Belum Lunas' : 'Lunas'
                                                        }
                                                    </td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ data.total_pembayaran } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ data.nominal_bayar } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="d-flex justify-content-center">
                                                            <Button onClick={() => handleDetailPurchase(data.code)} variant="contained" color="success" size="small">
                                                                Detail
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        {/* pagination */}
                        <div className="d-flex justify-content-between">
                            <div>
                                <span>Halaman : { pagination.current_page ? pagination.current_page : '' }</span>
                            </div>
                            <div>
                                {
                                    pagination.pagination ? 
                                    <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} /> : ''
                                }
                            </div>
                            <div>
                                <span>Total : { pagination.total ? pagination.total : '' } Pembelian</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Warehouse>
    )
}

export default PurchaseData
