import React from 'react'
import ResellerData from '../../views/mitra/reseller/data/ResellerData'
import ResellerForm from '../../views/mitra/reseller/form/ResellerForm'
import ResellerDataDetail from '../../views/mitra/reseller/data/ResellerDataDetail'

function MitraResellerController({ view }) {
    if (view === 'form') {
        return (
            <ResellerForm />
        )
    } else if (view === 'detail') {
        return (
            <ResellerDataDetail />
        )
    } else {
        return (
            <ResellerData />
        )
    }
}

export default MitraResellerController
