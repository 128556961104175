import React, { useEffect, useState } from 'react'
import Sales from '../../Sales'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSalesMarketingSpoilDetail } from '../../../../models/sales/marketing/sales_marketing_spoil_m';
import toast from 'react-hot-toast';
import Loading from '../../../loading/Loading';
import { ReplyAll } from '@mui/icons-material';

import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';

const SalesSpoilDataDetail = () => {
    const { username, code } = useParams();
    const dispatch = useDispatch();

    const onBack = () => {
        return window.location.href = `/${username}/sales/spoil/data`
    }

    const [loading, setLoading] = useState(false);

    const [spoil, setSpoil] = useState([]);
    const [spoilLists, setSpoilLists] = useState([]);

    const getDetail = async() => {
        try {
            const response = await dispatch(getSalesMarketingSpoilDetail(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setSpoil(response.spoil)
                setSpoilLists(response.spoil_lists);
            }

            if (response.status === 404) {
                setLoading(true);
                toast.error('Data tidak ditemukan')
                setTimeout(() => {
                    return window.location.href = `/${username}/sales/spoil/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail();
    }, []);

    const sub_total_spoil = spoilLists.reduce((i, j) => i + j.sub_total, 0)
    let ppn_price = 0;
    if (spoil.status_ppn === 'ppn') {
        ppn_price = (11 / 100) * sub_total_spoil;
    }

    return (
        <Sales>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>SPOIL PENJUALAN { code }</h3>
                                <span>Detail Spoil Penjualan</span>
                            </div>
                            <div>
                                <span onClick={onBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    <table className="table-borderless table-sm align-middle">
                                        <tbody>
                                            <tr>
                                                <th>Kode Spoil</th>
                                                <td style={{width: '40%'}}>: { code }</td>

                                                <th>Marketing</th>
                                                <td>: { spoil.marketing }</td>
                                            </tr>
                                            <tr>
                                                <th>Kode Transaksi</th>
                                                <td>: { spoil.code_sales_marketing }</td>

                                                <th>Status Pengajuan</th>
                                                <td>
                                                    : {
                                                        spoil.status_spoil === 'success' ? 
                                                        <span className="badge bg-success">Tervalidasi</span> : 
                                                        spoil.status_spoil === 'not' ? 
                                                        <span className="badge bg-danger">Ditolak</span> : 
                                                        <span className="badge bg-orange">Proses</span>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Tanggal Pengajuan</th>
                                                <td>
                                                    : <Moment format="dddd, DD MMMM YYYY">{ spoil.date }</Moment>
                                                </td>

                                                <th>Bukti Spoil</th>
                                                <td>
                                                    : <a href={ spoil.file } target="_blank">Lihat Bukti Spoil</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Kode Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Produk</span>
                                                </th>
                                                <th className="text-center" style={{width: '13%'}}>
                                                    <span className="form-control">Jumlah Spoil</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Harga Satuan</span>
                                                </th>
                                                <th className="table-border-end">
                                                    <span className="form-control">Sub Total</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                spoilLists.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{ data.code_product }</td>
                                                        <td>{ data.product }</td>
                                                        <td className="text-center">{data.qty}</td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.unit_price } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.sub_total } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                            <tr>
                                                <th colSpan={5}>SUB TOTAL SPOIL</th>
                                                <th>
                                                    <NumericFormat 
                                                        value={ sub_total_spoil} 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </th>
                                            </tr>

                                            <tr>
                                                <th>CATATAN</th>
                                                <td colSpan={5}>: { spoil.desc }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-price mb-2">
                                    <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                        <span>
                                            Total Transaksi Spoil
                                        </span>
                                        <span>
                                            { code }
                                        </span>
                                    </div>

                                    <div className="text-center price-total">
                                        <NumericFormat 
                                            value={ spoil.nominal } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </div>
                                </div>

                                <div className="card-in mb-2">
                                    <div style={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-center">
                                        <div>
                                            PPN
                                        </div>
                                        <div className="text-orange">
                                            <NumericFormat 
                                                value={ ppn_price } 
                                                allowLeadingZeros 
                                                thousandSeparator="," 
                                                valueIsNumericString
                                                prefix="Rp. "
                                                displayType="text"
                                                renderText={(value) => <span>{value}</span>}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">Keterangan</label>
                                    <span className="form-control">{ spoil.desc_cancel }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Sales>
    )
}

export default SalesSpoilDataDetail
