import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../instanceapi";

const initialState = {

}

export const getStore = createAsyncThunk('store/getStore', async(page) => {
    try {
        const response = await instanceapi.get(`/store?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const postStore = createAsyncThunk('store/postStore', async(data) => {
    try {
        const response = await instanceapi.post('/store/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getStoreDetail = createAsyncThunk('store/getStoreDetail', async(code) => {
    try {
        const response = await instanceapi.get(`/store/detail/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const updateStoreImage = createAsyncThunk('store/updateStoreImage', async(data) => {
    try {
        const response = await instanceapi.post(`/store/update/image/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const updateStoreData = createAsyncThunk('store/updateStoreData', async(data) => {
    try {
        const response = await instanceapi.post(`/store/update/data/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const deleteStore = createAsyncThunk('store/deleteStore', async(code) => {
    try {
        const response = await instanceapi.delete(`/store/delete/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const store_m = createSlice({
    name: 'store',
    initialState
})

export default store_m.reducer;