import { Drawer } from '@mui/material'
import React from 'react'
import { atk } from '../../images'
import { AccessTime, LocalPhone, LocationOn, PeopleAlt } from '@mui/icons-material'

const MapsDetail = ({ name, open, onClose }) => {
    return (
        <Drawer
            anchor="left"
            open={ open }
            onClose={onClose}
        >
            <div style={{width: '400px'}}>
                <img src={ atk } alt="" style={{width: '100%', height: '300px'}} className="mb-2" />
                <div className="px-3 mt-3 mb-3">
                    <div className="mb-3">
                        <h2 style={{lineHeight: 0.2}}>{ name }</h2>
                        <span>Mitra Toko Perumda Aneka Usaha Jepara</span>
                    </div>
                    
                    <div className="mb-3">
                        <div className="table-responsive">
                            <table className="table table-sm table-borderless">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LocationOn color="primary" />
                                        </td>
                                        <td>
                                        Jl. RS. Fatmawati Raya No.45, Cilandak Bar., Kec. Cilandak, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12430
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <AccessTime color="primary" />
                                        </td>
                                        <td>
                                            Buka Dari Pukul 08.00 - 17.00 WIB
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LocalPhone color="primary" />
                                        </td>
                                        <td>08111866767</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <PeopleAlt color="primary" />
                                        </td>
                                        <td>
                                            Terakhir dikunjungi pada Selasa, 01 Januari 2024 oleh 
                                            <b> Bagus Setiawan</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h3>LAPORAN PENJUALAN</h3>
                        <div className="table-responsive">
                            <table className="table align-middle table-sm">
                                <tbody>
                                    <tr>
                                        <th>Total Penjualan</th>
                                        <td>: Rp. 12.000.000</td>
                                    </tr>
                                    <tr>
                                        <th>Total Pembelian</th>
                                        <td>: Rp. 10.000.000</td>
                                    </tr>
                                    <tr>
                                        <th>Total Persediaan Kopi</th>
                                        <td>: 80 PCS</td>
                                    </tr>
                                    <tr>
                                        <th>Total Persediaan Air</th>
                                        <td>: 10 DUS</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default MapsDetail
