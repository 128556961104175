import React, { useEffect, useState } from 'react'

import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import { useDispatch } from 'react-redux'
import { getFinancialGrafikPendapatan } from '../../../models/financial/financial_m'
import Moment from 'react-moment'
import moment from 'moment'

const ChartSales = () => {
    const dispatch = useDispatch();

    const [pendapatan, setPendapatan] = useState([]);

    const getData = async() => {
        try {
            const year = new Date().getFullYear();
            const response = await dispatch(getFinancialGrafikPendapatan(year)).unwrap().catch((err) => {});
            setPendapatan(
                response.data.map((i) => ({
                    pendapatan: i.pendapatan,
                    month: moment().month(i.month - 1).format('MMMM')
                }))
            );
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const options = {
        chart: {
            zoomType: 'x'
        },
        title: {
            text: 'Grafik Pendapatan Perbulan',
            align: 'left'
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
            align: 'left'
        },
        xAxis: {
            categories: pendapatan.map((i) => (i.month)),
            accessibility: {
                description: 'Months of the year'
            }
        },
        yAxis: {
            title: {
                text: 'Pendapatan'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    ]
                },
                marker: {
                    radius: 2
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                threshold: null
            }
        },
        accessibility: {
            enabled: false
        },
        series: [{
            type: 'area',
            name: 'Total Pendapatan',
            data: pendapatan.map((i) => (i.pendapatan))
        }]
    }

    return (
        <HighchartsReact  highcharts={Highcharts} options={options}  />
    )
}

export default ChartSales
