import React, { useEffect, useState } from 'react'
import SalesMarketing from '../SalesMarketing'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { getEmployeeByName } from '../../../../models/employee/employee_m';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Badge, Button, TextField, Typography } from '@mui/material';
import { getSearchMarketingByCodeName } from '../../../../models/marketing/marketing_m';
import { getSearchCategoryByCodeName } from '../../../../models/product/product_category_m';
import { getSearchProductMerkByCategoryCodeName } from '../../../../models/product/product_merk_m';
import toast from 'react-hot-toast';
import { getProductByMerkCodeNameSearch } from '../../../../models/product/product_m';
import { ArrowDropDown, ShoppingBag } from '@mui/icons-material';

import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import SalesListProduct from './SalesListProduct';
import { storeSalesMarketing } from '../../../../models/sales/marketing/sales_marketing_m';
import Loading from '../../../loading/Loading';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="Rp. "
        />
    );
});

NumericFormatCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const status_ppn = [
    { code: 'ppn', label: 'PPN' },
    { code: 'non', label: 'NON PPN' }
]

const payment_method = [
    { code: 'cash', label: 'Cash' },
    { code: 'transfer', label: 'Transfer' }
]

const status_pengiriman = [
    { code: 'dikirim', label: 'Dikirim' },
    { code: 'diambil', label: 'Diambil' }
]

const SalesMarketingForm = () => {
    const dispatch = useDispatch();
    const { username } = useParams();

    const [employee, setEmployee] = useState([]);
    const [marketing, setMarketing] = useState([]);

    const [category, setCategory] = useState([])
    const [merk, setMerk] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const getEmployee = async(name) => {
        try {
           const response = await dispatch(getEmployeeByName(name)).unwrap().catch((err) => {});
           setEmployee(response.data.map((data) => ({code: data.code, label: data.name})));
        } catch (error) {
            
        }
    }

    const getMarketing = async(code_name_search) => {
        try {
            const response = await dispatch(getSearchMarketingByCodeName(code_name_search)).unwrap().catch((err) => {});
            setMarketing(response.data.map((data) => ({code: data.code, label: data.name })))
        } catch (error) {
            
        }
    }

    const getKategori = async(code_name_search) => {
        try {
            const response = await dispatch(getSearchCategoryByCodeName(code_name_search)).unwrap().catch((err) => {});
            setCategory(response.data.map((data) => ({ code: data.code, label: data.name })))
        } catch (error) {
            
        }
    }

    const getMerk = async(data) => {
        try {
            const response = await dispatch(getSearchProductMerkByCategoryCodeName(data)).unwrap().catch((err) => {});
            setMerk(response.data.map((data) => ({ code: data.code, label: data.name })))
        } catch (error) {
            
        }
    }

    const [product, setProduct] = useState([])

    const getProduk = async(data) => {
        try {
            const response = await dispatch(getProductByMerkCodeNameSearch(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setLoadingSearch(false)
                setProduct(response.data)
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getEmployee('a')
        getMarketing('-')
        getKategori('-')
    }, []);

    const date = new Date();
    const code = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`
    const dateNow = date.toLocaleString() + '';

    const [add, setAdd] = useState({
        code_category: '', category: '', code_merk: '', merk: '', search: ''
    });

    const [input, setInput] = useState({
        code: `TR${code}`, date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, 
        time: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
        code_employee: '', employee: '', code_marketing: '', marketing: '', status_ppn: '', ppn_price: 0,
        tempo_1: '', tempo_2: '', nominal_tempo_1: '', nominal_tempo_2: '', payment_method: '',
        nominal_bayar: '', file: '', status_pengiriman: '', status_tempo: false,
        bank_akun: '', bank_name: '', 
    })

    // handle search
    const handleSearchCategory = (e) => {
        if (e.target.value !== null) {
            return getKategori(e.target.value)
        }
    }

    const handleSearchMerk = (e) => {
        if (e.target.value !== null) {
            if (add.code_category !== null) {
                const data = { code_category: add.code_category, code_name_search: e.target.value };
                return getMerk(data);
            } else {
                toast.error('Silahkan pilih kategori produk terlebih dahulu')
            }
        }
    }

    // handle change
    const handleChangeEmployee = (_, event) => {
        if (event) {
            setInput({...input, code_employee: event.code, employee: event.label})
        }
    }

    const handleChangeCategory = (_, event) => {
        if (event) {
            setAdd({...add, code_category: event.code, category: event.label, code_merk: '', merk: ''});
            setProduct([])
            return getMerk({ code_category: event.code, code_name_search: '-' })
        }
    }

    const handleChangeMerk = (_, event) => {
        if (event) {
            setAdd({...add, code_merk: event.code, merk: event.label})
        }
    }

    const handleChangeMarketing = (_, event) => {
        if (event) {
            setInput({...input, code_marketing: event.code, marketing: event.label})
        }
    }

    const onSearchData = async() => {
        setLoadingSearch(true)
        if (add.code_category === '' || add.code_merk === '') {
            toast.error('Silahkan pilih katori dan merk produk')
        } else {
            if (add.search !== '') {
                add.search = add.search
            } else {
                add.search = '-'
            }
            const data = { code_merk: add.code_merk, code_name_search: add.search }
            return getProduk(data)
        }
    }
    const [list, setList] = useState([]);

    // handle detail chart lists
    const [open, setOpen] = useState(false)
    const onClickOpen = () => setOpen(true)
    const onClickClose = () => setOpen(false);

    const onAddData = (code_product) => {
        const checkListData = list.some(obj => {
            if (obj.code === code_product) {
                return true
            }
            return false
        })

        if (checkListData === true) {
            toast.error('Produk Sudah Dimasukkan Kedalam Keranjang')
        } else {
            toast.success('Produk Berhasil Ditambahkan Kedalam Keranjang')
            product.some(obj => {
                if (obj.code === code_product) {
                    setList([...list, 
                        {
                            code: obj.code,
                            name: obj.name,
                            code_category: obj.code_category,
                            category: obj.category,
                            harga_1: obj.harga_1,
                            harga_2: obj.harga_2,
                            harga_3: obj.harga_3,
                            harga_4: obj.harga_4,
                            stock: obj.stock,
                            unit_price: obj.harga_1,
                            qty: 1,
                            sub_total: 1 * obj.harga_1
                        }
                    ])
                }
            })                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
        }
    }

    // update list data
    const updateStatusHarga = (event, code_product) => {
        const newList = list.map(obj => {
            if (obj.code === code_product) {
                const unit_price = obj[`${event}`];
                const sub_total = unit_price * obj.qty;
                return {...obj, unit_price: unit_price, sub_total: sub_total, status_harga: event}
            }
            return obj;
        })

        setList(newList)
    }

    const updateQty = (e, code_product) => {
        if (e.target.value !== '') {
            const newList = list.map(obj => {
                if (obj.code === code_product) {
                    if (parseInt(e.target.value) > parseInt(obj.stock)) {
                       toast.error('Jumlah Melebihi Stok Produk') 
                       const sub_total = obj.unit_price * 1;
                       return {...obj, qty: 1, sub_total: sub_total}
                    } else {
                        const sub_total = obj.unit_price * parseInt(e.target.value);
                        return {...obj, qty: parseInt(e.target.value), sub_total: sub_total}
                    }
                }
                return obj;
            })

            setList(newList);
        }
    }

    const onDeleteData = (index) => {
        const array = Array.from(list)
        array.splice(index, 1)

        setList(array);
    }

    let sub_total_pembayaran = list.reduce((i, j) => i + j.sub_total, 0)
    let total_pembayaran;
    let ppn_price;
    if (input.status_ppn === 'ppn') {
        ppn_price = (11 / 100) * sub_total_pembayaran;
        total_pembayaran = sub_total_pembayaran + ppn_price
    } else {
        ppn_price = 0;
        total_pembayaran = sub_total_pembayaran
    }

    const [nominalValidation, setNominalValidation] = useState({
        status: false, message: ''
    })

    const handleChangeNominalBayar = (e) => {
        if (e.target.value !== '') {
            const nominal = e.target.value;
            if (input.status_tempo === true) {
                if (parseFloat(nominal) === parseFloat(input.nominal_tempo_1)) {
                    setInput({...input, nominal_bayar: nominal})
                    setNominalValidation({status: false, message: ''})
                } else {
                    setInput({...input, nominal_bayar: ''})
                    setNominalValidation({status: true, message: `Nominal Yang Harus adalah ${input.nominal_tempo_1}`})
                }
            } else {
                if (parseFloat(nominal) === parseFloat(total_pembayaran)) {
                    setInput({...input, nominal_bayar: nominal})
                    setNominalValidation({status: false, message: ''})
                } else {
                    setInput({...input, nominal_bayar: ''})
                    setNominalValidation({status: true, message: `Nominal Yang Harus adalah ${total_pembayaran}`})
                }
            }
        } else {
            setNominalValidation({status: false, message: ''})
        }
    }

    const handleChangeNominalTempo = (e) => {
        if (e.target.value !== '') {
            if (parseFloat(e.target.value) > parseFloat(total_pembayaran)) {
                setInput({...input, nominal_tempo_1: '', nominal_tempo_2: ''})
            } else {
                const sisa = parseFloat(total_pembayaran) - parseFloat(e.target.value);
                setInput({...input, nominal_tempo_1: parseFloat(e.target.value), nominal_tempo_2: sisa, status_tempo: true})
            }
        }
    }

    const transferMethod = (
        <>
            <div className="mb-2">
                <label className="form-label">Nama Bank</label>
                <TextField 
                    size="small"
                    fullWidth
                    placeholder="Tulis Nama Pemilih Rekening"
                    required={ input.payment_method === 'transfer' ? true : false }
                    onChange={(e) => setInput({...input, bank_name: e.target.value})}
                />
            </div>

            <div className="mb-2">
                <label className="form-label">Nama Pemilik Rekening</label>
                <TextField 
                    size="small"
                    fullWidth
                    placeholder="Tulis Nama Pemilih Rekening"
                    required={ input.payment_method === 'transfer' ? true : false }
                    onChange={(e) => setInput({...input, bank_akun: e.target.value})}
                />
            </div>
        </>
    )

    const [loading, setLoading] = useState(false);

    const onSubmit = async(e) => {
        e.preventDefault();

        let status_pembayaran;
        if (parseFloat(total_pembayaran) === parseFloat(input.nominal_bayar)) {
            status_pembayaran = 'lunas';
        } else {
            status_pembayaran = 'belum'
        }

        if (input.nominal_bayar !== '') {
            input.nominal_bayar = input.nominal_bayar;
        } else {
            input.nominal_bayar = 0;
        }

        const data = {
            ...input,
            list: {...list},
            total_pembayaran: total_pembayaran,
            status_pembayaran: status_pembayaran
        }

        try {
            const response = await dispatch(storeSalesMarketing(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Transaksi Penjualan Marketing Berhasil')
                setTimeout(() => {
                    return window.location.href = `/${username}/sales/marketing/data`
                }, 1200);
            } 
        } catch (error) {
            
        }
    }

    return (
        <SalesMarketing>
            { loading ? <Loading /> : '' }
            <form onSubmit={onSubmit} className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-in mb-2">
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Kode Transaksi</label>
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                value={input.code}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Tanggal Transaksi</label>
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                value={dateNow}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Petugas</label>
                                            <Autocomplete 
                                                size="small"
                                                fullWidth
                                                disableClearable
                                                options={employee}
                                                value={input.employee}
                                                isOptionEqualToValue={(options, value) => options.label === value}
                                                renderInput={(params) => <TextField {...params} placeholder="Pilih Petugas" required />}
                                                onChange={handleChangeEmployee}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="card-in mb-3">
                                    <div className="row align-items-end">
                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Marketing</label>
                                            <Autocomplete 
                                                disabled={ input.code_marketing ? true : false }
                                                size="small"
                                                fullWidth
                                                disableClearable
                                                options={marketing}
                                                value={input.marketing}
                                                isOptionEqualToValue={(options, value) => options.label === value}
                                                renderInput={(params) => <TextField {...params} placeholder="Pilih Marketing" required />}
                                                onChange={handleChangeMarketing}
                                            />
                                        </div>

                                        <div className="col-md-4 mb-2">
                                            <label className="foem-label">Kategori Produk</label>
                                            <Autocomplete 
                                                size="small"
                                                fullWidth
                                                disableClearable
                                                options={category}
                                                value={input.category}
                                                isOptionEqualToValue={(options, value) => options.label === value.label}
                                                renderInput={(params) => <TextField {...params} onChange={handleSearchCategory} placeholder="Pilih Kategori" />}
                                                onChange={handleChangeCategory}
                                            />
                                        </div>

                                        <div className="col-md-4 mb-2">
                                            <label className="form-label">Merk Produk</label>
                                            <Autocomplete 
                                                size="small"
                                                fullWidth
                                                disableClearable
                                                options={merk}
                                                value={add.merk}
                                                isOptionEqualToValue={(options, value) => options.label === value.label}
                                                renderInput={(params) => <TextField {...params} onChange={handleSearchMerk} placeholder="Merk Produk" />}
                                                onChange={handleChangeMerk}
                                            />
                                        </div>

                                        <div className="col-md-10 mb-2">
                                            <label className="form-label">Cari Produk</label>
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                placeholder="Cari Nama Produk"
                                                value={add.search}
                                                onChange={(e) => setAdd({...add, search: e.target.value})}
                                            /> 
                                        </div>

                                        <div className="col-md-2 mb-2">
                                            <Button onClick={onSearchData} sx={{padding: '8.5px 14px'}} fullWidth variant="contained" color="primary" >
                                                Cari
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    {
                                        loadingSearch ? 
                                        <div className="col-md-12">
                                            <div className="text-center">
                                                Loading ...
                                            </div>
                                        </div> : ''
                                    }
                                    {
                                        product.map((data, index) => (
                                            <div className="col-md-3 mb-2" key={index}>
                                                <div className="card">
                                                    <img src={ data.image } style={{width: '100%', height: '180px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}}/>
                                                    <div className="card-body">
                                                        <div className="text-center mt-2 mb-2">
                                                            <h3 style={{lineHeight: 0}}>{ data.name }</h3>
                                                            <span>{ data.category }</span>
                                                        </div>
                                                        <div className="text-center text-success mb-2">
                                                            Tersedia : { data.stock } { data.unit }
                                                        </div>
                                                        <div>
                                                            <Button onClick={() => onAddData(data.code)} sx={{'.css-12esyml-MuiButtonBase-root-MuiButton-root' : {alignItems: 'start'}}} fullWidth size="small" variant="contained" color="primary" endIcon={ <ShoppingBag /> }>
                                                                Order
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-price mb-2">
                                    <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                        <span>
                                            Total Transaksi
                                        </span>
                                        <span>
                                            { input.code }
                                        </span>
                                    </div>

                                    <div className="text-center price-total">
                                        <NumericFormat 
                                            value={ total_pembayaran } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </div>
                                </div>

                                <div className="card-in mb-2">
                                    <div onClick={onClickOpen} style={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-center">
                                        <div>
                                            Lihat Keranjang
                                        </div>
                                        <div>
                                            <Badge badgeContent={ list.length } color="error">
                                                <ShoppingBag />
                                            </Badge>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <Accordion sx={{boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.274)'}}>
                                        <AccordionSummary
                                            expandIcon={ <ArrowDropDown /> }
                                        >
                                            <Typography>Tempo</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="mb-2">
                                                <label className="form-label form-label-m-0">Tanggal Tempo 1</label>
                                                <div className="mb-1">
                                                    <DatePicker 
                                                        slotProps={{ textField: { size: 'small', required: input.status_tempo ? true : false} }}
                                                        onChange={(e) => setInput({...input, tempo_1: `${e['$y']}-${e['$M'] + 1}-${e['$D']}`, status_tempo: true})}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="mb-1">
                                                    <TextField
                                                        required={ input.status_tempo ? true : false }
                                                        fullWidth
                                                        size="small"
                                                        value={input.nominal_tempo_1}
                                                        placeholder="Nominal Tempo 1"
                                                        onChange={handleChangeNominalTempo}
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <label className="form-label form-label-m-0">Tanggal Tempo 2</label>
                                                <div className="mb-1">
                                                    <DatePicker 
                                                        slotProps={{ textField: { size: 'small',  required: input.status_tempo ? true : false } }}
                                                        onChange={(e) => setInput({...input, tempo_2: `${e['$y']}-${e['$M'] + 1}-${e['$D']}`, status_tempo: true})}
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="mb-1">
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        placeholder="Nominal Tempo (auto)"
                                                        value={input.nominal_tempo_2}
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>

                                <div className="mb-2">
                                    <label className="form-label">
                                        Status PPN &nbsp; &nbsp; 
                                        <NumericFormat 
                                            value={ ppn_price } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span className="text-green">{value}</span>}
                                        /> 
                                    </label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={status_ppn}
                                        renderInput={(params) => <TextField {...params} placeholder="Status PPN" required />}
                                        onChange={(_, event) => setInput({...input, status_ppn: event.code})}
                                    />
                                </div>

                                <div className="mb-2">
                                    <label className="form-label">Metode Pembayaran</label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={payment_method}
                                        renderInput={(params) => <TextField {...params} placeholder="Status PPN" required />}
                                        onChange={(_, event) => setInput({...input, payment_method: event.code})}
                                    />
                                </div>
                                
                                {
                                    input.payment_method === 'transfer' ? 
                                    transferMethod : ''
                                }

                                <div className="mb-2">
                                    <label className="form-label form-label-m-0">Nominal Bayar</label>
                                    <TextField
                                        error={ nominalValidation.status ? true : false }
                                        fullWidth
                                        size="small"
                                        placeholder="Rp. "
                                        onChange={handleChangeNominalBayar}
                                        value={input.nominal_bayar}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom,
                                        }}
                                    />
                                    <small className="text-danger">{ nominalValidation.message ? nominalValidation.message : '' }</small>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Status Pengiriman</label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={status_pengiriman}
                                        isOptionEqualToValue={(options, value) => options.label === value}
                                        renderInput={(params) => <TextField {...params} placeholder="Status Pengiriman" required />}
                                        onChange={(_, event) => setInput({...input, status_pengiriman: event.code})}
                                    />
                                </div>
                                
                                <div className="mb-3">
                                    <label className="form-label">Upload Bukti Transaksi</label>
                                    <input type="file" className="form-control" onChange={(e) => setInput({...input, file: e.target.files[0]})} required={ input.nominal_bayar > 0 ? true : false } />
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <Button fullWidth variant="contained" color="error">
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Button fullWidth variant="contained" color="primary" type="submit">
                                            Bayar
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <SalesListProduct 
                open={open} 
                onClose={onClickClose} 
                list={list} 
                updateStatusHarga={updateStatusHarga}
                updateQty={updateQty}
                onDeleteData={onDeleteData}
            />
        </SalesMarketing>
    )
}

export default SalesMarketingForm
