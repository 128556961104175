import React, { useEffect, useState } from 'react'
import Warehouse from '../Warehouse'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';

import { getDetailOpname } from '../../../models/opname/opname_m'
import { ReplyAll } from '@mui/icons-material';
import Moment from 'react-moment';

import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="Rp. "
        />
    );
});

NumericFormatCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const OpnameSelesaiDetail = () => {
    const dispatch = useDispatch();
    const { username, code } = useParams();

    const handleBack = () => {
        return window.location.href = `/${username}/warehouse/opname/terlaksana`
    }   

    const [opname, setOpname] = useState([]);
    const [opnameLists, setOpnameLists] = useState([]);
    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailOpname(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setOpname(response.opname)
                setOpnameLists(response.opname_lists.map((i) => ({...i, selisih: i.accept_qty - i.qty, sub_total: (i.accept_qty - i.qty) * i.harga_beli})));
            }
        } catch (error) {
            
        }
    }

    let total = opnameLists.reduce((i, j) => i + j.sub_total, 0);

    useEffect(() => {
        getDetail();
    }, []);
    return (
        <Warehouse>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>{ opname.code ? opname.code : '' }</h3>
                                <span>Opaname Selesai</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <th>Kode Opname</th>
                                                <td>
                                                    : { opname.code }
                                                </td>

                                                <th>Waktu Pelaksanaan</th>
                                                <td>
                                                    : { opname.waktu_pelaksanaan } WIB
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Tanggal Opname</th>
                                                <td>
                                                    : <Moment format="dddd, DD MMMM YYYY">{opname.tgl_opname }</Moment>
                                                </td>

                                                <th>Petugas</th>
                                                <td>: { opname.petugas }</td>
                                            </tr>
                                            <tr>
                                                <th>Tanggal Pelaksanaan</th>
                                                <td>
                                                    : <Moment format="dddd, DD MMMM YYYY">{ opname.tgl_pelaksanaan }</Moment>
                                                </td>

                                                <th>Status Penyelesaian</th>
                                                <td>: <span className="badge bg-green">{ opname.status_penyelesaian }</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mb-3">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Kode Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Nama Produk</span>
                                                </th>
                                                <th className="text-center">
                                                    <span className="form-control">Stok</span>
                                                </th>
                                                <th className="text-center">
                                                    <span className="form-control">Opname</span>
                                                </th>
                                                <th className="text-center">
                                                    <span className="form-control">Selisih</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                opnameLists.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{data.code_product}</td>
                                                        <td>{data.product}</td>
                                                        <td className="text-center">{data.qty}</td>
                                                        <td className="text-center">{ data.accept_qty }</td>
                                                        <td className="text-center">
                                                            { 
                                                                Math.sign(data.accept_qty - data.qty) === -1 ? 
                                                                <span className="text-danger">{data.accept_qty - data.qty}</span> :
                                                                <span className="text-success">+{data.accept_qty - data.qty}</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                            <tr>
                                                <th>Catatan</th>
                                                <td colSpan={7}>: { opname.desc }</td>
                                            </tr>
                                            <tr>
                                                <th>File</th>
                                                <td colSpan={7}>
                                                    : <a href={ opname.file } target="_blank">Lihat Dokument</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>  
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-price mb-3">
                                    <div className="text-center price-total">
                                        <NumericFormat 
                                            value={ total } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">Nominal Petugas</label>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={opname.nominal_petugas}
                                        placeholder="Rp. "
                                        InputProps={{
                                            inputComponent: NumericFormatCustom,
                                        }}
                                        disabled
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">Nominal Perusahaan</label>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={opname.nominal_perusahaan}
                                        placeholder="Rp. "
                                        InputProps={{
                                            inputComponent: NumericFormatCustom,
                                        }}
                                        disabled
                                    />
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="form-label form-label-m-0">Bukti File</label>
                                        {
                                            opname.file_penyelesaian !== null ?
                                            <a href={ opname.file_penyelesaian } target="_blank">Lihat File</a> : ''
                                        }
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">Keterangan</label>
                                    <textarea style={{height: '80px'}} placeholder="Tulis Keterangan" defaultValue={opname.desc} disabled className="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Warehouse>
    )
}

export default OpnameSelesaiDetail
