const date = new Date();
const year = date.getFullYear();

const yearListNext = [];
const yearListPrev = [];
const yearOne = year + 1;
for (let i = 0; i < 10; i++) {
    yearListNext.push(yearOne + i)
    yearListPrev.push(year - i)
}

export const listYearPrev = yearListPrev.sort((a, b) => a - b)
export const listYearNext = yearListNext.sort((a, b) => a - b)