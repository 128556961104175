import React, { useEffect, useState } from 'react'
import Sales from '../Sales'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { BackupTable } from '@mui/icons-material';
import { getSalesMarketingTempoData } from '../../../models/sales/marketing/sales_marketing_m';

import Paginate from '../../pagination/Paginate'
import Moment from 'react-moment';

import { NumericFormat } from 'react-number-format';
import { Button } from '@mui/material';

const SalesTempoData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { username } = useParams();

    const goData = () => {
        return window.location.href = `/${username}/sales/report/data`
    }

    const [tempo, setTempo] = useState([]);
    const [pagination, setPagination] = useState([]);
    const getData = async(page) => {
        try {
            const response = await dispatch(getSalesMarketingTempoData(page)).unwrap().catch((err) => {});
            setTempo(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);

    const handlePagination = (_, page) => {
        return getData(page);
    }

    const handleDetail = (code) => {
        return navigate(`${code}`)
    }

    return (
        <Sales>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DATA TEMPO PENJUALAN</h3>
                                <span>List Data Tempo Penjualan</span>
                            </div>
                            <div className="d-flex">
                                <div onClick={goData} className="text-center me-2" style={{cursor: 'pointer'}}>
                                    <span className="btn btn-icon btn-primary" style={{padding: '.4rem .4rem'}}>
                                        <BackupTable sx={{width: '1.25rem', height: '1.25rem'}} />
                                    </span>
                                    <span style={{fontSize: '12px'}} className="d-block">Data Penjualan</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode Transaksi</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Tanggal Transaksi</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Marketing</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Total</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Terbayar</span>
                                        </th>
                                        <th className="text-center">
                                            <span className="form-control">Status</span>
                                        </th>
                                        <th className="table-border-end text-center" style={{width: '8%'}}>
                                            <span className="form-control">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tempo.map((data) => (
                                            <tr key={data.no}>
                                                <td className="text-center">{data.no}</td>
                                                <td>{data.code}</td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{data.tgl_transaksi}</Moment>
                                                </td>
                                                <td>
                                                    {data.marketing}
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.total_pembayaran } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.nominal_bayar } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        data.status_pembayaran === 'belum' ? 
                                                        <span className="badge bg-orange">Belum Lunas</span> :
                                                        <span className="badge bg-green">Lunas</span>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    <Button onClick={() => handleDetail(data.code)} size="small" color={ data.status_pembayaran === 'lunas' ? 'success' : 'primary' } variant="contained">
                                                        {
                                                            data.status_pembayaran === 'lunas' ? 'Detail' : 'Validasi'
                                                        }
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* pagination */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Halaman : { pagination.current_page ? pagination.current_page : '' }</span>
                            </div>
                            {
                                pagination.pagination ? 
                                <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} /> : ''
                            }
                            <div>
                                <span>Total : { pagination.total } Lists</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Sales>
    )
}

export default SalesTempoData
