import React, { useEffect, useState } from 'react'
import Delivery from '../Delivery'
import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { getDelivery } from '../../../models/delivery/delivery_m'

import Paginate from '../../pagination/Paginate'
import Moment from 'react-moment'

const DeliveryData = () => {
    const dispatch = useDispatch();

    const [delivery, setDelivery] = useState([]);
    const [pagination, setPagination] = useState([]);

    const getData = async(page) => {
        try {
            const response = await dispatch(getDelivery(page)).unwrap().catch((err) => {});
            setDelivery(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1)
    }, []);

    const handlepagination = (_, page) => {
        return getData(page);
    }



    const onDetail = (code) => {
        return window.location.href = `data/${code}`
    }

    return (
        <Delivery>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div style={{lineHeight: '60%'}}>
                            <h3>DATA PENGIRIMAN</h3>
                            <span>List Data Pengiriman</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table align-middle mb-3">
                                <thead>
                                    <tr>
                                        <th className="text-center table-border-start" style={{width: '5%'}}>
                                            <span className="form-control fw-bold">
                                                No
                                            </span>
                                        </th>
                                        <th style={{width: '15%'}}>
                                            <span className="form-control fw-bold">
                                                Kode Pengiriman
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Tanggal Pengiriman
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Petugas
                                            </span>
                                        </th>
                                        <th className="text-center">
                                            <span className="form-control fw-bold">
                                                Status
                                            </span>
                                        </th>
                                        <th style={{width: '15%'}} className="text-center table-border-end">
                                            <span className="form-control fw-bold">
                                                Action
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        delivery.map((data) => (
                                            <tr key={data.no}>
                                                <td className="text-center">{data.no}</td>
                                                <td>{data.code}</td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{data.tgl_pengiriman}</Moment>
                                                </td>
                                                <td>{data.employee}</td>
                                                <td className="text-center">
                                                    {
                                                        data.status === 'proses' ? 
                                                        <span className="badge bg-orange">Proses</span> :
                                                        data.status === 'perjalanan' ? 
                                                        <span className="badge bg-pink">Perjalanan</span> : 
                                                        <span className="badge bg-green">Selesai</span>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    <Button onClick={() => onDetail(data.code)} variant="contained" size="small" color={`${data.status === 'selesai' ? 'success' : 'primary'}`}>
                                                        {
                                                            data.status === 'selesai' ? 'Detail' : 'Validasi'
                                                        }
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            {/* pagination */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <span>Halaman : { pagination.current_page ? pagination.current_page : ''}</span>
                                </div>
                                {
                                    pagination.pagination ? 
                                    <Paginate count={ pagination.total } page={pagination.current_page} onChange={handlepagination} /> : ''
                                }
                                <div>
                                    <span>Total : { pagination.total ? pagination.total : ''} List</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Delivery>
    )
}

export default DeliveryData
