import React, { useEffect, useState } from 'react'
import Employee from '../Employee'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { getDetailEmployee, updateEmployee, updateEmployeePhoto } from '../../../models/employee/employee_m';
import toast from 'react-hot-toast';
import Loading from '../../loading/Loading';
import { ReplyAll } from '@mui/icons-material';

import dayjs from 'dayjs';

import { noimage } from '../../images';
import { Button, TextField } from '@mui/material';
import { b64toBlob, imageResizer } from '../../../utils';
import { Camera, CameraResultType } from '@capacitor/camera';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { DatePicker } from '@mui/x-date-pickers';

const EmployeeDataDetail = () => {
    const dispacth = useDispatch();
    const navigate = useNavigate();

    const [employee, setEmployee] = useState([]);
    const { username, employee_username } = useParams();
    const [picture, setPicture] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [input, setInput] = useState({
        code: '', nik: '', nip: '', name: '', 
        address: '', phone: '', last_education: '',
        tmpt_lahir: '', tgl_lahir: '',  email: '',
        username: '', password: '', docs: ''
    })

    const getDataDetail = async() => {
        try {
            const response = await dispacth(getDetailEmployee(employee_username)).unwrap().catch((err) => {});
            if (response.status === 404) {
                setLoading(true);
                toast.error('Data karyawan tidak ditemukan')
                setTimeout(() => {
                    return window.location.href = `/${username}/employee/data`
                }, 1200);
            }

            if (response.status === 200) {
                const data = response.data;
                setEmployee(data);
                setInput({
                    code: data.code, nik:`${data.nik ? data.nik : ''}`, nip: data.nip, name: data.name, 
                    address: data.address, phone: data.phone, last_education: data.last_education,
                    tmpt_lahir:data.tmpt_lahir, tgl_lahir: data.tgl_lahir,  email: `${data.email ? data.email : ''}`,
                    username: `${ data.username ?  data.username : ''}`, password: ''
                })
            }
        } catch (error) {
            setLoading(false);
            toast.error('Lost connection data')
            setTimeout(() => {
                return window.location.href = `/${username}/employee/data`
            }, 1200);
        }
    }

    useEffect(() => {
        getDataDetail();
    }, []);

    const handleBack = () => {
        return window.location.href = `/${username}/employee/data`
    }

    const takePhoto = async() => {
        try {
            const image = await Camera.getPhoto({
                quality: 20,
                allowEditing: true,
                resultType: CameraResultType.Base64
            })

            const imageUrl = image.base64String;
            setPicture('data:image/png;base64,' + imageUrl);

            const blob = b64toBlob(imageUrl, 'image/png');
            const file = new File([blob], 'image.png', {
                type: blob.type,
                lastModified: new Date().getTime()
            })
            
            const resize = await imageResizer(file, 240, 240);

            const data = {
                code: employee.code,
                photo: resize
            }

            const response = await dispacth(updateEmployeePhoto(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Foto profile berhasil diperbaharui');
                setTimeout(() => {
                    return window.location.reload(); 
                }, 1000);
            }

        } catch (error) {
            
        }
    }

    const onUpdate = async(e) => {
        e.preventDefault();
        setLoading(true)
        const data = {...input}

        try {
            const response = await dispacth(updateEmployee(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Data berhasil diupdate')
                setTimeout(() => {
                    return window.location.reload()
                }, 1200);
            }

            if (response.status === 400) {
                setError(response.message)
                toast.error('Periksa kembali data Anda')
                setTimeout(() => {
                    setError([]);
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <Employee>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>{ employee.name ? employee.name : '' }</h3>
                                <span>Detail Data Karyawan</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onUpdate}>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <a href={ employee.photo } target="_blank">
                                            <img src={ picture ? picture : employee.photo } alt="" className="photo-profile" />
                                        </a>
                                        <div className="mb-2">
                                            <span onClick={takePhoto} className="btn-update-foto">
                                                Update Foto
                                            </span>
                                        </div>
                                        <div className="mb-2">
                                            <span style={{fontSize: '16px', fontWeight: 'bold'}} >{ employee.name ? employee.name : '' }</span> <br />
                                            <span>{ employee.posisi } <br /> ( { employee.jabatan } ) </span>
                                        </div>
                                        <div className="mb-3">
                                            <a href={ employee.docs } target="_blank">Lihat Dokumen</a>
                                        </div>
                                        <div>
                                            <a href={`https://wa.me/${employee.phone}`} target="_blank" className="badge bg-success">
                                                { employee.phone }
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">NIP <span className="text-danger">*</span></label>
                                        <TextField 
                                            error={ error.nip ? true : false }
                                            size="small"
                                            fullWidth
                                            value={input.nip}
                                            onChange={(e) => setInput({...input, nip: e.target.value})}
                                            required
                                        />
                                        <small className="text-danger">{ error.nip ? error.nip : '' }</small>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">NIK</label>
                                        <TextField 
                                            error={ error.nik ? true : false }
                                            size="small"
                                            fullWidth
                                            value={input.nik}
                                            placeholder="Tulis NIK"
                                            onChange={(e) => setInput({...input, nik: e.target.value})}
                                        />
                                         <small className="text-danger">{ error.nik? error.nik: '' }</small>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Nama Karyawan <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            value={input.name}
                                            placeholder="Tulis Nama Karyawan"
                                            onChange={(e) => setInput({...input, name: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Alamat <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            value={input.address}
                                            placeholder="Tulis Alamat"
                                            onChange={(e) => setInput({...input, address: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label form-label-m-0">Tempat Lahir <span className="text-danger">*</span></label>
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                value={input.tmpt_lahir}
                                                placeholder="Tempat Lahir"
                                                onChange={(e) => setInput({...input, tmpt_lahir: e.target.value})}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label form-label-m-0">Tanggal Lahir</label>
                                            <DatePicker 
                                                slotProps={{textField: { size: 'small' }}}
                                                fullWidth
                                                onChange={(e) => setInput({...input, tgl_lahir:`${e['$y']}-${e['$M'] + 1}-${e['$D']}`})}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="form-label form-label-m-0">Email</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            value={input.email}
                                            placeholder="Email"
                                            type="email"
                                            onChange={(e) => setInput({...input, email: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Nomor Handphone <span className="text-danger">*</span></label>
                                        <PhoneInput
                                            country={'id'}
                                            value={input.phone}
                                            onChange={phone => setInput({...input, phone: phone})}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                                autoFocus: true
                                            }}
                                            placeholder='+62 858 *** *** ***'
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Pendidikan Terakhir <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            value={input.last_education}
                                            onChange={(e) => setInput({...input, last_education: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Upload Dokumen</label>
                                        <input type="file" className="form-control"onChange={(e) => setInput({...input, docs: e.target.files[0]})} />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label form-label-m-0">Username</label>
                                            <TextField 
                                                error={ error.username ? true : false }
                                                size="small"
                                                fullWidth
                                                value={input.username}
                                                required
                                                placeholder="Tulis Username"
                                                onChange={(e) => setInput({...input, username: e.target.value})}
                                            />
                                            <small className="text-danger">{ error.username ? error.username : '' }</small>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label form-label-m-0">Password</label>
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                value={input.password}
                                                placeholder="*********"
                                                onChange={(e) => setInput({...input, password: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button variant="contained" color="success" type="submit">
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Employee>
    )
}

export default EmployeeDataDetail
