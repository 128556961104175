import React from 'react'
import FinancialData from '../../views/financial/financial/FinancialData'
import FinancialHutang from '../../views/financial/hutang/FinancialHutang'
import FinancialPiutang from '../../views/financial/piutang/FinancialPiutang'
import FinancialPiutangDetail from '../../views/financial/piutang/FinancialPiutangDetail'
import FinancialHutangDetail from '../../views/financial/hutang/FinancialHutangDetail'

function FinancialController({ view }) {
    if (view === 'financial') {
        return (
            <FinancialData />
        )
    } else if (view === 'hutang') {
        return (
            <FinancialHutang />
        )
    } else if (view === 'hutang-detail') {
        return (
            <FinancialHutangDetail />
        )
    } else if (view === 'piutang') {
        return (
            <FinancialPiutang />
        )
    } else if (view === 'piutang-detail') {
        return (
            <FinancialPiutangDetail />
        )
    }
}

export default FinancialController
