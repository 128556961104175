import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from '../instanceapi'

const initialState = {

}

export const getPurchaseRetur = createAsyncThunk('purchase-retur', async(page) => {
    try {
        const response = await instanceapi.get(`/purchase/retur?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const storePurchaseRetur = createAsyncThunk('purchase-retur/storePurchaseRetur', async(data) => {
    try {
        const response = await instanceapi.post('/purchase/retur/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getDetailPurchaseRetur = createAsyncThunk('purchase-retur/getDetailPurchaseRetur', async(code) => {
    try {
        const response = await instanceapi.get(`/purchase/retur/detail/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const purchase_retur_m = createSlice({
    name: 'purchase-retur',
    initialState
})


export default purchase_retur_m.reducer;