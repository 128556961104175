import React, { useEffect, useState } from 'react'

import Operasional from '../Operasional'
import { TableView } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { Autocomplete, Button, TextField } from '@mui/material'

import { imageResizer } from '../../../utils'
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import Loading from '../../loading/Loading'
import { storeOperasionalBisnis } from '../../../models/operasional/operasional_bisnis_m'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { getRkapStatusBisnisBiaya } from '../../../models/rkap/rkap_m'

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="Rp. "
        />
    );
});

NumericFormatCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const OperasionalBisnisForm = () => {
    const { username } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goData = () => {
        return navigate(`/${username}/operasional/bisnis/data`)
    }

    const [category, setCategory] = useState([]);
    const getDataCategory = async() => {
        try {
            const response = await dispatch(getRkapStatusBisnisBiaya('bisnis')).unwrap().catch((err) => {});
            setCategory(response.data.map((data) => ({code: data.code, label: data.name})))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataCategory()
    }, [])

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const time = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
    const time_now = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    const [loading, setLoading] = useState(false);

    const [input, setInput] = useState({
        code: `OB${year}${month}${day}${time}`, date: `${year}-${month}-${day}`, time: time_now, name: '', desc: '', file: '', nominal: '',
        code_rkap: '', rkap: ''
    })

    const handleFile = async(e) => {
        const file = e.target.files[0];
        const resizer = await imageResizer(file, 480, 480);
        setInput({...input, file: resizer})
    }

    const onSubmit = async(e) => {
        e.preventDefault();

        setLoading(true);
        const data = {
            ...input,
            date: `${year}-${month}-${day}`,
            month: `${month}`,
            year: `${year}`
        }
        try {
            const response = await dispatch(storeOperasionalBisnis(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Data berhasil disimpan')
                setTimeout(() => {
                   return window.location.href = `/${username}/operasional/bisnis/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <Operasional>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>FORM BIAYA BISNIS</h3>
                                <span>Form Biaya Bisnis</span>
                            </div>
                            <div onClick={goData} className="text-center me-2" style={{ cursor: 'pointer' }}>
                                <span className="btn btn-icon btn-primary" style={{ padding: '.4rem .4rem' }}>
                                    <TableView sx={{ width: '1.25rem', height: '1.25rem' }} />
                                </span>
                                <span style={{ fontSize: '12px' }} className="d-block">Data</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row row-deck">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Kode Biaya Bisnis</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            value={input.code}
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Kategori Biaya Rkap</label>
                                        <Autocomplete 
                                            size="small"
                                            fullWidth
                                            disableClearable
                                            options={category}
                                            renderInput={(params) => <TextField {...params} placeholder="Kategori Biaya RKAP" required />}
                                            onChange={(_, event) => setInput({...input, code_rkap: event.code, rkap: event.label})}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Nama Biaya Bisnis</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Nama Biaya Bisnis"
                                            onChange={(e) => setInput({...input, name: e.target.value})}
                                            required
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Nominal</label>
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            value={input.nominal}
                                            onChange={(e) => setInput({...input, nominal: Math.abs(e.target.value)})}
                                            placeholder="Rp. "
                                            InputProps={{
                                                inputComponent: NumericFormatCustom,
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Upload Bukti</label>
                                        <input type="file" accept="image/png, image/jpg, image/jpeg" required onChange={handleFile} className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Keterangan</label>
                                        <textarea style={{height: '60px'}} placeholder="Tulis Keterangan" onChange={(e) => setInput({...input, desc: e.target.value})} className="form-control"></textarea>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button type="submit" variant="contained" color="primary">
                                            Simpan Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Operasional>
    )
}

export default OperasionalBisnisForm
