import React, { useEffect, useState } from 'react'
import Financial from '../Financial'
import { useParams } from 'react-router-dom'
import { ReplyAll } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { getDetailPurchaseTotal } from '../../../models/purchase/purchase_total_m';
import Moment from 'react-moment';

import { NumericFormat } from 'react-number-format';

const FinancialHutangDetail = () => {
    const { username, code } = useParams();
    const dispatch = useDispatch();

    const handleBack = () => {
        return window.location.href = `/${username}/financial/hutang`
    }

    const [purchase, setPurchase] = useState([]);
    const [list, setList] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [tempo, setTempo] = useState([]);

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailPurchaseTotal(code)).unwrap().catch((err) => {});
            setPurchase(response.total);
            setList(response.list);
            setTempo(response.tempo)
            setSupplier(response.supplier)
            setList(response.lists);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail();
    }, []);

    return (
        <Financial>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>Detail Piutang { code }</h3>
                                <span>Detail Piutang</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            <table className="table table-borderless table-sm align-middle">
                                <tbody>
                                    <tr>
                                        <th>Kode Transaksi</th>
                                        <td>: { purchase.code }</td>

                                        <th>Supplier</th>
                                        <td>: { purchase.supplier }</td>

                                        <th>Status Penerimaan</th>
                                        <td>
                                            : {
                                                purchase.status_penerimaan === 'diterima' ?
                                                <span className="badge bg-green">Diterima</span> : 
                                                <span className="badge bg-red">Belum Diterima</span>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Tanggal Transaksi</th>
                                        <td>
                                            : <Moment format="dddd, DD MMMM YYYY">{ purchase.tgl_transaksi }</Moment>
                                        </td>

                                        <th>Alamat</th>
                                        <td>: { supplier.address }</td>

                                        <th>Sistem Pembayaran</th>
                                        <td>
                                            : {
                                                purchase.sistem_pembayaran === 'tempo' ? 
                                                <span className="badge bg-primary">Tempo</span> :
                                                <span className="badge bg-info">Tunai</span>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Petugas</th>
                                        <td>
                                            : { purchase.petugas }
                                        </td>

                                        <th>Nomor Telephone</th>
                                        <td>: { supplier.contact_primary }</td>

                                        <th>Status</th>
                                        <td>
                                            : {
                                                purchase.status_pembayaran === 'lunas' ? 
                                                <span className="badge bg-green">Lunas</span> :
                                                <span className="badge bg-red">Belum Lunas</span>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive mb-3">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode Produk</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nama Produk</span>
                                        </th>
                                        <th className="text-center">
                                            <span className="form-control">Jumlah</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Harga Satuan</span>
                                        </th>
                                        <th className="table-border-end">
                                            <span className="form-control">Sub Total</span>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        list.map((data, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{ index + 1 }</td>
                                                <td>{data.code_product}</td>
                                                <td>{ data.product }</td>
                                                <td className="text-center">{ data.qty }</td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.unit_price } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.sub_total } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Tanggal Pembayaran</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nominal</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Terbayar</span>
                                        </th>
                                        <th className="table-border-end text-center">
                                            <span className="form-control">Status</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tempo.map((data, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{ index + 1 }</td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{ data.tgl_pembayaran }</Moment>
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.nominal } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.terbayar } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        data.nominal !== data.terbayar ? 
                                                        <span className="badge bg-danger">Belum Lunas</span> : 
                                                        <span className="badge bg-green">Lunas</span>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Financial>
    )
}

export default FinancialHutangDetail
