import React, { useEffect, useState } from 'react'
import SalesRetur from '../SalesRetur'
import { Autocomplete, Button, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { getSearchMarketingByCodeName } from '../../../../models/marketing/marketing_m'
import { getDetailSalesMarketing } from '../../../../models/sales/marketing/sales_marketing_m'

import { NumericFormat } from 'react-number-format';
import { CameraAlt, ShoppingBag } from '@mui/icons-material'
import SalesReturChart from './SalesReturChart'
import { Camera, CameraResultType } from '@capacitor/camera'

import { imageResizer, b64toBlob } from '../../../../utils'
import { storeSalesMarketingRetur } from '../../../../models/sales/marketing/sales_marketing_retur_m'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import Loading from '../../../loading/Loading'

const SalesReturForm = () => {
    const dispatch = useDispatch();
    const { username } = useParams();
    const [marketing, setMarketing] = useState([]);

    const getMarketing = async(code_name_search) => {
        try {
            const response = await dispatch(getSearchMarketingByCodeName(code_name_search)).unwrap().catch((err) => {});
            setMarketing(response.data.map((i) => ({ code: i.code, label: i.name })));
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getMarketing('-');
    }, []);


    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const time = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
    const date_now = date.toLocaleString() + '';

    const [input, setInput] = useState({
        code: `RTR${day}${month}${year}${time}`, date: '', marketing: '', code_marketing: '',
        code_sales_marketing: '', desc: ''
    })
    
    const [sales, setSales] = useState([]);
    const [salesLists, setSalesLists] = useState([]);
    const [nodata, setNodata] = useState('');

    const getSalesDetail = async(code) => {
        try {
            const response = await dispatch(getDetailSalesMarketing(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setSales(response.data['sales']);
                const list = response.data['sales_list'];
                setSalesLists(list.map((i) => ({...i, retur: false})));
                setNodata('')
            }

            if (response.status === 404) {
                setSales([]);
                setSalesLists([]);
                setNodata('Nomor Transaksi tidak ditemukan')
            }
        } catch (error) {
            
        }
    }

    const handleSeachMarketing = (e) => {
        if (e.target.value !== '') {
            return getMarketing(e.target.value);
        }
    }

    const handleChangeMarketing = (_, event) => {
        setSales([]);
        setSalesLists([]);
        if (event) {
            setInput({...input, marketing: event.label, code_marketing: event.code})
        }
    }

    const onSubmitNota = async(e) => {
        e.preventDefault();
        return getSalesDetail(input.code_sales_marketing);
    }

    const [returList, setReturList] = useState([]);
    const [picture, setPicture] = useState('');
    const [upload, setUpload] = useState('');
    const onRetur = (code_product) => {
        const data = salesLists.map((data) => {
            if (data.code_product === code_product) {
                const sub_total = 1 * data.unit_price;
                setReturList([...returList, {...data, qty: 1, sub_total: sub_total, retur: true}])
                return {...data, retur: true}
            }
            return data;
        })
        setSalesLists(data)
    }

    let total_retur = 0;
    let ppn_price = 0
    if (returList.length > 0) {
        let sub_total = returList.reduce((i, j) => i + j.sub_total,0);
        if (sales.status_ppn === 'ppn') {
            ppn_price = (11 / 100) * sub_total;
            total_retur = ppn_price + sub_total
        } else {
            ppn_price = 0;
            total_retur = sub_total;
        }
    }

    const [open, setOpen] = useState(false);
    const handleModalChartOpen = () => {
        setOpen(true)
    }

    const handleModalChartClose = () => {
        setOpen(false);
    }

    const handleUpdateQtyChart = (e, code_product) => {
        if (e.target.value !== '') {
            const newLists = returList.map((data) => {
                if (data.code_product === code_product) {
                    const sub_total = e.target.value * data.unit_price;
                    return {...data, qty: e.target.value, sub_total : sub_total}
                }
                return data;
            })

            setReturList(newLists);
        }
    }

    const handleCancelChartRetur = (code_product, index) => {
        const lists = salesLists.map((data) => {
            if (data.code_product === code_product) {
                return {...data, retur: false}
            }
            return data;
        })

        const arrayList = Array.from(returList);
        arrayList.splice(index, 1);
        setReturList(arrayList);
        setSalesLists(lists)
    }

    const takePhoto = async() => {
        try {
            const image = await Camera.getPhoto({
                quality: 30,
                allowEditing: true,
                resultType: CameraResultType.Base64
            })

            const imageUrl = image.base64String;
            setPicture('data:image/png;base64,' + imageUrl);
            setUpload(imageUrl);
            
        } catch (error) {
            
        }
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);
    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);

        if (upload === '') {
            setLoading(false)
            toast.error('Periksa kembali data Anda');
            setError({file: 'Silahkan upload bukti retur'});
            setTimeout(() => {
                setError([])
            }, 1500);
        } else {
            const blob = b64toBlob(upload, 'image/png');
            const file = new File([blob], 'image.png', {
                type: blob.type,
                lastModified: new Date().getTime()
            })
            const resize = await imageResizer(file, 240, 240);

            const data = {
                ...input,
                file : resize,
                date: `${year}-${month}-${day}`,
                nominal: total_retur,
                status_ppn: sales.status_ppn,
                retur_lists: {...returList}
            }

            try {
                const response = await dispatch(storeSalesMarketingRetur(data)).unwrap().catch((err) => {});
                if (response.status === 201) {
                    toast.success('Retur penjualan berhasil diajukan');
                    setTimeout(() => {
                        return window.location.href = `/${username}/sales/retur/validasi`
                    }, 1200);
                }

                if (response.status === 400) {
                    toast.error('Periksa kembali data Anda')
                    setLoading(false);
                    setError(response.message);
                    setTimeout(() => {
                        setError([])
                    }, 1500);
                }
            } catch (error) {
                
            }
        }

    }

    const onCancelData = () => {
        setLoading(true)
        toast.success('Retur Penjualan Berhasil di Batalkan')
        setTimeout(() => {
            return window.location.href = `/${username}/sales/retur/data`
        }, 1200);
    }

    return (
        <SalesRetur>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <form onSubmit={onSubmitNota}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-in mb-2">
                                        <div className="row">
                                            <div className="col-md-4 mb-1">
                                                <label className="form-label form-label-m-0">Kode Retur</label>
                                                <TextField 
                                                    size="small"
                                                    fullWidth
                                                    value={input.code}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-md-4 mb-1">
                                                <label className="form-label form-label-m-0">Tanggal</label>
                                                <TextField 
                                                    size="small"
                                                    fullWidth
                                                    value={date_now}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-md-4 mb-1">
                                                <label className="form-label form-label-m-0">Marketing</label>
                                                <Autocomplete 
                                                    size="small"
                                                    fullWidth
                                                    disableClearable
                                                    options={marketing}
                                                    renderInput={(params) => <TextField {...params} placeholder="Marketing" onChange={handleSeachMarketing} required />}
                                                    onChange={handleChangeMarketing}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-in mb-2">
                                        <div className="row align-items-end">
                                            <div className="col-md-9 mb-1">
                                                <label className="form-label form-label-m-0">No Transaksi</label>
                                                <TextField 
                                                    error={ error.code_sales_marketing ? true : false }
                                                    size="small"
                                                    fullWidth
                                                    placeholder="Masukkan Nomor Transaksi"
                                                    onChange={(e) => setInput({...input, code_sales_marketing: e.target.value})}
                                                    required
                                                />
                                                <small className="text-danger">{ error.code_sales_marketing ? error.code_sales_marketing : '' }</small>
                                            </div>
                                            <div className="col-md-3 mb-1">
                                                <Button type="submit" sx={{padding: '8.5px 14px'}} fullWidth variant="contained" color="primary">
                                                    Cari Transaksi
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="table-responsive">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                                        <span className="form-control">No</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Kode Produk</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Produk</span>
                                                    </th>
                                                    <th className="text-center">
                                                        <span className="form-control">Jumlah Beli</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Harga Beli</span>
                                                    </th>
                                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                                        <span className="form-control">Action</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    nodata ? 
                                                    <tr>
                                                        <th colSpan={6} className="text-center">{ nodata }</th>
                                                    </tr> : ''
                                                }
                                                {
                                                    salesLists.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{index}</td>
                                                            <td>{data.code_product}</td>
                                                            <td>{data.product}</td>
                                                            <td className="text-center">{data.qty}</td>
                                                            <td>
                                                                <NumericFormat 
                                                                    value={ data.unit_price } 
                                                                    allowLeadingZeros 
                                                                    thousandSeparator="," 
                                                                    valueIsNumericString
                                                                    prefix="Rp. "
                                                                    displayType="text"
                                                                    renderText={(value) => <span>{value}</span>}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <Button variant="contained" disabled={data.retur ? true : false} size="small" sx={{backgroundColor: '#dd1cd1', ':hover' : { backgroundColor: '#b706ac' }}} onClick={() => onRetur(data.code_product)}>
                                                                    Retur
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-3 mb-3">
                        <form onSubmit={onSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-price mb-2">
                                        <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                            <span>
                                                Total Transaksi
                                            </span>
                                            <span>
                                                { sales.code }
                                            </span>
                                        </div>

                                        <div className="text-center price-total">
                                            <NumericFormat 
                                                value={ total_retur } 
                                                allowLeadingZeros 
                                                thousandSeparator="," 
                                                valueIsNumericString
                                                prefix="Rp. "
                                                displayType="text"
                                                renderText={(value) => <span>{value}</span>}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="card-in mb-2">
                                        <div onClick={handleModalChartOpen} style={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-center">
                                            <div>
                                                Lihat Keranjang Retur
                                            </div>
                                            <div>
                                                <ShoppingBag />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-in mb-2">
                                        <div style={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-center">
                                            <div>
                                                PPN
                                            </div>
                                            <div className="text-orange">
                                                <NumericFormat 
                                                    value={ ppn_price } 
                                                    allowLeadingZeros 
                                                    thousandSeparator="," 
                                                    valueIsNumericString
                                                    prefix="Rp. "
                                                    displayType="text"
                                                    renderText={(value) => <span>{value}</span>}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Keterangan</label>
                                        <textarea placeholder="Tulis Keterangan" required onChange={(e) => setInput({...input, desc: e.target.value})} style={{height: '80px'}} className="form-control"></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Upload Bukti Retur</label>
                                        <span className="form-control mb-1" onClick={takePhoto} style={{cursor: 'pointer'}}>
                                            <CameraAlt /> Ambil Foto
                                        </span>
                                        {
                                            picture ? 
                                            <img src={ picture } style={{width: '100%', height: '160px', borderRadius: '4px'}} /> : ''
                                        }
                                        <small className="text-danger">{ error.file ? error.file : '' }</small>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-1">
                                            <Button onClick={onCancelData} fullWidth variant="contained" color="error">
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="col-md-6 mb-1">
                                            <Button type="submit" fullWidth variant="contained" color="primary">
                                                Ajukan
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {
                open ? 
                <SalesReturChart 
                    open={open}
                    onClose={handleModalChartClose}
                    returList={returList}
                    onUpdateQty={handleUpdateQtyChart}
                    onCancelCart={handleCancelChartRetur}
                /> : ''
            }
        </SalesRetur>
    )
}

export default SalesReturForm
