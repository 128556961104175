import React from 'react'
import SalesData from '../../views/sales/data/SalesData'
import SalesDataDetail from '../../views/sales/data/SalesDataDetail'
import SalesForm from '../../views/sales/sales/form/SalesForm'
import SalesTempoData from '../../views/sales/tempo/SalesTempoData'
import SalesTempoDetail from '../../views/sales/tempo/SalesTempoDetail'

function SalesController({ view }) {
    if (view === 'sales-data') {
        return (
            <SalesData />
        )
    } else if (view === 'sales-data-detail') {
        return (
            <SalesDataDetail />
        )
    } else if (view === 'sales-form') {
        return (
            <SalesForm />
        )
    } else if (view === 'sales-tempo-data') {
        return (
            <SalesTempoData />
        )
    } else if (view === 'sales-tempo-detail') {
        return (
            <SalesTempoDetail />
        )
    }
}

export default SalesController
