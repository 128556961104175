import React from 'react'
import Home from '../../views/home/Home'

function HomeController() {
    return (
        <Home />
    )
}

export default HomeController
