import React, { useEffect, useState } from 'react'
import RkapRealisasi from '../RkapRealisasi'
import { useDispatch } from 'react-redux'

import { NumericFormat } from 'react-number-format';
import { getRkapVariableCompare } from '../../../../models/rkap/rkap_variable_m';

const RkapRealisasiData = () => {
    const dispatch = useDispatch();

    const [rkap, setRkap] = useState([]);

    const getData = async() => {
        try {
            const response = await dispatch(getRkapVariableCompare()).unwrap().catch((err) => {});
            setRkap(response.data)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <RkapRealisasi>
            <div className="row mt-3">
                <div className="col-md-1"></div>
                <div className="col-md-10 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive mb-3">
                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th className="table-border-start text-center" style={{width: '5%'}}>
                                                <span className="form-control fw-bold">No</span>
                                            </th>
                                            <th style={{width: '30%'}}>
                                                <span className="form-control fw-bold">Uraian</span>
                                            </th>
                                            <th>
                                                <span className="form-control fw-bold">RKAP Tahun { new Date().getFullYear() } </span>
                                            </th>
                                            <th>
                                                <span className="form-control fw-bold">Realisasi RKAP Tahun { new Date().getFullYear() } </span>
                                            </th>
                                            <th className="text-center" style={{width: '15%'}}>
                                                <span className="form-control fw-bold">Persentasi Realisasi</span>
                                            </th>                                           
                                            <th className="table-border-end text-center" style={{width: '15%'}}>
                                                <span className="form-control fw-bold">Lebih Kurang</span>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            rkap.map((data, index) => (
                                                <tr key={index}>
                                                    <th className="text-center">{ index + 1 }</th>
                                                    <th>{ data.name }</th>
                                                    <th>
                                                        <NumericFormat 
                                                            value={ data.total_target } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </th>
                                                    <th>
                                                        <NumericFormat 
                                                            value={ data.total_realisasi } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </th>
                                                    <th className="text-center">
                                                        { parseFloat((data.total_realisasi / data.total_target) * 100).toFixed(3) } %
                                                    </th>
                                                    <th className="text-center">
                                                        { parseFloat(100 - ((data.total_realisasi / data.total_target) * 100)).toFixed(3) } %
                                                    </th>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-1"></div>
            </div>
        </RkapRealisasi>
    )
}

export default RkapRealisasiData
