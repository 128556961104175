import React, { useEffect, useState } from 'react'
import Delivery from '../../Delivery'
import { useDispatch } from 'react-redux'
import { getSalesMarketingByStatusJadwalPengiriman } from '../../../../models/sales/marketing/sales_marketing_m';
import Moment from 'react-moment';
import { Autocomplete, Button, Checkbox, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { storeDelivery } from '../../../../models/delivery/delivery_m';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import Paginate from '../../../pagination/Paginate'
import { getEmployeeByNameJabatan } from '../../../../models/employee/employee_m';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="Rp. "
        />
    );
});

NumericFormatCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const DeliveryScheduleForm = () => {
    const dispatch = useDispatch();
    const { username } = useParams();

    const [sales, setSales] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [pagination, setPagination] = useState([]);

    const getData = async(page) => {
        try {
            const data = {
                status_jadwal_pengiriman: 'process',
                page: page
            }
            const response = await dispatch(getSalesMarketingByStatusJadwalPengiriman(data)).unwrap().catch((err) => {});
            setSales(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    const getDataEmployee = async(name) => {
        try {
            const data = {
                name: name,
                jabatan: 'Staf Sub Bidang Pengiriman'
            }
            const response = await dispatch(getEmployeeByNameJabatan(data)).unwrap().catch((err) => {});
            setEmployee(response.data.map((i) => ({code: i.code, name: i.name, label: i.name})));
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
        getDataEmployee('');
    }, []);

    const handlepagination = (_, page) => {
        return getData(page);
    }

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const time = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
    const [input, setInput] = useState({
        code: `DO${day}${month}${year}${time}`, code_employee: '', employee: '', tgl_pengiriman: '', desc:''
    })

    const [list, setList] = useState([]);

    const handleCheckbox = (e, index, code) => {
        if (e.target.checked === true) {
            setList([
                ...list,
                {
                    code_sales_marketing: code
                }
            ])
        } else {
            const newLists = Array.from(list);
            newLists.splice(index, 1);
            setList(newLists);
        }
    }

    const onSubmit = async(e) => {
        e.preventDefault();

        const data = {
            ...input,
            delivery_lists : {...list}
        }

        try {
            const response = await dispatch(storeDelivery(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Data berhasil disimpan')
                setTimeout(() => {
                   return window.location.href = `/${username}/delivery/data` 
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <Delivery>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>BUAT JADWAL PENGIRIMAN</h3>
                                <span>Buat Jadwal Pengiriman</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-9 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive mb-3">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                                        <span className="form-control">No</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Kode Transaksi</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Tanggal Transaksi</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Marketing</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control">Alamat</span>
                                                    </th>
                                                    <th className="table-border-end text-center" style={{width: '8%'}}>
                                                        <span className="form-control">Pilih</span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    sales.map((data, index) => (
                                                        <tr key={data.no}>
                                                            <td className="text-center">{data.no}</td>
                                                            <td>{data.code}</td>
                                                            <td>
                                                                <Moment format="dddd, DD MMMM YYYY">{data.date}</Moment>
                                                            </td>
                                                            <td>
                                                                { data.marketing }
                                                            </td>
                                                            <td>
                                                                { data.address }
                                                            </td>
                                                            <td className="text-center">
                                                                <Checkbox onChange={(e) => handleCheckbox(e, index, data.code )} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* pagination */}
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <span>Halaman : { pagination.current_page ? pagination.current_page : '' }</span>
                                        </div>
                                        {
                                            pagination.pagination ? 
                                            <Paginate count={pagination.total} page={pagination.current_page} onChange={handlepagination} /> : ''
                                        }
                                        <div>
                                            <span>Total : { pagination.total } List</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Kode Pengiriman</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            value={input.code}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Petugas</label>
                                        <Autocomplete 
                                            size="small"
                                            fullWidth
                                            value={input.employee}
                                            options={employee}
                                            disableClearable
                                            renderInput={(params) => <TextField {...params} required onChange={(e) => getDataEmployee(e.target.value)} placeholder="Petugas" />}
                                            onChange={(_, event) => setInput({...input, code_employee: event.code, employee: event.name})}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Tanggal Pengiriman</label>
                                        <DatePicker 
                                            slotProps={{textField: {size: 'small', fullWidth: true}}}
                                            onChange={(e) => setInput({...input, tgl_pengiriman: `${e['$y']}-${e['$M'] + 1}-${e['$D']}`, month_year: `${e['$y']}-${e['$M']}`})}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Keterangan</label>
                                        <textarea placeholder="Tulis keterangan" style={{height: '100px'}} onChange={(e) => setInput({...input, desc: e.target.value})} className="form-control"></textarea>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button disabled={ pagination.total ? false : true } variant="contained" color="primary" type="submit">
                                            Simpan Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Delivery>
    )
}

export default DeliveryScheduleForm
