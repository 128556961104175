import React from 'react'
import StoreData from '../../views/mitra/store/data/StoreData'
import StoreForm from '../../views/mitra/store/form/StoreForm'
import StoreDataDetail from '../../views/mitra/store/data/StoreDataDetail'

function StoreController({ view }) {
    if (view === 'store-data-detail') {
        return (
            <StoreDataDetail />
        )
    } else if(view === 'store-form') {
        return (
            <StoreForm />
        )
    } else {
        return (
            <StoreData />
        )
    }
}

export default StoreController
