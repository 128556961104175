import React, { useEffect, useState } from 'react'
import Financial from '../Financial'
import { useDispatch } from 'react-redux'

import { NumericFormat } from 'react-number-format';
import { getRkapVariableCompare } from '../../../models/rkap/rkap_variable_m';

const FinancialData = () => {
    const dispatch = useDispatch();

    const [rkap, setRkap] = useState([]);

    const getData = async() => {
        try {
            const response = await dispatch(getRkapVariableCompare()).unwrap().catch((err) => {});
            setRkap(response.data);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData();
    }, [])

    const pendapatan = rkap.filter(rkap => rkap.status_bisnis === 'bisnis' && rkap.status_kas === 'masuk').reduce((i, j) => i + parseFloat(j.total_realisasi), 0);
    const hpp = rkap.filter(rkap => rkap.status_bisnis === 'bisnis' && rkap.status_kas === 'keluar').reduce((i, j) => i + parseFloat(j.total_realisasi), 0);

    const pendapatan_non_bisnis = rkap.filter(rkap => rkap.status_bisnis === 'non' && rkap.status_kas === 'masuk').reduce((i, j) => i + parseFloat(j.total_realisasi), 0);
    const biaya = rkap.filter(rkap => rkap.status_bisnis === 'non' && rkap.status_kas === 'keluar').reduce((i, j) => i + parseFloat(j.total_realisasi), 0);

    const laba_kotor = pendapatan - hpp;

    const laba_bersih = laba_kotor + pendapatan_non_bisnis - biaya;

    return (
        <Financial>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>Data Laporan Keuangan</h3>
                                <span>Rekap Laporan Keuangan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="row">
                    <div className="col-md-5 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control fw-bold">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">Uraian</span>
                                                </th>
                                                <th className="table-border-end">
                                                    <span className="form-control fw-bold">Nominal</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                rkap.filter(rkap => rkap.status_bisnis === 'bisnis').map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <th>{ index + 1 }</th>
                                                            <th>{ data.name }</th>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>{ data.name }</td>
                                                            <td>
                                                                <NumericFormat 
                                                                    value={ data.total_realisasi } 
                                                                    allowLeadingZeros 
                                                                    thousandSeparator="," 
                                                                    valueIsNumericString
                                                                    prefix="Rp. "
                                                                    displayType="text"
                                                                    renderText={(value) => <span>{value}</span>}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))
                                            }
                                            <tr>
                                                <th colSpan={2}>LABA KOTOR</th>
                                                <th>
                                                    <NumericFormat 
                                                        value={ laba_kotor } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control fw-bold">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">Uraian</span>
                                                </th>
                                                <th className="table-border-end">
                                                    <span className="form-control fw-bold">Nominal</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                rkap.filter(rkap => rkap.status_bisnis === 'non').map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <th>{ index + 1 }</th>
                                                            <th>{ data.name }</th>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>{ data.name }</td>
                                                            <td>
                                                                <NumericFormat 
                                                                    value={ data.total_realisasi } 
                                                                    allowLeadingZeros 
                                                                    thousandSeparator="," 
                                                                    valueIsNumericString
                                                                    prefix="Rp. "
                                                                    displayType="text"
                                                                    renderText={(value) => <span>{value}</span>}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))
                                            }
                                            <tr>
                                                <th colSpan={2}>LABA BERSIH SEBELUM PAJAK</th>
                                                <th>
                                                    <NumericFormat 
                                                        value={ laba_bersih } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Financial>
    )
}

export default FinancialData
