import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../instanceapi";

const initialState = {

}

export const storeWarehouseSpoil = createAsyncThunk('warehouse-spoil/storeWarehouseSpoil', async(data) => {
    try {
        const response = await instanceapi.post('/warehouse/spoil/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getDetailSpoil = createAsyncThunk('warehouse-spoil/getDetailSpoil', async(code) => {
    try {
        const response = await instanceapi.get(`/warehouse/spoil/detail/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSpoilByStatus = createAsyncThunk('warehouse-spoil/getSpoilByStatus', async(data) => {
    try {
        const response = await instanceapi.get(`/warehouse/spoil/status/${data.status}?page=${data.page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const updateSpoilValidasi = createAsyncThunk('warehouse-spoil/updateSpoilValidasi', async(code) => {
    try {
        const response = await instanceapi.post(`/warehouse/spoil/update/validasi/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const deleteSpoil = createAsyncThunk('warehouse-spoil/deleteSpoil', async(code) => {
    try {
        const response = await instanceapi.delete(`/warehouse/spoil/delete/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const warehouse_spoil_m = createSlice({
    name: 'warehouse-spoil',
    initialState
})

export default warehouse_spoil_m.reducer;