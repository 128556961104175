import React from 'react'
import Reseller from '../Reseller'

const ResellerData = () => {
    return (
        <Reseller>
            
        </Reseller>
    )
}

export default ResellerData
