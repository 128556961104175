import React from 'react'
import SalesSpoilCashier from '../../views/sales/spoil/cashier/SalesSpoilCashier'
import SalesSpoilValidation from '../../views/sales/spoil/validation/SalesSpoilValidation'
import SalesSpoilData from '../../views/sales/spoil/data/SalesSpoilData'
import SalesSpoilValidationDetail from '../../views/sales/spoil/validation/SalesSpoilValidationDetail'
import SalesSpoilDataDetail from '../../views/sales/spoil/data/SalesSpoilDataDetail'

function SalesSpoilController({ view }) {
    if (view === 'cashier') {
        return (
            <SalesSpoilCashier />
        )
    } else if (view === 'validasi') {
        return (
            <SalesSpoilValidation />
        )
    } else if (view === 'validasi-detail') {
        return (
            <SalesSpoilValidationDetail />
        )
    } else if (view === 'data-detail') {
        return (
            <SalesSpoilDataDetail />
        )
    } else {
        return (
            <SalesSpoilData />
        )
    }
}

export default SalesSpoilController
