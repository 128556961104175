import React, { useEffect, useState } from 'react'
import Warehouse from '../../Warehouse'
import { ReplyAll } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getDetailPurchaseRetur } from '../../../../models/purchase/purchase_retur_m'
import toast from 'react-hot-toast'
import Loading from '../../../loading/Loading'
import { NumericFormat } from 'react-number-format'
import Moment from 'react-moment'

const WarehouseReturDataDetail = () => {
    const dispatch = useDispatch();
    const { username, code } = useParams();

    const handleBack = () => {
        return window.location.href = `/${username}/warehouse/retur/data`
    }

    const [retur, setRetur] = useState([]);
    const [returLists, setReturLists] = useState([]);
    const [loading, setLoading] = useState(false)

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailPurchaseRetur(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setRetur(response.retur)
                setReturLists(response.lists);
            }

            if (response.status === 404) {
                setLoading(true)
                toast.error('Data tidak ditemukan')
                setTimeout(() => {
                    return window.location.href = `/${username}/warehouse/retur/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail()
    }, []);

    return (
        <Warehouse>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>Retur Pembelian { code }</h3>
                                <span>Detail Data Retur Pembelian</span>
                            </div>

                            <div className="d-flex">
                                <div onClick={handleBack} className="text-center me-2" style={{cursor: 'pointer'}}>
                                    <span className="btn btn-icon btn-orange" style={{padding: '.4rem .4rem', borderRadius: '100%',}}>
                                        <ReplyAll sx={{width: '1.25rem', height: '1.25rem'}} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    <table className="table-borderless table-sm">
                                        <tbody>
                                            <tr>
                                                <th>Kode Retur</th>
                                                <td style={{width: '40%'}}>: { code }</td>

                                                <th>PPN</th>
                                                <td>
                                                    : <NumericFormat 
                                                        value={ retur.ppn } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Kode Transaksi</th>
                                                <td>: { retur.code_purchase_total }</td>

                                                <th>Nominal Retur</th>
                                                <td>
                                                    : <NumericFormat 
                                                        value={ retur.nominal } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Supplier</th>
                                                <td>: { retur.supplier }</td>

                                                <th>Tanggal Validasi</th>
                                                <td>
                                                    : <Moment format="dddd, DD MMMM YYYY">{ retur.tgl_validasi }</Moment>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Kode Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Jumlah Retur</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Harga Satuan</span>
                                                </th>
                                                <th className="table-border-end">
                                                    <span className="form-control">Sub Total</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                returLists.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{data.code_product}</td>
                                                        <td>{data.product}</td>
                                                        <td className="text-center">{data.qty}</td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.unit_price } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.sub_total } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Warehouse>
    )
}

export default WarehouseReturDataDetail
