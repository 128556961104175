import React from 'react'
import WarehouseReturData from '../../views/warehouse/retur/data/WarehouseReturData'
import WarehouseReturValidation from '../../views/warehouse/retur/validation/WarehouseReturValidation'
import WarehouseReturValidationDetail from '../../views/warehouse/retur/validation/WarehouseReturValidationDetail'
import WarehouseReturDataDetail from '../../views/warehouse/retur/data/WarehouseReturDataDetail'

function WarehouseReturController({ view }) {
    if(view === 'data') {
        return (
            <WarehouseReturData />
        )
    }  else if(view === 'data-detail') {
        return (
            <WarehouseReturDataDetail />
        )
    } else if(view === 'validasi') {
        return (
            <WarehouseReturValidation />
        )
    } else if(view === 'validasi-detail') {
        return (
            <WarehouseReturValidationDetail />
        )
    }
}

export default WarehouseReturController
