import React, { useEffect, useState } from 'react'
import Financial from '../Financial'
import { ReplyAll } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getDetailSalesMarketing } from '../../../models/sales/marketing/sales_marketing_m'
import Moment from 'react-moment'

import { NumericFormat } from 'react-number-format';
import Loading from '../../loading/Loading'

const FinancialPiutangDetail = () => {
    const dispatch = useDispatch();

    const { username, code } = useParams();

    const handleBack = () => {
        return window.location.href = `/${username}/financial/piutang`
    }

    const [sales, setSales] = useState([]);
    const [list, setList] = useState([]);
    const [marketing, setMarketing] = useState([]);
    const [tempo, setTempo] = useState([]);

    const [loading, setLoading] = useState(false)

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailSalesMarketing(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setSales(response.sales)
                setMarketing(response.marketing)
                setList(response.list)
                setTempo(response.tempo)
            }
            if (response.status === 404) {
                setLoading(true)
                setTimeout(() => {
                   return window.location.href = `/${username}/financial/piutang` 
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail();
    }, []);

    return (
        <Financial>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>Detail Piutang { code }</h3>
                                <span>Detail Piutang</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            <table className="table table-borderless table-sm">
                                <tbody>
                                    <tr>
                                        <th>Kode Transaksi</th>
                                        <td>: { sales.code }</td>

                                        <th>Marketing</th>
                                        <td>: { sales.marketing }</td>

                                        <th>Status Pengiriman</th>
                                        <td>
                                            : {
                                                sales.status_pengiriman === 'diterima' ?
                                                <span className="badge bg-green">Diterima</span> : 
                                                sales.status_pengiriman === 'perjalanan' ?
                                                <span className="badge bg-pink">Perjalanan</span> : 
                                                <span className="badge bg-orange">Proses</span>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Tanggal Transaksi</th>
                                        <td>
                                            : <Moment format="dddd, DD MMMM YYYY">{sales.tgl_transaksi}</Moment>
                                        </td>

                                        <th>Alamat</th>
                                        <td>: { marketing.address }</td>

                                        <th>Sistem Pembayaran</th>
                                        <td>
                                            : {
                                                sales.sistem_pembayaran === 'tunai' ? 
                                                <span className="badge bg-primary">Tunai</span> : <span className="badge bg-info">Tempo</span>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Petugas</th>
                                        <td>
                                            : { sales.petugas }
                                        </td>

                                        <th>Nomor Telephone</th>
                                        <td>: { marketing.contact_primary }</td>

                                        <th>Status</th>
                                        <td>
                                            : {
                                                sales.status_pembayaran === 'lunas' ? 
                                                <span className="badge bg-green">Lunas</span> : 
                                                <span className="badge bg-danger">Belum Lunas</span>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive mb-3">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode Produk</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nama Produk</span>
                                        </th>
                                        <th className="text-center">
                                            <span className="form-control">Jumlah</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Harga Satuan</span>
                                        </th>
                                        <th className="table-border-end">
                                            <span className="form-control">Sub Total</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((data, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{ index + 1 }</td>
                                                <td>{ data.code_product }</td>
                                                <td>{ data.product }</td>
                                                <td className="text-center">{data.qty}</td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.unit_price } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.sub_total } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Tanggal Pembayaran</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nominal</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Terbayar</span>
                                        </th>
                                        <th className="table-border-end text-center">
                                            <span className="form-control">Status</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tempo.map((data, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{ index + 1 }</td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{ data.tgl_pembayaran }</Moment>
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.nominal } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.terbayar } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        data.nominal !== data.terbayar ? 
                                                        <span className="badge bg-danger">Belum Lunas</span> : 
                                                        <span className="badge bg-green">Lunas</span>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </Financial>
    )
}

export default FinancialPiutangDetail
