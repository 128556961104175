import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getSalesMarketingTempo } from '../../../models/sales/marketing/sales_marketing_m';
import Moment from 'react-moment';

import { NumericFormat } from 'react-number-format';

const SalesDataTempo = ({code_sales_marketing}) => {
    const dispatch = useDispatch();

    const [tempo, setTempo] = useState([]);
    const getTempo = async() => {
        try {
            const response = await dispatch(getSalesMarketingTempo(code_sales_marketing)).unwrap().catch((err) => {});
            setTempo(response.data);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getTempo();
    }, []);

    return (
        <>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="table-border-start text-center" style={{width: '5%'}}>
                                <span className="form-control">No</span>
                            </th>
                            <th>
                                <span className="form-control">Tanggal Pembayaran</span>
                            </th>
                            <th>
                                <span className="form-control">Nominal</span>
                            </th>
                            <th>
                                <span className="form-control">Terbayar</span>
                            </th>
                            <th className="table-border-end text-center">
                                <span className="form-control">status</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tempo.map((data, index) => (
                                <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td>
                                        <Moment format="dddd, DD MMMM YYYY">{data.tgl_pembayaran}</Moment>
                                    </td>
                                    <td>
                                        <NumericFormat 
                                            value={ data.nominal } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </td>
                                    <td>
                                        <NumericFormat 
                                            value={ data.terbayar } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </td>
                                    <td className="text-center">
                                        {
                                            data.nominal === data.terbayar ? 
                                            <span className="badge bg-green">Lunas</span> :
                                            <span className="badge bg-orange">Belum Lunas</span>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default SalesDataTempo
