import React, { useState } from 'react'
import { bglogin, logo } from '../images'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { AccountCircle, LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../models/auth/auth_m'
import toast, { Toaster } from 'react-hot-toast'
import Loading from '../loading/Loading'

const Auth = () => {
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);
    const [input, setInput] = useState({
        username: '', password: ''
    })


    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true);
        const data = {...input};
        try {
            const response = await dispatch(loginUser(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                const data = response.data;
                toast.success('Login berhasil divalidasi');
                localStorage.setItem('user_data', JSON.stringify(data));
                setTimeout(() => {
                    return window.location.href = `/${data.username}/select-role`
                }, 1200);
            }

            if (response.status === 400) {
                setLoading(false)
                setError(response.message);
                toast.error('Periksa kembali data Anda')
                setTimeout(() => {
                    setError([])
                }, 2000);
            }

            if (response.status === 403) {
                setLoading(false)
                setError(response.message);
                toast.error('Periksa kembali data Anda')
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }

    return (
        <div>
            <Toaster position="top-center" />
            { loading ? <Loading /> : '' }
            <div className="row g-0 flex-fill">
                <div className="col-12 col-lg-6 col-xl-8 d-none d-lg-block">
                    <div className="bg-cover h-100 min-vh-100" style={{ backgroundImage: `url(${bglogin})` }}></div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4 border-top-wide border-primary d-flex flex-column justify-content-center">
                    <div className="container container-tight my-5 px-lg-5">
                        <div className="login-mobile">
                            <div className="text-center mb-2">
                                <a href="" className="navbar-brand navbar-brand-autodark">
                                    <img src={ logo } alt="" />
                                </a>
                            </div>
                            <h2 className="h3 text-center mb-3">
                                Login to your account
                            </h2>
                            <form onSubmit={onSubmit}>
                                <div className="mb-2">
                                    <label className="form-label form-label-m-0">Username &nbsp; &nbsp; <small className="text-danger">{ error.username ? error.username : '' }</small></label>
                                    <TextField 
                                        error={ error.username ? true : false }
                                        size="small"
                                        fullWidth
                                        value={input.username}
                                        placeholder="Masukkan Username"
                                        InputProps={{
                                            startAdornment: ( <InputAdornment position="start"> <AccountCircle /> </InputAdornment> ),
                                        }}
                                        onChange={(e) => setInput({...input, username: e.target.value.trim().toLowerCase()})}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">
                                        Password
                                        &nbsp; &nbsp; <small className="text-danger">{ error.password ? error.password : '' }</small>
                                    </label>
                                    <TextField 
                                        error={ error.password ? true : false }
                                        size="small"
                                        fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Masukkan Password"
                                        InputProps={{
                                            startAdornment: ( <InputAdornment position="start"> <LockOutlined /> </InputAdornment> ),
                                            endAdornment: (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            )
                                        }}
                                        onChange={(e) => setInput({...input, password: e.target.value})}
                                        required
                                    />
                                </div>
                                <div>
                                    <Button fullWidth variant="contained" color="primary" type="submit">
                                        Login
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth
