import React from 'react'
import Sales from '../Sales'
import { Link, useLocation, useParams } from 'react-router-dom'
import { PlaylistAddCheckCircle, PlaylistAddCircle } from '@mui/icons-material';

const SalesSpoil = ({ children }) => {
    const { username } = useParams();
    const location = useLocation();
    const path = location.pathname;
    const split = path.split('/');

    return (
        <Sales>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="sub-menu">
                            <ul>
                                <li>
                                    <Link to={`/${username}/sales/spoil/data`} className={` sub-menu-item ${split[4] === 'data'? 'active' : ''} `}>
                                        <div className="me-2">
                                            <PlaylistAddCircle />
                                        </div>
                                        <span>Data Spoil</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${username}/sales/spoil/validasi`} className={` sub-menu-item ${split[4] === 'validasi'? 'active' : ''} `} >
                                        <div className="me-2">
                                            <PlaylistAddCheckCircle />
                                        </div>
                                        <span>Validasi</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${username}/sales/spoil/cashier`} className={` sub-menu-item ${split[4] === 'cashier'? 'active' : ''} `} >
                                        <div className="me-2">
                                            <PlaylistAddCheckCircle />
                                        </div>
                                        <span>Kasir Spoil</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            { children }
        </Sales>
    )
}

export default SalesSpoil
