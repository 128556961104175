import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../../instanceapi";

const initialState = {

}

export const getSalesMarketingRetur = createAsyncThunk('sales-marketing-retur/getSalesMarketingRetur', async(page) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/retur?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const storeSalesMarketingRetur = createAsyncThunk('sales-marketing-retur/storeSalesMarketingRetur', async(data) => {
    try {
        const response = await instanceapi.post('/sales/marketing/retur/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingReturStatus = createAsyncThunk('sales-marketing-retur/getSalesMarketingReturStatus', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/retur/status/${data.status}?page=${data.page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingReturDetail = createAsyncThunk('sales-marketing-retur/getSalesMarketingReturDetail', async(code) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/retur/detail/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const postSalesMarketingReturCancel = createAsyncThunk('sales-marketing-retur/postSalesMarketingReturCancel', async(data) => {
    try {
        const response = await instanceapi.post(`/sales/marketing/retur/validasi/cancel/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const updateSalesMarketingValidasi = createAsyncThunk('sales-marketing-retur/updateSalesMarketingValidasi', async(data) => {
    try {
        const response = await instanceapi.post(`/sales/marketing/retur/validasi/success/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingReturFilterData = createAsyncThunk('sales-marketing-retur/getSalesMarketingReturFilterData', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/retur/filter/year/month/name/${data.year}/${data.month}/${data.name}?page=${data.page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const sales_marketing_retur_m = createSlice({
    name: 'sales-marketing-retur',
    initialState
})

export default sales_marketing_retur_m.reducer;