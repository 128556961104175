import React, { useEffect, useState } from 'react'
import Operasional from '../Operasional'
import { useNavigate, useParams } from 'react-router-dom';
import { TableView } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { getOperasionalNonBisnis } from '../../../models/operasional/operasional_non_bisnis_m';

import Paginate from '../../pagination/Paginate'

import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment'

const OperasionalNonBisnis = () => {
    const { username } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goForm = () => {
        return navigate(`/${username}/operasional/non-bisnis/form`)
    }

    const [operasional, setOperasional] = useState([]);
    const [pagination, setPagination] = useState([]);
    const getData = async(page) => {
        try {
            const response = await dispatch(getOperasionalNonBisnis(page)).unwrap().catch((err) => {});
            setOperasional(response.data)
            setPagination(response.pagination)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);

    const handlePagination = (_, page) => {
        return getData(page);
    }

    return (
        <Operasional>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DATA BIAYA NON BISNIS</h3>
                                <span>List Data Biaya Non Bisnis</span>
                            </div>
                            <div onClick={goForm} className="text-center me-2" style={{ cursor: 'pointer' }}>
                                <span className="btn btn-icon btn-orange" style={{ padding: '.4rem .4rem' }}>
                                    <TableView sx={{ width: '1.25rem', height: '1.25rem' }} />
                                </span>
                                <span style={{ fontSize: '12px' }} className="d-block">Input Biaya</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="text-center">
                                <h3>
                                    DATA BIAYA NON BISNIS PERUMDA ANEKA USAHA <br />
                                    KABUPATEN JEPARA <br />
                                    TAHUN { new Date().getFullYear() }
                                </h3>
                            </div>
                            <table className="table align-middle mb-3">
                                <thead>
                                    <tr>
                                        <th className="text-center table-border-start" style={{width: '5%'}}>
                                            <span className="form-control fw-bold">
                                                No
                                            </span>
                                        </th>
                                        <th style={{width: '15%'}}>
                                            <span className="form-control fw-bold">
                                                Kode Kegiatan
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Tanggal Kegiatan
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                RKAP
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Kegiatan
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Nominal
                                            </span>
                                        </th>
                                        <th style={{width: '15%'}} className="text-center table-border-end">
                                            <span className="form-control fw-bold">
                                                Lampiran
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        operasional.map((data) => (
                                            <tr key={data.no}>
                                                <td className="text-center">{data.no}</td>
                                                <td>{data.code}</td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{data.date}</Moment>, { data.time }
                                                </td>
                                                <td>{data.rkap}</td>
                                                <td>
                                                    {data.name}
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.nominal } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <a href={ data.file } target="_blank">
                                                        Lihat Lampiran
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            {/* pagination */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <span>Halaman : { pagination.current_page }</span>
                                </div>
                                {
                                    pagination.pagination ? 
                                    <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} /> : ''
                                }
                                <div>
                                    <span>Total : { pagination.total } List</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Operasional>
    )
}

export default OperasionalNonBisnis
