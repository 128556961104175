import React, { useState } from 'react'
import Reseller from '../Reseller'
import { Button, MenuItem, Select, TextField } from '@mui/material'

import { imageResizer } from '../../../../utils'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { storeReseller } from '../../../../models/reseller/reseller_m'
import toast from 'react-hot-toast'

import Loading from '../../../loading/Loading'

const ResellerForm = () => {
    const dispatch = useDispatch();

    const { username } = useParams();

    const [input, setInput] = useState({
        code: '', no_identity: '', name: '', address: '', phone_primary: '', status: '',
        phone_second: '', phone_second_name: '', email: '', photo: '', desc: '', bidang_usaha: '',
        username: '', password: '', website: '', docs: ''
    })

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);


    const handleChangePhoto = async(e) => {
        const file = e.target.files[0];
        const resizer = await imageResizer(file, 240, 240);
        setInput({...input, photo: resizer})
    } 
    const handleChangeDocs = async(e) => {
        const file = e.target.files[0];
        setInput({...input, docs: file})
    }
    

    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        
        if (input.username === '') {
            input.username = input.phone_primary
        } else {
            input.username = input.username
        }

        if (input.password === '') {
            input.password = input.phone_primary
        } else{
            input.password = input.password
        }

        try {   
            const response = await dispatch(storeReseller(input)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Data berhasil disimpan')
                setTimeout(() => {
                   return window.location.href = `/${username}/mitra/reseller/data` 
                }, 1200);
            }

            if (response.status === 400) {
                toast.error('Periksa kembali data Anda')
                setError(response.message);
                setTimeout(() => {
                    setError([]);
                }, 1500);
            }
        } catch (error) {
            
        }
    }

    const company = (
        <>
            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">NIB</label>
                            <TextField 
                                error={ error.no_identity ? true : false }
                                size="small"
                                fullWidth
                                placeholder="Tulis NIB Perusahaan"
                                onChange={(e) => setInput({...input, no_identity: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.no_identity ? error.no_identity : '' }</small>
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Bidang Usaha</label>
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Tulis Bidang Usaha"
                                onChange={(e) => setInput({...input, bidang_usaha: e.target.value})}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Alamat</label>
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Tulis Alamat"
                                onChange={(e) => setInput({...input, address: e.target.value})}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Email</label>
                            <TextField 
                                error={ error.email ? true : false }
                                size="small"
                                fullWidth
                                type="email"
                                placeholder="Tulis Email"
                                onChange={(e) => setInput({...input, email: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.email ? error.email : '' }</small>
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Website</label>
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Tulis Website ( optional )"
                                onChange={(e) => setInput({...input, website: e.target.value})}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Kontak Perusahaan</label>
                            <TextField 
                                error={ error.phone_primary ? true : false }
                                size="small"
                                fullWidth
                                placeholder="Kontak Perusahaan"
                                onChange={(e) => setInput({...input, phone_primary: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.phone_primary ? error.phone_primary : '' }</small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Nama PIC</label>
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Nama PIC"
                                onChange={(e) => setInput({...input, phone_second_name: e.target.value})}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Kontak PIC</label>
                            <TextField 
                                error={ error.phone_second ? true : false }
                                size="small"
                                fullWidth
                                placeholder="Kontak PIC"
                                onChange={(e) => setInput({...input, phone_second: e.target.value})}
                            />
                            <small className="text-danger">{ error.phone_second ? error.phone_second : '' }</small>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label className="form-label form-label-m-0">Username</label>
                                    <TextField 
                                        error={ error.username ? true : false }
                                        required={ input.password !== '' ? true : false }
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Username"
                                        onChange={(e) => setInput({...input, username: e.target.value})}
                                    />
                                    <small className="text-danger">{ error.username ? error.username : '' }</small>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label className="form-label form-label-m-0">Password</label>
                                    <TextField 
                                        required={ input.username !== '' ? true : false }
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Password"
                                        onChange={(e) => setInput({...input, password: e.target.value})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const individu = (
        <>
            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">NIK</label>
                            <TextField 
                                error={error.no_identity ? true : false}
                                size="small"
                                fullWidth
                                placeholder="Tulis NIK"
                                onChange={(e) => setInput({...input, no_identity: e.target.value})}
                                required
                            />
                            <small className="text-dange">{ error.no_identity ? error.no_identity : '' }</small>
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Bidang Usaha</label>
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Tulis Bidang Usaha"
                                onChange={(e) => setInput({...input, bidang_usaha: e.target.value})}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Alamat</label>
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Tulis Alamat"
                                onChange={(e) => setInput({...input, address: e.target.value})}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Email</label>
                            <TextField 
                                error={ error.email ? true : false }
                                size="small"
                                fullWidth
                                type="email"
                                placeholder="Tulis Email"
                                onChange={(e) => setInput({...input, email: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.email ? error.email : '' }</small>
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Website</label>
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Tulis Website ( optional )"
                                onChange={(e) => setInput({...input, website: e.target.value})}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Kontak Reseller</label>
                            <TextField 
                                error={ error.phone_primary ? true : false }
                                size="small"
                                fullWidth
                                placeholder="Kontak Reseller"
                                onChange={(e) => setInput({...input, phone_primary: e.target.value})}
                                required
                            />
                            <small className="text-danger">{ error.phone_primary ? error.phone_primary : '' }</small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <div className="card">
                    <div className="card-body">
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Nama Kontak Darurat</label>
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Nama Kontak Darurat"
                                onChange={(e) => setInput({...input, phone_second_name: e.target.value})}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label form-label-m-0">Kontak Darurat</label>
                            <TextField 
                                error={error.phone_second ? true : false}
                                size="small"
                                fullWidth
                                placeholder="Kontak Darurat"
                                onChange={(e) => setInput({...input, phone_second: e.target.value})}
                            />
                            <small className="text-danger">{ error.phone_second ? error.phone_second : '' }</small>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label className="form-label form-label-m-0">Username</label>
                                    <TextField 
                                        error={error.username ? true : false}
                                        required={ input.password !== '' ? true : false }
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Username"
                                        onChange={(e) => setInput({...input, username: e.target.value})}
                                    />
                                    <small className="text-danger">{ error.username ? error.username : '' }</small>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label className="form-label form-label-m-0">Password</label>
                                    <TextField 
                                        required={ input.username !== '' ? true : false }
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Password"
                                        onChange={(e) => setInput({...input, password: e.target.value})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    return (
        <Reseller>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row row-deck">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Kode Reseller</label>
                                        <TextField 
                                            error={ error.code ? true : false }
                                            size="small"
                                            fullWidth
                                            placeholder="Kode Reseller"
                                            onChange={(e) => setInput({...input, code: e.target.value})}
                                            required
                                        />
                                        <small className="text-danger">{ error.code ? error.code : '' }</small>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Status Reseller</label>
                                        <Select
                                            size="small"
                                            fullWidth
                                            displayEmpty
                                            value={input.status}
                                            onChange={(e) => setInput({...input, status: e.target.value})}
                                            required
                                        >
                                            <MenuItem value="" disabled>Status Reseller</MenuItem>
                                            <MenuItem value="company">Badan</MenuItem>
                                            <MenuItem value="individu">Perorangan</MenuItem>
                                        </Select>
                                    </div>
                                    <div>
                                        <label className="form-label form-label-m-0">Nama Reseller</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Nama Reseller"
                                            onChange={(e) => setInput({...input, name: e.target.value})}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            input.status === 'company' ? 
                            company : input.status === 'individu' ? 
                            individu : ''
                        }

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <label className="form-label form-label-m-0">Keterangan</label>
                                    <textarea style={{height: '190px'}} placeholder="Tulis Keterangan" onChange={(e) => setInput({...input, desc: e.target.value})} className="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Upload Foto</label>
                                        <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={handleChangePhoto} className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Upload Dokumen</label>
                                        <input type="file" className="form-control" onChange={handleChangeDocs} />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button variant="contained" color="primary" type="submit">
                                            Simpan Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Reseller>
    )
}

export default ResellerForm
