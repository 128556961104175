import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../instanceapi";

const initialState = {};

export const getRkapVariable = createAsyncThunk('rkap-variable/getRkapVariable', async(page) => {
    try {
        const response = await instanceapi.get(`/rkap/variable?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const storeRkapVariable = createAsyncThunk('rkap-variable/storeRkapVariable', async(data) => {
    try {
        const response = await instanceapi.post('/rkap/variable/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getRkapVariableByStatusVariable = createAsyncThunk('rkap-variable/getRkapVariableByStatusVariable', async(status_bisnis) => {
    try {
        const response = await instanceapi.get(`/rkap/variable/get-by-status-bisnis/${status_bisnis}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getRkapVariableRkap = createAsyncThunk('rkap-variable/getRkapVariableRkap', async() => {
    try {
        const response = await instanceapi.get('/rkap/variable/get-rkap').catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getRkapVariableCompare = createAsyncThunk('rkap-variable/getRkapVariableCompare', async() => {
    try {
        const response = await instanceapi.get('rkap/variable/get-compare').catch((err) => {} );
        return response.data;
    } catch (error) {
        
    }
})

const rkap_variable_m = createSlice({
    name: 'rkap-variable',
    initialState
})

export default rkap_variable_m.reducer;