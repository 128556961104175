import React, { useEffect, useState } from 'react'
import SalesSpoil from '../SalesSpoil'
import { Autocomplete, Badge, Button, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getSearchMarketingByCodeName } from '../../../../models/marketing/marketing_m';
import { getDetailSalesMarketing, getSalesMarketingByCodeMarketing } from '../../../../models/sales/marketing/sales_marketing_m';

import { NumericFormat } from 'react-number-format';
import { CameraAlt, ShoppingBag } from '@mui/icons-material';
import SalesCashierChart from './SalesCashierChart';
import { Camera, CameraResultType } from '@capacitor/camera';
import { useParams } from 'react-router-dom';
import { b64toBlob, imageResizer } from '../../../../utils';
import { storeSalesMarketingSpoil } from '../../../../models/sales/marketing/sales_marketing_spoil_m';
import toast from 'react-hot-toast';
import Loading from '../../../loading/Loading';

const SalesSpoilCashier = () => {
    const dispatch = useDispatch();
    const { username } = useParams();

    const [marketing, setMarketing] = useState([]);
    const [sales, setSales] = useState([]);
    const [salesList, setSalesList] = useState([]);

    const [error, setError] = useState([]);

    const getMarketing = async(code_name_search) => {
        try {
            const response = await dispatch(getSearchMarketingByCodeName(code_name_search)).unwrap().catch((err) => {});
            setMarketing(response.data.map((i) => ({ code: i.code, label: i.name })))
        } catch (error) {
            
        }
    }

    const getSalesMarketing = async() => {
        try {
            const response = await dispatch(getDetailSalesMarketing(input.code_sales_marketing)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setSales(response.data['sales']);
                const list = response.data['sales_list']
                setSalesList(list.map((i) => ({...i, status_spoil: false, qty_spoil: 0})))
            }

            if (response.status === 404) {
                setError({ code: 'Nomor transaksi tidak ditemukan' })
                setTimeout(() => {
                    setError([]);
                }, 1500);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getMarketing('-');
    }, []);

    const handleSearchMarketing = (e) => {
        if (e.target.value !== '') {
            return getMarketing(e.target.value)
        }
    }


    const time_now = new Date().toLocaleString();
    const date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
    const date_code = `${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}`
    const time = `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
    const [input, setInput] = useState({
        code: `SPLPJ${date_code}`, date: date, time: time, code_marketing: '', marketing: '', 
        code_sales_marketing: '', status_filter: false, desc: ''
    })

    const handleChangeMarketing = (_, event) => {
        if (event) {
            setInput({...input, code_marketing: event.code, marketing: event.label})
        }
    }

    const onFilter = async(e) => {
        e.preventDefault();
        setInput({...input, status_filter: true})
        return getSalesMarketing();
    }

    const unlockFilter = () => {
        return window.location.reload();
    }

    const handleChangeQtySpoil = (e, code_product) => {
        const newLists = salesList.map(obj => {
            if (obj.code_product === code_product) {
                return {...obj, qty_spoil: e.target.value}
            }
            return obj
        })

        setSalesList(newLists);
    }

    const [listSpoil, setListSpoil] = useState([]);
    const onAddSpoil = (code_product) => {
        const newList = salesList.map(obj => {
            if (obj.code_product === code_product) {
                if (obj.qty_spoil > obj.qty || obj.qty_spoil === 0) {
                    alert('Jumlah spoil melebihi jumlah pembelian atau Nol')
                } else {
                    setListSpoil([...listSpoil, {...obj, sub_total: obj.qty_spoil * obj.unit_price}]);
                    return {...obj, status_spoil: true}
                }
            }
            return obj;
        })
        setSalesList(newList);
    }

    let sub_total = 0;
    let total_spoil_pembayaran = 0;
    let ppn_price = 0;
    if (listSpoil.length > 0) {
        sub_total = listSpoil.reduce((i, j) => i + j.sub_total, 0)
        if (sales.status_ppn === 'ppn') {
            ppn_price = (11 / 100) * sub_total
            total_spoil_pembayaran = sub_total + ppn_price
        } else {
            total_spoil_pembayaran = sub_total
            ppn_price = 0
        }
    }

    // modal
    const [open, setOpen] = useState(false);
    const handleChartSpoilList = () => {
        setOpen(true);
    }

    const onCloseModal = () => {
        setOpen(false)
    }

    const onCancelCart = (code_product, index) => {
        // delete array
        const newArray = Array.from(listSpoil);
        newArray.splice(index, 1);
        setListSpoil(newArray);

        // update status spoil product
        const newLists = salesList.map(obj => {
            if (obj.code_product === code_product) {
                return {...obj, status_spoil: false}
            }
            return obj;
        })
        setSalesList(newLists);
    }

    const onUpdateQty = (e, code_product) => {
        const newLists = listSpoil.map(obj => {
            if (obj.code_product === code_product) {
                const sub_total = e.target.value * obj.unit_price;
                return { ...obj, qty_spoil: e.target.value, sub_total: sub_total }
            }
            return obj;
        })

        setListSpoil(newLists);
    }

    const [picture, setPicture] = useState('');
    const [upload, setUpload] = useState('');
    const takePicture = async() => {
        try {
            const image = await Camera.getPhoto({
                quality: 30,
                allowEditing: true,
                resultType: CameraResultType.Base64
            })

            const imageUrl = image.base64String;
            setPicture('data:image/png;base64,' + imageUrl);
            setUpload(imageUrl)
        } catch (error) {
            
        }
    }

    const cancleSpoil = () => {
        return window.location.href = `/${username}/sales/spoil/data`
    }

    const [loading, setLoading] = useState(false);
    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);

        const blob = b64toBlob(upload, 'image/png');
        const file = new File([blob], 'image.png', {
            type: blob.type,
            lastModified: new Date().getTime()
        })
        const resize = await imageResizer(file, 480, 480);
        const data = {
            ...input,
            file: resize,
            nominal: total_spoil_pembayaran,
            status_ppn: sales.status_ppn,
            spoil_lists: {...listSpoil}
        }

        try {
            const response = await dispatch(storeSalesMarketingSpoil(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Spoil berhasil diajukan');
                setTimeout(() => {
                    return window.location.href = `/${username}/sales/spoil/validasi`
                }, 1200);
            }

            if (response.status === 400) {
                setLoading(false);
                setError(response.message);
                toast.error('Periksa kembali data Anda');
                setTimeout(() => {
                    setError([]);
                }, 1500);
            }
        } catch (error) {
            
        }
    }

    return (
        <SalesSpoil>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={onFilter}>
                                    <div className="card-in mb-2">
                                        <div className="row">
                                            <div className="col-md-4 mb-1">
                                                <label className="form-label form-label-m-0">Kode Spoil</label>
                                                <TextField 
                                                    size="small"
                                                    fullWidth
                                                    value={input.code}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-md-4 mb-1">
                                                <label className="form-label form-label-m-0">Tanggal</label>
                                                <TextField 
                                                    size="small"
                                                    fullWidth
                                                    value={time_now}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-md-4 mb-1">
                                                <label className="form-label form-label-m-0">Marketing</label>
                                                <Autocomplete 
                                                    disableClearable
                                                    size="small"
                                                    fullWidth
                                                    options={marketing}
                                                    renderInput={(params) => <TextField {...params} placeholder="Marketing" onChange={handleSearchMarketing} required />}
                                                    onChange={handleChangeMarketing}
                                                    disabled={ input.status_filter ? true : false }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-in mb-2">
                                        <div className="row align-items-end">
                                            <div className="col-md-9 mb-1">
                                                <label className="form-label form-label-m-0">No Transaksi &nbsp; &nbsp; <small className="text-danger">{ error.code ? error.code : '' }</small></label>
                                                <TextField 
                                                    error={ error.code ? true : false }
                                                    size="small"
                                                    fullWidth
                                                    placeholder="Masukkan Nomor Transaksi"
                                                    required
                                                    onChange={(e) => setInput({...input, code_sales_marketing: e.target.value})}
                                                    disabled={ input.status_filter ? true : false }
                                                />
                                            </div>

                                            <div className="col-md-3 mb-1">
                                                {
                                                    input.status_filter ? 
                                                    <Button onClick={unlockFilter} fullWidth sx={{padding: '8.5px 14px'}} color="error" variant="contained">
                                                        Unlock
                                                    </Button> : 
                                                    <Button type="submit" fullWidth sx={{padding: '8.5px 14px'}} color="primary" variant="contained">
                                                        Cari Transaksi
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Kode Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Produk</span>
                                                </th>
                                                <th className="text-center" style={{width: '10%'}}>
                                                    <span className="form-control">Jumlah Beli</span>
                                                </th>
                                                <th style={{width: '10%'}} className="text-center">
                                                    <span className="form-control">Jumlah Spoil</span>
                                                </th>
                                                <th className="table-border-end text-center" style={{width: '10%'}}>
                                                    <span className="form-control">Action</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                error.code ? 
                                                <tr>
                                                    <th colSpan={6} className="text-center">Data Tidak Ditemukan</th>
                                                </tr> :
                                                salesList.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{ data.code_product }</td>
                                                        <td>{ data.product }</td>
                                                        <td className="text-center">{data.qty}</td>
                                                        <td>
                                                            <TextField 
                                                                size="small"
                                                                fullWidth
                                                                placeholder="Jml Spoil"
                                                                type="number"
                                                                onInput={(e) => {
                                                                    const target = e.target;
                                                                    target.value = e.target.value.replace(/[^0-9]/g, "");
                                                                }}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 1,
                                                                    },
                                                                }}
                                                                onChange={(e) => handleChangeQtySpoil(e, data.code_product)}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <Button onClick={() => onAddSpoil(data.code_product)} disabled={ data.status_spoil ? true : false } size="small" variant="contained" color="success">
                                                                Spoil
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <form onSubmit={onSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-price mb-2">
                                        <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                            <span>
                                                Total Transaksi Spoil
                                            </span>
                                            <span>
                                                { input.code }
                                            </span>
                                        </div>

                                        <div className="text-center price-total">
                                            <NumericFormat 
                                                value={ total_spoil_pembayaran } 
                                                allowLeadingZeros 
                                                thousandSeparator="," 
                                                valueIsNumericString
                                                prefix="Rp. "
                                                displayType="text"
                                                renderText={(value) => <span>{value}</span>}
                                            />
                                        </div>
                                    </div>

                                    <div className="card-in mb-2">
                                        <div onClick={handleChartSpoilList} style={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-center">
                                            <div>
                                                Lihat Keranjang Spoil
                                            </div>
                                            <div>
                                                <Badge badgeContent={listSpoil.length} color="error">
                                                    <ShoppingBag />
                                                </Badge>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-in mb-2">
                                        <div style={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-center">
                                            <div>
                                                PPN
                                            </div>
                                            <div className="text-orange">
                                                <NumericFormat 
                                                    value={ ppn_price } 
                                                    allowLeadingZeros 
                                                    thousandSeparator="," 
                                                    valueIsNumericString
                                                    prefix="Rp. "
                                                    displayType="text"
                                                    renderText={(value) => <span>{value}</span>}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Keterangan Spoil</label>
                                        <textarea style={{height: '100px'}} onChange={(e) => setInput({...input, desc: e.target.value})} placeholder="Tulis Keterangan" required className="form-control"></textarea>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Bukti Spoil</label>
                                        <span onClick={takePicture} className="form-control bg-pink" style={{cursor: 'pointer'}}>
                                            <CameraAlt /> Ambil Foto
                                        </span>
                                        {
                                            picture ? 
                                            <img src={picture} className="mt-2" style={{width: '100%', height: '160px', borderRadius: '4px'}} /> : ''
                                        }
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <Button onClick={cancleSpoil} fullWidth variant="contained" color="error">
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Button type="submit" disabled={ listSpoil.length === 0 ? true : false } fullWidth variant="contained" color="primary">
                                                Ajukan
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {
                open ? 
                <SalesCashierChart 
                    open={open}
                    onClose={onCloseModal}
                    listSpoil={listSpoil}
                    onCancelCart={onCancelCart}
                    onUpdateQty={onUpdateQty}
                /> : ''
            }
        </SalesSpoil>
    )
}

export default SalesSpoilCashier
