import React, { useEffect, useState } from 'react'
import SalesRetur from '../SalesRetur'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getSalesMarketingRetur, getSalesMarketingReturFilterData } from '../../../../models/sales/marketing/sales_marketing_retur_m';

import Paginate from '../../../pagination/Paginate';
import Moment from 'react-moment';

import { NumericFormat } from 'react-number-format';
import { Button, MenuItem, Select, TextField } from '@mui/material';

import { listYearNext, listYearPrev } from '../../data/date/year';
import months from '../../data/date/month';
import { CloudDownload, CloudUpload } from '@mui/icons-material';

const SalesReturData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [retur, setRetur] = useState([]);
    const [pagination, setPagination] = useState([]);

    const date = new Date();
    const year = date.getFullYear();
    const monthNow = date.getMonth();

    const getData = async(page) => {
        try {
            const response = await dispatch(getSalesMarketingRetur(page)).unwrap().catch((err) => {});
            setRetur(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    const getFilter = async(page) => {
        try {
            if (filter.year === 'all') {
                filter.year = year;
            }
            const data = {
                ...filter,
                page: page
            }
            const response = await dispatch(getSalesMarketingReturFilterData(data)).unwrap().catch((err) => {});
            setRetur(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);

    const handlePagination = (_, page) => {
        if (filter.status === true) {
            return getFilter(page)
        } else {
            return getData(page)
        }
    }

    const onDetail = (code) => {
        return navigate(`${code}`)
    }

    const [filter, setFilter] = useState({
        status: false, year: year, month: monthNow + 1, name: ' '
    })

    const handleChangeYear = (e) => {
        if (e.target.value === 'all') {
            setFilter({...filter, year: 'all', month: 'all'})
        } else {
            setFilter({...filter, year: e.target.value, month: 1})
        }
    }

    const onFilter = async(e) => {
        e.preventDefault();
        setFilter({...filter, status: true});
        return getFilter(1);
    }

    const unlockFilter = () => {
        return window.location.reload();
    }

    return (
        <SalesRetur>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DATA RETUR PENJUALAN</h3>
                                <span>List Data Retur Penjualan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-start mb-3">
                            <div></div>
                            <div>
                                <div style={{lineHeight: '60%'}} className="text-center">
                                    <h2>
                                        DATA RETUR PENJUALAN PERUMDA ANEKA USAHA <br /> 
                                        KABUPATEN JEPARA <br />
                                        Tahun { filter.year }
                                    </h2>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="text-center me-2" style={{ cursor: 'pointer' }}>
                                    <span className="btn btn-icon btn-success" style={{ padding: '.4rem .4rem' }}>
                                        <CloudDownload sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </span>
                                    <span style={{ fontSize: '12px' }} className="d-block">Import</span>
                                </div>

                                <div className="text-center me-2" style={{ cursor: 'pointer' }}>
                                    <span className="btn btn-icon btn-pink" style={{ padding: '.4rem .4rem' }}>
                                        <CloudUpload sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </span>
                                    <span style={{ fontSize: '12px' }} className="d-block">Ekspor</span>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={onFilter}>
                            <div className="row align-items-end">
                                <div className="col-md-3 mb-3">
                                    <label className="form-label form-label-m-0">Tahun</label>
                                    <Select
                                        size="small"
                                        fullWidth
                                        placeholder="Tahun"
                                        displayEmpty
                                        value={filter.year}
                                        onChange={handleChangeYear}
                                        required
                                        disabled={ filter.status ? true : false }
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {
                                            listYearPrev.map((year, index) => (
                                                <MenuItem key={index} value={year}>{ year }</MenuItem>
                                            ))
                                        }
                                        {
                                            listYearNext.map((year, index) => (
                                                <MenuItem key={index} value={year}>{year}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="form-label form-label-m-0">Bulan</label>
                                    <Select
                                        size="small"
                                        fullWidth
                                        displayEmpty
                                        value={filter.month}
                                        onChange={(e) => setFilter({...filter, month: e.target.value})}
                                        disabled={ filter.year === 'all' || filter.status === true ? true : false }
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {
                                            months.map((month, index) => (
                                                <MenuItem key={index} value={month.id} >{ month.label }</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </div>

                                <div className="col-md-5 mb-3">
                                    <label className="form-label form-label-m-0">Cari Marketing</label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Cari Nama Marketing"
                                        onChange={(e) => setFilter({...filter, name: e.target.value})}
                                        disabled={ filter.status ? true : false }
                                    />
                                </div>

                                <div className="col-md-1 mb-3">
                                    {
                                        filter.status ? 
                                        <Button onClick={unlockFilter} sx={{padding: '8.5px 14px'}} variant="contained" fullWidth color="error">
                                            Unlock
                                        </Button> : 
                                        <Button type="submit" sx={{padding: '8.5px 14px'}} variant="contained" fullWidth color="primary">
                                            Filter
                                        </Button>
                                    }
                                </div>
                            </div>
                        </form>

                        <div className="table-responsive mb-3">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Tanggal</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Marketing</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nominal</span>
                                        </th>
                                        <th className="text-center">
                                            <span className="form-control">Status Retur</span>
                                        </th>
                                        <th className="table-border-end text-center" style={{width: '10%'}}>
                                            <span className="form-control">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        retur.map((data) => (
                                            <tr key={data.no}>
                                                <td className="text-center">{data.no}</td>
                                                <td>
                                                    {data.code}
                                                </td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY" >{data.date}</Moment>
                                                </td>
                                                <td>
                                                    { data.marketing }
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.nominal } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        data.status_retur === 'process' ? 
                                                        <span className="badge bg-orange">Proses</span> : 
                                                        data.status_retur === 'success' ? 
                                                        <span className="badge bg-success">Tervalidasi</span> : 
                                                        <span className="badge bg-danger">Di Tolak</span>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    <Button onClick={() => onDetail(data.code)} variant="contained" size="small" color="success">
                                                        Detail
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        {/* pagination */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Halaman : { pagination.current_page }</span>
                            </div>
                            {
                                pagination.pagination ? 
                                <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} /> : ''
                            }
                            <div>
                                <span>Total : { pagination.total } Data</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SalesRetur>
    )
}

export default SalesReturData
