import React, { useEffect, useState } from 'react'
import Delivery from '../Delivery'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDetailSalesMarketing } from '../../../models/sales/marketing/sales_marketing_m';
import toast from 'react-hot-toast';
import { ReplyAll } from '@mui/icons-material';
import Moment from 'react-moment';
import { getDetailDelivery } from '../../../models/delivery/delivery_m';
import { getDetailMarketing } from '../../../models/marketing/marketing_m';

const DeliveryDiterima = () => {
    const { username, code, code_sales_marketing } = useParams();
    const dispatch = useDispatch();

    const handleBack = () => {
        return window.location.href = `/${username}/delivery/data/${code}`
    }

    const [loading, setLoading] = useState(false);
    const[delivery, setDelivery] = useState([]);
    const [sales, setSales] = useState([]);
    const [listProduct, setListProduct] = useState([]);
    const [marketing, setMarketing] = useState([]);

    const getMarketing = async(code) => {
        try {
            const response = await dispatch(getDetailMarketing(code)).unwrap().catch((err) => {});
            setMarketing(response.data)
        } catch (error) {
            
        }
    }

    const getDataDetail = async() => {
        try {
            const response = await dispatch(getDetailDelivery(code)).unwrap().catch((err) => {})
            setDelivery(response.delivery)
        } catch (error) {
            
        }
    }

    const geDataDetailSalesMarketing = async() => {
        try {
            const response = await dispatch(getDetailSalesMarketing(code_sales_marketing)).unwrap().catch((err) => {});
            setSales(response.data['sales']);
            setListProduct(response.data['sales_list'])
            getMarketing(response.data['sales']['code_marketing'])
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataDetail();
        geDataDetailSalesMarketing()
    }, []);

    return (
        <Delivery>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>{ code }</h3>
                                <span>Detail Pengiriman</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            <table className="table-sm table-borderless">
                                <tbody>
                                    <tr>
                                        <th>Kode Pengiriman</th>
                                        <td style={{width: "25%"}}>: { code }</td>

                                        <th>Marketing</th>
                                        <td style={{width: "25%"}}>: { marketing.name }</td>

                                        <th>Penerima</th>
                                        <td>: { delivery.penerima }</td>
                                    </tr>
                                    <tr>
                                        <th>Kode Transaksi</th>
                                        <td>: { sales.code }</td>

                                        <th>Alamat</th>
                                        <td>: { marketing.address }</td>

                                        <th>Nomor Handphone Penerima</th>
                                        <td>: { delivery.phone_penerima }</td>
                                    </tr>
                                    <tr>
                                        <th>Tanggal Pengiriman</th>
                                        <td>
                                            : <Moment format="dddd, DD MMMM YYYY">{delivery.tgl_pengiriman}</Moment>
                                        </td>

                                        <th>Nomor Handphone</th>
                                        <td>: { marketing.contact_primary }</td>

                                        <th>Foto Penerima</th>
                                        <td>
                                            : <a href={delivery.photo} target="_blank">Lihat Foto</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Petugas Pengiriman</th>
                                        <td>: { delivery.employee }</td>

                                        <th>Status Penerimaan</th>
                                        <td>
                                            : <span className="badge bg-green">{ sales.status_pengiriman}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode Produk</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nama Produk</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kategori</span>
                                        </th>
                                        <th className="table-border-end text-center">
                                            <span className="form-control">Jumlah Produk</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listProduct.map((data, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>{data.code_product}</td>
                                                <td>{data.product}</td>
                                                <td>{data.category}</td>
                                                <td className="text-center">{data.qty}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Delivery>
    )
}

export default DeliveryDiterima
