import React from 'react'
import RkapRealisasiData from '../../views/rkap/realisasi/data/RkapRealisasiData'
import RkapComparasi from '../../views/rkap/realisasi/comparasi/RkapComparasi'
import RkapComparasiDetail from '../../views/rkap/realisasi/comparasi/RkapComparasiDetail'

function RkapRealisasiController({ view }) {
    if (view === 'rkap') {
        return(
            <RkapRealisasiData />
        )
    } else if (view === 'compare') {
        return (
            <RkapComparasi />
        )
    } else if (view === 'compare-detail') {
        return (
            <RkapComparasiDetail />
        )
    }
}

export default RkapRealisasiController
