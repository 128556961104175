import React, { useEffect, useState } from 'react'
import Warehouse from '../Warehouse'
import { ThumbUpAlt } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { getSalesMarketingByStatusGudang } from '../../../models/sales/marketing/sales_marketing_m'
import Moment from 'react-moment'
import { Button } from '@mui/material'
import { useParams } from 'react-router-dom'

import Paginate from '../../pagination/Paginate'

const RequestSale = () => {
    const dispatch = useDispatch();
    const { username } = useParams();

    const hadleDetail = (code) => {
        return window.location.href = `/${username}/warehouse/request/sale/${code}`
    }

    const [sales, setSales] = useState([]);
    const [pagination, setPagination] = useState([]);

    const getData = async(page) => {
        const data = {
            status_gudang: 'process',
            page: page
        }
        try {
            const response = await dispatch(getSalesMarketingByStatusGudang(data)).unwrap().catch((err) => {});
            setSales(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);

    const handlePagination = (_, page) => {
        return getData(page);
    }

    return (
        <Warehouse>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>PERMINTAAN PENJUALAN</h3>
                                <span>List Permintaan Penjualan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table align-middle mb-3">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode Penjualan</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Tanggal Transaksi</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Marketing</span>
                                        </th>
                                        <th className="table-border-end text-center" style={{width: '15%'}}>
                                            <span className="form-control">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sales.map((data) => (
                                            <tr key={data.no}>
                                                <td className="text-center">
                                                    {data.no}
                                                </td>
                                                <td>
                                                    { data.code }
                                                </td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{data.tgl_transaksi}</Moment>
                                                </td>
                                                <td>
                                                    { data.marketing }
                                                </td>
                                                <td className="text-center">
                                                    <Button onClick={() => hadleDetail(data.code)} size="small" variant="contained" color="primary">
                                                        validasi
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            {/* pagination */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <span>Halaman : { pagination.current_page ? pagination.current_page : '' }</span>
                                </div>
                                {
                                    pagination.pagination ? 
                                    <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} /> : ''
                                }
                                <div>
                                    <span>Total : { pagination.total ? pagination.total : '' } Permintaan</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Warehouse>
    )
}

export default RequestSale
