import React, { useEffect, useState } from 'react'
import Warehouse from '../Warehouse'
import { ReplyAll } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDetailSalesMarketing } from '../../../models/sales/marketing/sales_marketing_m'
import toast from 'react-hot-toast'
import Loading from '../../loading/Loading'
import Moment from 'react-moment'
import { Autocomplete, Button, TextField } from '@mui/material'
import { storeSalesRequest } from '../../../models/sales/marketing/sales_request_m'
import { getEmployeeByNameJabatan } from '../../../models/employee/employee_m'

const RequestSaleDetail = () => {
    const dispatch = useDispatch();
    const { username, code } = useParams();
    const url_back = `/${username}/warehouse/request/sale`

    const [sales, setSales] = useState([]);
    const [lists, setLists] = useState([]);

    const [employee, setEmployee] = useState([]);

    const [loading, setLoading] = useState(false);

    const date = new Date();
    const code_request = `SR${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`
    const date_acc = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

    const [input, setInput] = useState({
        code: code_request, code_sales_marketing: code, code_employee: '', date: date_acc, time: time,
        employee: '', status: '', desc: ''
    })

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailSalesMarketing(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setSales(response.data['sales'])
                setLists(response.data['sales_list'])
            } else {
                setLoading(true)
                toast.error('Data Penjualan yang Anda Cari Tidak Ditemukan')
                setTimeout(() => {
                   return window.location.href = `/${username}/warehouse/request/sale` 
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const getEmployee = async(data) => {
        try {
            const response = await dispatch(getEmployeeByNameJabatan(data)).unwrap().catch((err) => {});
            setEmployee(response.data.map((data) => ({code: data.code, label: data.name})))
        } catch (error) {
            
        }
    }

    const onBack = () => {
        return window.location.href = url_back
    }

    useEffect(() => {
        getDetail();
        getEmployee({ name: ' ', jabatan: 'Staf Sub Pergudangan' })
    }, []);

    const onSubmit = async() => {
        setLoading(true)
        const data = {
            ...input,
            list: {...lists},
            status: 'acc'
        }

        if (input.code_employee === '' || input.desc === '') {
            alert('Silahkan untuk mengisi pegawai dan keterangan')
            setLoading(false)
        } else {
            try {
                const response = await dispatch(storeSalesRequest(data)).unwrap().catch((err) => {});
                if (response.status === 201) {
                    toast.success('Permintaan Penjualan Berhasil Dikonfirmasi');
                    setTimeout(() => {
                        return window.location.href = url_back;
                    }, 1200);
                }
            } catch (error) {
                
            }
        }
    }

    const onCancel = async() => {
        setLoading(true)
        const data = {
            ...input,
            list: {...lists},
            status: 'reject'
        }

        if (input.code_employee === '' || input.desc === '') {
            alert('Silahkan untuk mengisi pegawai dan keterangan')
            setLoading(false)
        } else {
            try {
                const response = await dispatch(storeSalesRequest(data)).unwrap().catch((err) => {});
                if (response.status === 201) {
                    toast.success('Permintaan Penjualan Berhasil Dikonfirmasi');
                    setTimeout(() => {
                        return window.location.href = url_back;
                    }, 1200);
                }
            } catch (error) {
                
            }
        }
    }

    return (
        <Warehouse>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>{ sales.code }</h3>
                                <span>Detail Permintaan Penjualan</span>
                            </div>

                            <div>
                                <span onClick={onBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    <table className="table table-borderless table-sm">
                                        <tbody>
                                            <tr>
                                                <th>Kode Transaksi</th>
                                                <td>: { sales.code }</td>

                                                <th>Marketing</th>
                                                <td>: { sales.marketing }</td>
                                            </tr>
                                            <tr>
                                                <th>Tanggal Transaksi</th>
                                                <td>
                                                    : <Moment format="dddd, DD MMMM YYYY">{ sales.tgl_transaksi }</Moment>
                                                </td>

                                                <th>Validasi</th>
                                                <td>
                                                    : {
                                                        sales.status_gudang === 'process' ? 
                                                        <span className="text-orange">Belum tervalidasi</span> : 
                                                        <span className="text-green">Tervalidasi</span>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Petugas</th>
                                                <td>: { sales.employee }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                                <div className="table-responsive mb-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Kode Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Nama Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Kategori</span>
                                                </th>
                                                <th className="table-border-end text-center">
                                                    <span className="form-control">Jumlah</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                lists.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{data.code_product}</td>
                                                        <td>{data.product}</td>
                                                        <td>{data.category}</td>
                                                        <td className="text-center">{data.qty}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>  
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Petugas</label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={employee}
                                        value={input.employee}
                                        isOptionEqualToValue={(options, value) => options.label === value}
                                        renderInput={(params) => <TextField {...params} placeholder="Petugas" required />}
                                        onChange={(_, event) => setInput({...input, code_employee: event.code, employee: event.label})}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">Keterangan</label>
                                    <textarea placeholder="Tulis Keterangan" onChange={(e) => setInput({...input, desc: e.target.value}) } required style={{height: '100px'}} className="form-control"></textarea>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <Button onClick={onCancel} fullWidth variant="contained" color="error">
                                            Tolak
                                        </Button>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Button onClick={onSubmit} fullWidth variant="contained" color="primary">
                                            Validasi
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Warehouse>
    )
}

export default RequestSaleDetail
