import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../instanceapi";

const initialState = {

}

export const getOperasionalNonBisnis = createAsyncThunk('operasional-non-bisnis/getOperasionalNonBisnis', async(page) => {
    try {
        const response = await instanceapi.get(`/operasional/non-bisnis?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const storeOperasionalNonBisnis = createAsyncThunk('operasional-non-bisnis/storeOperasionalNonBisnis', async(data) => {
    try {
        const response = await instanceapi.post('/operasional/non-bisnis/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const operasional_non_bisnis_m = createSlice({
    name: 'operasional-non-bisnis',
    initialState
})

export default operasional_non_bisnis_m.reducer;