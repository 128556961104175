import { AttachMoney, BarChart, Description, GridView, Handshake, LocalGroceryStore, LocalShipping, MilitaryTech, Payment, People, Speed, StackedLineChart, Warehouse } from "@mui/icons-material";

const _sidebar = [
    {
        name: 'Dashboard',
        icon: GridView,
        to: 'dashboard',
        active: 'dashboard'
    },
    {
        name: 'Karyawan',
        icon: People,
        to: 'employee/form',
        active: 'employee'
    },
    {
        name: 'Mitra',
        icon: Handshake,
        to: 'mitra/supplier/form',
        active: 'mitra'
    },
    {
        name: 'Produk',
        icon: LocalGroceryStore,
        to: 'product/form',
        active: 'product'
    },
    {
        name: 'Pergudangan',
        icon: Warehouse,
        to: 'warehouse/purchase/data',
        active: 'warehouse'
    },
    {
        name: 'Penjualan',
        icon: AttachMoney,
        to: 'sales/marketing/data',
        active: 'sales'
    },
    {
        name: 'Pengiriman',
        icon: LocalShipping,
        to: 'delivery/data',
        active: 'delivery'
    },
    {
        name: 'Pendapatan Lain',
        icon: StackedLineChart,
        to: 'other-income/data',
        active: 'other-income'
    },
    {
        name: 'Biaya',
        icon: BarChart,
        to: 'operasional/bisnis/data',
        active: 'operasional'
    },
    {
        name: 'Laporan Keuangan',
        icon: Payment,
        to: 'financial/data',
        active: 'financial'
    },
    {
        name: 'RKAP',
        icon: Description,
        to: 'rkap/variable/data',
        active: 'rkap'
    },
    // {
    //     name: 'KPI',
    //     icon: Speed,
    //     to: '/kpi',
    // },
    // {
    //     name: 'Jaminan Mutu',
    //     icon: MilitaryTech,
    //     to: '/jaminan-mutu'
    // }
]

export default _sidebar;