import { Autocomplete, Button, Drawer, TextField } from '@mui/material'
import React from 'react'

import { NumericFormat } from 'react-number-format';

const status_harga = [
    { code: 'harga_1', label: 'Harga 1' },
    { code: 'harga_2', label: 'Harga 2' },
    { code: 'harga_3', label: 'Harga 3' },
    { code: 'harga_4', label: 'Harga 4' },
]

const SalesListProduct = ({ open, onClose, list, updateStatusHarga, updateQty, onDeleteData }) => {
    return (
        <Drawer
            anchor="top"
            open={ open }
            onClose={onClose}
        >
            <div className="py-3 px-5">
                <div className="table-responsive">
                    <table className="table align-middle">
                        <thead>
                            <tr>
                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                    <span className="form-control">No</span>
                                </th>
                                <th>
                                    <span className="form-control">Kode Produk</span>
                                </th>
                                <th>
                                    <span className="form-control">Nama Produk</span>
                                </th>
                                <th>
                                    <span className="form-control">Harga Satuan</span>
                                </th>
                                <th className="text-center" style={{width: '10%'}}>
                                    <span className="form-control">Jumlah</span>
                                </th>
                                <th className="text-center" style={{width: '10%'}}>
                                    <span className="form-control">Status Harga</span>
                                </th>
                                <th>
                                    <span className="form-control">Sub Total</span>
                                </th>
                                <th className="table-border-end text-center" style={{width: '10%'}}>
                                    <span className="form-control">Action</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                list.length > 0 ? 
                                list.map((data, index) => (
                                    <tr key={index}>
                                        <td className="text-center">{ index + 1 }</td>
                                        <td>{ data.code }</td>
                                        <td>{ data.name }</td>
                                        <td>
                                            <NumericFormat 
                                                value={ data.unit_price } 
                                                allowLeadingZeros 
                                                thousandSeparator="," 
                                                valueIsNumericString
                                                prefix="Rp. "
                                                displayType="text"
                                                renderText={(value) => <span>{value}</span>}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                type="number"
                                                placeholder="QTY"
                                                value={data.qty}
                                                onChange={(e) => updateQty(e, data.code)}
                                            />
                                        </td>
                                        <td>
                                            <Autocomplete 
                                                size="small"
                                                fullWidth
                                                disableClearable
                                                options={status_harga}
                                                renderInput={(params) => <TextField {...params} placeholder="Status Harga" required />}
                                                onChange={(_, event) => updateStatusHarga(event.code, data.code)}
                                            />
                                        </td>
                                        <td>
                                            <NumericFormat 
                                                value={ data.sub_total } 
                                                allowLeadingZeros 
                                                thousandSeparator="," 
                                                valueIsNumericString
                                                prefix="Rp. "
                                                displayType="text"
                                                renderText={(value) => <span>{value}</span>}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <Button onClick={() => onDeleteData(index)} size="small" variant="contained" color="error">
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                )) : 
                                <tr>
                                    <th colSpan={8} className="text-center">KERANJANG PENJUALAN MASIH KOSONG</th>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </Drawer>
    )
}

export default SalesListProduct
