import React, { useEffect, useState } from 'react'
import MitraMarketing from '../MitraMarketing'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDetailMarketing, updateDataMarketing, updateImageMarketing } from '../../../../models/marketing/marketing_m';
import { Camera, CameraResultType } from '@capacitor/camera';
import { b64toBlob, imageResizer } from '../../../../utils';
import toast from 'react-hot-toast';
import { Button, TextField } from '@mui/material';
import Mitra from '../../Mitra';
import Loading from '../../../loading/Loading';
import { ReplyAll } from '@mui/icons-material';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const MitraMarketingDetail = () => {
    const dispatch = useDispatch();
    const { username, code } = useParams();
    const [marketing, setMarketing] = useState([]);
    const [loading, setLoading] = useState(false);

    const [picture, setPicture] = useState('');

    const [input, setInput] = useState({
        code: code,  name: '', no_identity: '', address: '', email: '',
        website: '', contact_primary: '', contact_name: '', contact_secondary: '',
        desc: '', docs: ''
    })
    const [error, setError] = useState([])

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailMarketing(code)).unwrap().catch((err) => {});
            if (response.status === 404) {
                setLoading(true);
                toast.error('Data Tidak Ditemukan')
                setTimeout(() => {
                   return window.location.href = `/${username}/mitra/marketing/data` 
                }, 1000);
            }

            if (response.status === 200) {
                const data = response.data;
                setMarketing(data);
                setInput({
                    ...input, name: data.name, no_identity: data.no_identity, address: data.address, email: `${data.email ? data.email : ''}`,
                    website: `${data.website ? data.website : ''}`, contact_primary: data.contact_primary, contact_name: data.contact_name, 
                    contact_secondary: data.contact_secondary, desc: data.desc
                })
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail()
    }, [])

    const handleBack = () => {
        return window.location.href = `/${username}/mitra/marketing/data`
    }

    const takePhoto = async() => {
        try {
            const image = await Camera.getPhoto({
                quality: 30,
                allowEditing: true,
                resultType: CameraResultType.Base64
            })

            const imageUrl = image.base64String;
            setPicture('data:image/png;base64,' + imageUrl);

            const blob = b64toBlob(imageUrl, 'image/png');
            const file = new File([blob], 'image.png', {
                type: blob.type,
                lastModified: new Date().getTime()
            })

            const resize = await imageResizer(file, 240, 240);

            const data = {
                code: code,
                image: resize
            }

            const response = await dispatch(updateImageMarketing(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Gambar berhasil diperbaharui');
                setTimeout(() => {
                   return window.location.reload(); 
                }, 1000);
            }

        } catch (error) {
            
        }
    }

    const onUpdate = async(e) => {
        e.preventDefault();
        setLoading(true);
        const data = {...input};

        try {
            const response = await dispatch(updateDataMarketing(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Data berhasil diupdate');
                setTimeout(() => {
                    return window.location.reload();
                }, 1200);
            }

            if (response.status === 400) {
                setLoading(false)
                setError(response.message);
                toast.error('Periksa kembali data Anda');
                setTimeout(() => {
                    setError([]);
                }, 1500);
            }
        } catch (error) {
            
        }
    }

    return (
        <Mitra>
            { loading ? <Loading />  : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>{ marketing.name ? marketing.name : '' }</h3>
                                <span>Detail Data Marketing</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onUpdate}>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <a href={ marketing.image } target="_blank">
                                            <img src={ picture ? picture : marketing.image } alt="" className="photo-profile" />
                                        </a>
                                        <div className="mb-3">
                                            <span onClick={takePhoto} className="btn-update-foto">
                                                Update Foto
                                            </span>
                                        </div>
                                        <div className="mb-3">
                                            <span style={{fontSize: '16px', fontWeight: 'bold'}} >{ marketing.name ? marketing.name : '' }</span> <br />
                                            <span>( { marketing.status === 'company' ? 'Perusahaan' : marketing.status === 'employee' ? 'Karyawan' : 'Perorangan' } ) </span>
                                        </div>
                                        <div className="mb-3">
                                            <a href={ marketing.docs } target="_blank">
                                                Lihat Dokumen
                                            </a>
                                        </div>
                                        <div>
                                            <a href={`https://wa.me/${marketing.contact_primary}`} target='"_blank' className="badge bg-success">
                                                { marketing.contact_primary }
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">
                                            { marketing.status === 'employee' ? 'NIP' : marketing.status === 'company' ? 'NIB' : 'NIK' }
                                        </label>
                                        <TextField 
                                            error={ error.no_identity ? true : false }
                                            size="small"
                                            fullWidth
                                            value={input.no_identity}
                                            onChange={(e) => setInput({...input, no_identity: e.target.value})}
                                            required
                                        />
                                        <small className="text-danger">{ error.no_identity ? error.no_identity : '' }</small>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">
                                            { marketing.status === 'employee' ? 'Nama Karyawan' : marketing.status === 'company' ? 'Nama Perusahaan' : 'Nama Marketing' }
                                        </label>
                                        <TextField 
                                            disabled={ marketing.status === 'employee' ? true : false }
                                            size="small"
                                            fullWidth
                                            value={input.name}
                                            onChange={(e) => setInput({...input, name: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Alamat</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Alamat"
                                            value={input.address}
                                            onChange={(e) => setInput({...input, address: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">
                                            { marketing.status === 'employee' ? 'Kontak Umum' : marketing.status === 'company' ? 'Kontak Perusahaan' : 'Kontak Umum' }
                                        </label>
                                        <PhoneInput
                                            country={'id'}
                                            value={input.contact_primary}
                                            onChange={phone => setInput({...input, contact_primary: phone})}
                                            inputProps={{
                                                required: true,
                                                autoFocus: true
                                            }}
                                            placeholder='+62 858 *** *** ***'
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Email</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            type="email"
                                            placeholder="Tulis Email"
                                            value={input.email}
                                            onChange={(e) => setInput({...input, email: e.target.value})}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Website</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Website"
                                            value={input.website}
                                            onChange={(e) => setInput({...input, website: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">
                                            { marketing.status === 'employee' ? 'Nama Kontak Darurat' : marketing.status === 'company' ? 'Nama PIC' : 'Nama Kontak Darurat' }
                                        </label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            value={input.contact_name}
                                            onChange={(e) => setInput({...input, contact_name: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">
                                            { marketing.status === 'employee' ? 'Kontak Darurat' : marketing.status === 'company' ? 'Kontak PIC' : 'Kontak Darurat' }
                                        </label>
                                        <PhoneInput
                                            country={'id'}
                                            value={input.contact_secondary}
                                            onChange={phone => setInput({...input, contact_secondary: phone})}
                                            inputProps={{
                                                required: true,
                                                autoFocus: true
                                            }}
                                            placeholder='+62 858 *** *** ***'
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Upload Dokumen</label>
                                        <input type="file" className="form-control" onChange={(e) => setInput({...input, docs: e.target.files[0]})} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Keterangan</label>
                                        <textarea placeholder="Keterangan" onChange={(e) => setInput({...input, desc: e.target.value})} value={input.desc} className="form-control"></textarea>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button variant="contained" color="success" type="submit">
                                            Update Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Mitra>
    )
}

export default MitraMarketingDetail
