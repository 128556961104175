import DeliveryController from "../controllers/delivery/DeliveryController";
import DeliveryScheduleController from "../controllers/delivery/DeliveryScheduleController";
import EmployeeController from "../controllers/employee/EmployeeController";
import FinancialController from "../controllers/financial/FinancialController";
import HomeController from "../controllers/home/HomeController";
import MitraController from "../controllers/mitra/MitraController";
import MitraResellerController from "../controllers/mitra/MitraResellerController";
import OperasionalController from "../controllers/operasional/OperasionalController";
import OtherIncomeController from "../controllers/other_income/OtherIncomeController";
import MasterProductController from "../controllers/product/MasterProductController";
import ProductController from "../controllers/product/ProductController";
import RkapController from "../controllers/rkap/RkapController";
import RkapRealisasiController from "../controllers/rkap/RkapRealisasiController";
import RkapVariableController from "../controllers/rkap/RkapVariableController";
import SalesController from "../controllers/sales/SalesController";
import SalesMarketingController from "../controllers/sales/SalesMarketingController";
import SalesReturController from "../controllers/sales/SalesReturController";
import SalesSpoilController from "../controllers/sales/SalesSpoilController";
import ScreenSelectRoleController from "../controllers/screen/ScreenSelectRoleController";
import ScreenWelcomeController from "../controllers/screen/ScreenWelcomeController";
import StoreController from "../controllers/store/StoreController";
import WarehouseController from "../controllers/warehouse/WarehouseController";
import WarehousePenerimaanController from "../controllers/warehouse/WarehousePenerimaanController";
import WarehouseRequestController from "../controllers/warehouse/WarehouseRequestController";
import WarehouseReturController from "../controllers/warehouse/WarehouseReturController";
import WarehouseSpoilController from "../controllers/warehouse/WarehouseSpoilController";

const routes = [
    // screen
    {
        path: 'select-role',
        element: ScreenSelectRoleController,
    },
    {
        path: 'welcome-screen',
        element: ScreenWelcomeController,
    },

    {
        path: 'dashboard',
        element: HomeController,
    },

    // employee
    {
        path: 'employee/form',
        element: EmployeeController,
        view: 'form'
    },
    {
        path: 'employee/data',
        element: EmployeeController,
        view: 'data'
    },
    {
        path: 'employee/data/:employee_username',
        element: EmployeeController,
        view: 'data-detail'
    },

    // mitra
    // toko
    {
        path: 'mitra/toko/data',
        element: StoreController,
        view: 'store-data'
    },
    {
        path: 'mitra/toko/data/:code',
        element: StoreController,
        view: 'store-data-detail'
    },
    {
        path: 'mitra/toko/form',
        element: StoreController,
        view: 'store-form'
    },
    // supplier
    {
        path: 'mitra/supplier/form',
        element: MitraController,
        view: 'supplier-form'
    },
    {
        path: 'mitra/supplier/data',
        element: MitraController,
        view: 'supplier-data'
    },
    {
        path: 'mitra/supplier/data/:code',
        element: MitraController,
        view: 'supplier-data-detail'
    },

    // marketing
    {
        path: 'mitra/marketing/form',
        element: MitraController,
        view: 'marketing-form'
    },
    {
        path: 'mitra/marketing/data',
        element: MitraController,
        view: 'marketing-data'
    },
    {
        path: 'mitra/marketing/data/:code',
        element: MitraController,
        view: 'marketing-data-detail'
    },
    // reseller
    {
        path: 'mitra/reseller/data',
        element: MitraResellerController,
        view: 'data'
    },
    {
        path: 'mitra/reseller/data/:code',
        element: MitraResellerController,
        view: 'detail'
    },
    {
        path: 'mitra/reseller/form',
        element: MitraResellerController,
        view: 'form'
    },

    // master products
    // category
    {
        path: 'product/master/category/form',
        element: MasterProductController,
        view: 'category-form'
    },
    {
        path: 'product/master/category/data',
        element: MasterProductController,
        view: 'category-data'
    },

    // sub category
    {
        path: 'product/master/sub-category/form',
        element: MasterProductController,
        view: 'sub-category-form'
    },
    {
        path: 'product/master/sub-category/data',
        element: MasterProductController,
        view: 'sub-category-data'
    },

    // detail sub category
    {
        path: 'product/master/detail-sub-category/form',
        element: MasterProductController,
        view: 'detail-sub-category-form'
    },
    {
        path: 'product/master/detail-sub-category/data',
        element: MasterProductController,
        view: 'detail-sub-category-data'
    },
    
    // merk
    {
        path: 'product/master/merk/data',
        element: MasterProductController,
        view: 'merk-data'
    },
    {
        path: 'product/master/merk/form',
        element: MasterProductController,
        view: 'merk-form'
    },

    // products
    {
        path: 'product/form',
        element: ProductController,
        view: 'form'
    },
    {
        path: 'product/data',
        element: ProductController,
        view: 'data'
    },
    {
        path: 'product/data/:code',
        element: ProductController,
        view: 'detail'
    },
    {
        path: 'product/setting',
        element: ProductController,
        view: 'setting'
    },

    // warehouse
    // purchase
    {
        path: 'warehouse/purchase/data',
        element: WarehouseController,
        view: 'purchase-data'
    },
    {
        path: 'warehouse/purchase/data/:code',
        element: WarehouseController,
        view: 'purchase-data-detail'
    },
    {
        path: 'warehouse/purchase/form',
        element: WarehouseController,
        view: 'purchase-form'
    },
    {
        path: 'warehouse/purchase/void/data',
        element: WarehouseController,
        view: 'purchase-void-data'
    },

    // purchase tempo
    {
        path: 'warehouse/purchase/tempo/data',
        element: WarehouseController,
        view: 'purchase-tempo-data'
    },
    {
        path: 'warehouse/purchase/tempo/data/:code',
        element: WarehouseController,
        view: 'purchase-tempo-detail'
    },

    // penerimaan
    {
        path: 'warehouse/penerimaan/data',
        element: WarehousePenerimaanController,
        view: 'data'
    },
    {
        path: 'warehouse/penerimaan/data/:code',
        element: WarehousePenerimaanController,
        view: 'data-detail'
    },
    {
        path: 'warehouse/penerimaan/validasi',
        element: WarehousePenerimaanController,
        view: 'validasi'
    },
    {
        path: 'warehouse/penerimaan/validasi/:code',
        element: WarehousePenerimaanController,
        view: 'validasi-detail'
    },

    // stock
    {
        path: 'warehouse/stock',
        element: WarehouseController,
        view: 'stock'
    },

    // retur
    {
        path: 'warehouse/retur/data',
        element: WarehouseReturController,
        view: 'data'
    },
    {
        path: 'warehouse/retur/data/:code',
        element: WarehouseReturController,
        view: 'data-detail'
    },
    {
        path: 'warehouse/retur/validasi',
        element: WarehouseReturController,
        view: 'validasi'
    },
    {
        path: 'warehouse/retur/validasi/:code',
        element: WarehouseReturController,
        view: 'validasi-detail'
    },

    // spoil
    {
        path: 'warehouse/spoil/data',
        element: WarehouseSpoilController,
        view : 'data'
    },
    {
        path: 'warehouse/spoil/data/:code',
        element: WarehouseSpoilController,
        view : 'data-detail'
    },
    {
        path: 'warehouse/spoil/form',
        element: WarehouseSpoilController,
        view : 'form'
    },
    {
        path: 'warehouse/spoil/validasi',
        element: WarehouseSpoilController,
        view : 'validasi'
    },
    {
        path: 'warehouse/spoil/validasi/:code',
        element: WarehouseSpoilController,
        view : 'validasi-detail'
    },

    // opname
    {
        path: 'warehouse/opname/form/:code',
        element: WarehouseController,
        view: 'opname-form'
    },
    {
        path: 'warehouse/opname',
        element: WarehouseController,
        view: 'opname'
    },
    {
        path: 'warehouse/opname/data',
        element: WarehouseController,
        view: 'opname-data'
    },
    {
        path: 'warehouse/opname/validasi/:code',
        element: WarehouseController,
        view: 'opname-validasi'
    },
    {
        path: 'warehouse/opname/:code',
        element: WarehouseController,
        view: 'opname-detail'
    },
    {
        path: 'warehouse/opname/schedule/form',
        element: WarehouseController,
        view: 'opname-jadwal-form'
    },
    {
        path: 'warehouse/opname/terlaksana',
        element: WarehouseController,
        view: 'opname-terlaksana'
    },
    {
        path: 'warehouse/opname/terlaksana/:code',
        element: WarehouseController,
        view: 'opname-terlaksana-validasi'
    },
    {
        path: 'warehouse/opname/selesai/:code',
        element: WarehouseController,
        view: 'opname-selesai-detail'
    },

    // request sale
    {
        path: 'warehouse/request/sale',
        element: WarehouseRequestController,
        view: 'request-sale'
    },
    {
        path: 'warehouse/request/sale/:code',
        element: WarehouseRequestController,
        view: 'request-sale-detail'
    },

    // sales
    {
        path: 'sales/marketing/form',
        element: SalesMarketingController,
        view: 'form'
    },
    {
        path: 'sales/marketing/data',
        element: SalesMarketingController,
        view: 'data'
    },
    {
        path: 'sales/marketing/data/:code',
        element: SalesMarketingController,
        view: 'data-detail'
    },
    {
        path: 'sales/marketing/tempo',
        element: SalesMarketingController,
        view: 'tempo'
    },
    {
        path: 'sales/marketing/tempo/:code',
        element: SalesMarketingController,
        view: 'tempo-detail'
    },

    // retur sales
    {
        path: 'sales/retur/data',
        element: SalesReturController,
        view: 'data'
    },
    {
        path: 'sales/retur/data/:code',
        element: SalesReturController,
        view: 'data-detail'
    },
    {
        path: 'sales/retur/form',
        element: SalesReturController,
        view: 'form'
    },
    {
        path: 'sales/retur/validasi',
        element: SalesReturController,
        view: 'validasi'
    },
    {
        path: 'sales/retur/validasi/:code',
        element: SalesReturController,
        view: 'validasi-detail'
    },

    // sales spoil
    {
        path: 'sales/spoil/data',
        element: SalesSpoilController,
        view: 'data'
    },
    {
        path: 'sales/spoil/data/:code',
        element: SalesSpoilController,
        view: 'data-detail'
    },
    {
        path: 'sales/spoil/cashier',
        element: SalesSpoilController,
        view: 'cashier'
    },
    {
        path: 'sales/spoil/validasi',
        element: SalesSpoilController,
        view: 'validasi'
    },
    {
        path: 'sales/spoil/validasi/:code',
        element: SalesSpoilController,
        view: 'validasi-detail'
    },

    {
        path: 'sales/tempo/data',
        element: SalesController,
        view: 'sales-tempo-data'
    },
    {
        path: 'sales/tempo/data/:code',
        element: SalesController,
        view: 'sales-tempo-detail'
    },

    // delivery
    {
        path: 'delivery/data',
        element: DeliveryController,
        view: 'delivery'
    },
    {
        path: 'delivery/data/:code',
        element: DeliveryController,
        view: 'delivery-detail'
    },
    {
        path: 'delivery/data/:code/:code_sales_marketing',
        element: DeliveryController,
        view: 'delivery-detail-sales'
    },
    {
        path: 'delivery/diterima/:code/:code_sales_marketing',
        element: DeliveryController,
        view: 'delivery-diterima-detail'
    },
    {
        path: 'delivery/schedule/form',
        element: DeliveryScheduleController,
        view: 'form'
    },
    {
        path: 'delivery/retur',
        element: DeliveryController,
        view: 'retur'
    },
    {
        path: 'delivery/retur/:code',
        element: DeliveryController,
        view: 'retur-detail'
    },
    {
        path: 'delivery/spoil',
        element: DeliveryController,
        view: 'spoil'
    },
    {
        path: 'delivery/spoil/:code',
        element: DeliveryController,
        view: 'spoil-detail'
    },

    // pendapatan lain
    {
        path: 'other-income/data',
        element: OtherIncomeController,
        view: 'other-income'
    },
    {
        path: 'other-income/data/:code',
        element: OtherIncomeController,
        view: 'other-income-detail'
    },
    {
        path: 'other-income/form',
        element: OtherIncomeController,
        view: 'other-income-form'
    },

    // Operasional
    {
        path: 'operasional/bisnis/data',
        element: OperasionalController,
        view: 'bisnis'
    },
    {
        path: 'operasional/bisnis/data/:code',
        element: OperasionalController,
        view: 'bisnis-detail'
    },
    {
        path: 'operasional/bisnis/form',
        element: OperasionalController,
        view: 'bisnis-form'
    },
    
    {
        path: 'operasional/non-bisnis/data',
        element: OperasionalController,
        view: 'non-bisnis-data'
    },
    {
        path: 'operasional/non-bisnis/form',
        element: OperasionalController,
        view: 'non-bisnis-form'
    },

    // financial
    {
        path: 'financial/data',
        element: FinancialController,
        view: 'financial'
    },
    {
        path: 'financial/hutang',
        element: FinancialController,
        view: 'hutang'
    },
    {
        path: 'financial/hutang/:code',
        element: FinancialController,
        view: 'hutang-detail'
    },
    {
        path: 'financial/piutang',
        element: FinancialController,
        view: 'piutang'
    },
    {
        path: 'financial/piutang/:code',
        element: FinancialController,
        view: 'piutang-detail'
    },

    // rkap
    // rkap variable
    {
        path: 'rkap/variable/data',
        element: RkapVariableController,
        view: 'data'
    },
    {
        path: 'rkap/variable/data/:code',
        element: RkapVariableController,
        view: 'detail'
    },
    {
        path: 'rkap/variable/form',
        element: RkapVariableController,
        view: 'form'
    },  
    {
        path: 'rkap/input/data',
        element: RkapController,
        view: 'input-data'
    },
    {
        path: 'rkap/input/form',
        element: RkapController,
        view: 'input-form'
    },
    {
        path: 'rkap/realisasi/rkap',
        element: RkapRealisasiController,
        view: 'rkap'
    },
    {
        path: 'rkap/realisasi/compare',
        element: RkapRealisasiController,
        view: 'compare'
    },
    {
        path: 'rkap/realisasi/compare/:year',
        element: RkapRealisasiController,
        view: 'compare-detail'
    }
];

export default routes;