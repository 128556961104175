import React from 'react'
import SalesReturForm from '../../views/sales/retur/form/SalesReturForm'
import SalesReturValidasi from '../../views/sales/retur/validasi/SalesReturValidasi'
import SalesReturData from '../../views/sales/retur/data/SalesReturData'
import SalesReturValidasiDetail from '../../views/sales/retur/validasi/SalesReturValidasiDetail'
import SalesReturDataDetail from '../../views/sales/retur/data/SalesReturDataDetail'

function SalesReturController({ view }) {
    if (view === 'form') {
        return (
            <SalesReturForm />
        )
    } else if (view === 'validasi') {
        return (
            <SalesReturValidasi />
        )
    } else if (view === 'validasi-detail') {
        return (
            <SalesReturValidasiDetail />
        )
    } else if (view === 'data-detail') {
        return (
            <SalesReturDataDetail />
        )
    } else {
        return (
            <SalesReturData />
        )
    }
}

export default SalesReturController
