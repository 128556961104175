import React, { useEffect, useState } from 'react'
import Product from '../Product'
import { useDispatch } from 'react-redux'
import { getProduct } from '../../../models/product/product_m';
import toast from 'react-hot-toast';
import { Autocomplete, Backdrop, Box, Button, Fade, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';

import { NumericFormat } from 'react-number-format';
import SalesModalSettingPrice from '../../sales/data/SalesModalSettingPrice';

import Paginate from '../../pagination/Paginate'
import { CloudDownload, CloudUpload } from '@mui/icons-material';
import { getSearchCategoryByCodeName } from '../../../models/product/product_category_m';
import { getSearchProductSubCategoryByCategoryCodeName } from '../../../models/product/product_sub_category_m';

const ProductData = () => {
    const dispatch = useDispatch();

    const [product, setProduct] = useState([]);
    const [pagination, setPagination] = useState([]);

    // modal
    const [open, setOpen] = useState(false);
    const [setting, setSetting] = useState({
        code: '', name: ''
    });
  

    const getDataProduct = async(page) => {
        try {
            const response = await dispatch(getProduct(page)).unwrap().catch((err) => {});
            setProduct(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [subDetailCategory, setSubDetailCategory] = useState([]);
    const getDataCategory = async(code_name_search) => {
        try {
            const response = await dispatch(getSearchCategoryByCodeName(code_name_search)).unwrap().catch((err) => {});
            setCategory(response.data.map((i) => ({ code: i.code, label: i.name })))
        } catch (error) {
            
        }
    }

    const getDataSubCategory = async(data) => {
        try {
            const response = await dispatch(getSearchProductSubCategoryByCategoryCodeName(data)).unwrap().catch((err) => {})
            setSubCategory(response.data.map((i) => ({code: i.code, label: i.name})))
        } catch (error) {
            
        }
    }

    const getDataDetailCategory = async(data) => {
        try {
            
        } catch (error) {
            
        }
    }

    // filter
    const date = new Date();
    const year = date.getFullYear();

    const [filter, setFilter] = useState({
        code_category: '', category: '', code_sub_category: '', sub_category: '', 
        code_sub_category_detail: '', sub_category_detail: '', name: ''
    })

    useEffect(() => {
        getDataProduct(1);
        getDataCategory('-');
    }, [])

    const handlePagination = (_, page) => {
        return getDataProduct(page);
    }

    const ondetail = (code) => {
        return window.location.href = `data/${code}`
    }

    const onsetting = (code_product) => {
        setOpen(true)
        product.some(element => {
            if (element.code === code_product) {
                setSetting({
                    code: element.code,
                    name: element.name,
                    harga_beli: element.harga_beli
                })
            }
        })
       
    }  
    
    const handleCloseModal = () => {
        setOpen(false);
        setSetting([]);
    }

    const handleChangeCategory = (_, event) => {
        if (event) {
            setFilter({
                ...filter, 
                code_category: event.code, 
                category: event.label,
                code_sub_category: '', 
                sub_category: '',
                code_sub_category_detail: '',
                sub_category_detail: ''
            })

            const data = {
                code_category: event.code,
                code_name_search: '-'
            }
            return getDataSubCategory(data);
        }
    }

    const handleChangeSubCategory = (_, event) => {
        if (event) {
            setFilter({
                ...filter, 
                code_sub_category: event.code, 
                sub_category: event.label,
                code_sub_category_detail: '',
                sub_category_detail: ''
            })
        }
    }

    return (
        <Product>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-start mb-3">
                            <div></div>
                            <div>
                                <div style={{lineHeight: '60%'}} className="text-center">
                                    <h2>
                                        DATA PRODUK PERUMDA ANEKA USAHA <br /> 
                                        KABUPATEN JEPARA <br />
                                        Tahun { year }
                                    </h2>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="text-center me-2" style={{ cursor: 'pointer' }}>
                                    <span className="btn btn-icon btn-success" style={{ padding: '.4rem .4rem' }}>
                                        <CloudDownload sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </span>
                                    <span style={{ fontSize: '12px' }} className="d-block">Import</span>
                                </div>

                                <div className="text-center me-2" style={{ cursor: 'pointer' }}>
                                    <span className="btn btn-icon btn-pink" style={{ padding: '.4rem .4rem' }}>
                                        <CloudUpload sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </span>
                                    <span style={{ fontSize: '12px' }} className="d-block">Ekspor</span>
                                </div>
                            </div>
                        </div>

                        {/* <div className="row align-items-end">
                            <div className="col-md-2 mb-3">
                                <label className="form-label form-label-m-0">Kategori</label>
                                <Autocomplete 
                                    disableClearable
                                    size="small"
                                    value={filter.category}
                                    fullWidth
                                    options={category}
                                    renderInput={(params) => <TextField {...params} placeholder="Kategori Produk" />}
                                    onChange={handleChangeCategory}
                                />
                            </div>

                            <div className="col-md-2 mb-3">
                                <label htmlFor="" className="form-label form-label-m-0">Sub Kategori</label>
                                <Autocomplete 
                                    disableClearable
                                    size="small"
                                    value={filter.sub_category}
                                    fullWidth
                                    options={subCategory}
                                    renderInput={(params) => <TextField {...params} placeholder="Sub Kategori Produk" />}
                                    onChange={handleChangeSubCategory}
                                />
                            </div>

                            <div className="col-md-2 mb-3">
                                <label htmlFor="" className="form-label form-label-m-0">Detail Sub Kategori</label>
                                <Autocomplete 
                                    disableClearable
                                    size="small"
                                    value={filter.sub_category_detail}
                                    fullWidth
                                    options={subDetailCategory}
                                    renderInput={(params) => <TextField {...params} placeholder="Sub Kategori Detail Produk" />}
                                />
                            </div>

                            <div className="col-md-5 mb-3">
                                <label className="form-label form-label-m-0">Cari Nama Produk</label>
                                <TextField 
                                    size="small"
                                    fullWidth
                                    placeholder="Cari Nama Produk"
                                />
                            </div>

                            <div className="col-md-1 mb-3">
                                <Button fullWidth variant="contained">
                                    Filter
                                </Button>
                            </div>
                        </div> */}

                        <div className="table-responsive mb-3">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: '5%'}} className="table-border-start">
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nama Produk</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kategori</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Sub Kategori</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Harga Beli</span>
                                        </th>
                                        <th style={{width: '15%'}} className="text-center table-border-end">
                                            <span className="form-control">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        product.map((data) => {
                                            return (
                                                <tr key={data.no}>
                                                    <td className="text-center">{ data.no }</td>
                                                    <td>{data.code}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.category}</td>
                                                    <td>{data.sub_category}</td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ data.harga_beli } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="d-flex justify-content-center">
                                                            <Button onClick={() => onsetting(data.code)} variant="contained" color="primary" size="small" sx={{mr: 1}}>
                                                                Setting
                                                            </Button>
                                                            <Button onClick={() => ondetail(data.code)} variant="contained" color="success" size="small" sx={{mr: 1}}>
                                                                Detail
                                                            </Button>
                                                            {/* <Button variant="contained" color="error" size="small">
                                                                Delete
                                                            </Button> */}
                                                        </div> 
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        {/* pagination */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Halaman : { pagination.current_page ? pagination.current_page : '' }</span>
                            </div>

                            {
                                pagination.pagination ? 
                                <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} />  : ''
                            }

                            <div>
                                <span>Total : { pagination.total ? pagination.total : '' } Produk</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* modal */}
            {
                open ? <SalesModalSettingPrice open={open} onClose={handleCloseModal} setting={setting} /> : ''
            }
        </Product>
    )
}

export default ProductData
