import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../instanceapi";

const initialState = {

}

export const getOtherIncome = createAsyncThunk('other-income/getOtherIncome', async(page) => {
    try {
        const response = await instanceapi.get(`/other-income?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const storeOtherIncome = createAsyncThunk('other-income/storeOtherIncome', async(data) => {
    try {
        const response = await instanceapi.post('/other-income/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const other_income_m = createSlice({
    name: 'other-income',
    initialState
})

export default other_income_m.reducer;