import React, { useEffect, useState } from 'react'
import WarehouseSpoil from '../WarehouseSpoil'
import { ReplyAll } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { deleteSpoil, getDetailSpoil, updateSpoilValidasi } from '../../../../models/warehouse/warehouse_spoil_m'
import toast from 'react-hot-toast'

import Loading from '../../../loading/Loading'
import Moment from 'react-moment'
import { Button } from '@mui/material'

import { NumericFormat } from 'react-number-format';

const WarehouseSpoilValidasiDetail = () => {
    const { username, code } = useParams()
    const dispatch = useDispatch();

    const [spoil, setSpoil] = useState([]);
    const [spoilLists, setSpoilLists] = useState([]);
    const [loading, setLoading] = useState(false); 

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailSpoil(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setSpoil(response.spoil)
                setSpoilLists(response.spoil_lists);
            }

            if (response.status === 404) {
                setLoading(true);
                toast.error('Data tidak ditemukan');
                setTimeout(() => {
                   return window.location.href = `/${username}/warehouse/spoil/validasi` 
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const onBack = () => {
        return window.location.href = `/${username}/warehouse/spoil/validasi`
    }

    useEffect(() => {
        getDetail();
    }, []);

    const onDeleteData = async() => {
        try {
            const response = await dispatch(deleteSpoil(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setLoading(true);
                toast.success('Data berhasil dihapus');
                setTimeout(() => {
                    return window.location.href = `/${username}/warehouse/spoil/validasi`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const onValidasiData = async() => {
        try {
            const response = await dispatch(updateSpoilValidasi(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Data berhasil divalidasi');
                setLoading(true);
                setTimeout(() => {
                    return window.location.href = `/${username}/warehouse/spoil/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const sub_total = spoilLists.reduce((i, j) => i + (j.qty * j.unit_price), 0);
    const sub_total_ppn = spoilLists.reduce((i, j) => i + j.ppn, 0);

    return (
        <WarehouseSpoil>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DETAIL SPOIL { code }</h3>
                                <span>Detail Spoil Pergudangan</span>
                            </div>
                            <div className="d-flex">
                                <div onClick={onBack} className="text-center me-2" style={{cursor: 'pointer'}}>
                                    <span className="btn btn-icon btn-orange" style={{padding: '.4rem .4rem', borderRadius: '100%',}}>
                                        <ReplyAll sx={{width: '1.25rem', height: '1.25rem'}} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    <table className="table-borderless align-middle table-sm">
                                        <tbody>
                                            <tr>
                                                <th>Kode Spoil</th>
                                                <td>: { code }</td>
                                            </tr>
                                            <tr>
                                                <th>Tanggal Spoil</th>
                                                <td>
                                                    : <Moment format='dddd, DD MMMM YYYY'>{spoil.date}</Moment>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Petugas</th>
                                                <td>: {spoil.employee}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Kode Produk</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Nama Produk</span>
                                                </th>
                                                <th className="text-center">
                                                    <span className="form-control">Jumlah Spoil</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Harga Beli</span>
                                                </th>
                                                <th>
                                                    <span className="form-control">Sub Total</span>
                                                </th>
                                                <th className="table-border-end">
                                                    <span className="form-control">PPN</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                spoilLists.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{data.code_product}</td>
                                                        <td>{data.product}</td>
                                                        <td className="text-center">{data.qty}</td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.unit_price } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.qty * data.unit_price } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.ppn } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                            <tr>
                                                <th colSpan={5}>SUB TOTAL SPOIL</th>
                                                <th>
                                                    <NumericFormat 
                                                        value={ sub_total } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </th>
                                                <th>
                                                    <NumericFormat 
                                                        value={ sub_total_ppn } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </th>
                                            </tr>

                                            <tr>
                                                <th colSpan={5}>TOTAL TRANSAKSI SPOIL</th>
                                                <th colSpan={2} className="text-center">
                                                    <NumericFormat 
                                                        value={ spoil.nominal } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-price mb-2">
                                    <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                        <span>
                                            Total Transaksi Spoil
                                        </span>
                                        <span>
                                            { code }
                                        </span>
                                    </div>

                                    <div className="text-center price-total">
                                        <NumericFormat 
                                            value={ spoil.nominal } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">Keterangan</label>
                                    <span className="form-control">{spoil.desc}</span>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="form-label form-label-m-0">Bukti Spoil</label>
                                        <a href={spoil.file} target="_blank" className="badge bg-primary">Lihat Bukti Spoil</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <Button onClick={onDeleteData} fullWidth variant="contained" color="error">
                                            Tolak
                                        </Button>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Button onClick={onValidasiData} fullWidth variant="contained" color="success">
                                            Validasi
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WarehouseSpoil>
    )
}

export default WarehouseSpoilValidasiDetail
