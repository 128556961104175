import React, { useEffect, useState } from 'react'
import Warehouse from '../../Warehouse'
import { useParams } from 'react-router-dom'
import { ReplyAll } from '@mui/icons-material';
import { getDetailReportPenerimaan } from '../../../../models/penerimaan/report_penerimaan_m';
import toast from 'react-hot-toast';

import Loading from '../../../loading/Loading'
import { useDispatch } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';

const WarehousePenerimaanDataDetail = () => {
    const { username, code } = useParams();
    const dispatch = useDispatch();

    const handleBack = () => {
        return window.location.href = `/${username}/warehouse/penerimaan/data`
    }

    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState([]);
    const [list, setList] = useState([]);

    const getDataDetail = async() => {
        try {
            const response = await dispatch(getDetailReportPenerimaan(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setReport(response.report_penerimaan);
                setList(response.penerimaan_list)
            }

            if (response.status === 404) {
                toast.error('Data tidak ditemukan')
                setLoading(true)
                setTimeout(() => {
                    return window.location.href = `/${username}/warehouse/penerimaan/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataDetail();
    }, []);

    return (
        <Warehouse>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>Penerimaan { code }</h3>
                                <span>Detail Penerimaan Pembelian</span>
                            </div>

                            <div className="d-flex">
                                <div onClick={handleBack} className="text-center me-2" style={{cursor: 'pointer'}}>
                                    <span className="btn btn-icon btn-orange" style={{padding: '.4rem .4rem', borderRadius: '100%'}}>
                                        <ReplyAll sx={{width: '1.25rem', height: '1.25rem'}} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            <table className="table table-borderless table-sm">
                                <tbody>
                                    <tr>
                                        <th>Kode Pembelian</th>
                                        <td>: {report.code_purchase_total}</td>

                                        <th>Supplier</th>
                                        <td>: {report.supplier}</td>
                                    </tr>
                                    <tr>
                                        <th>Tanggal Penerimaan</th>
                                        <td>
                                            : <Moment format="dddd, DD MMMM YYYY">{report.tgl_penerimaan}</Moment>, {report.waktu} WIB
                                        </td>

                                        <th>Status</th>
                                        <td>
                                            :  {
                                                report.retur === 'iya' ?
                                                <span className="badge bg-danger">Tidak Sesuai</span> :
                                                <span className="badge bg-success">Sesuai</span>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Petugas</th>
                                        <td>: {report.employee}</td>

                                        <th>File Bukti Penerimaan</th>
                                        <td>
                                            : <a href={report.file} target="_blank">Lihat Bukti</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode Produk</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nama Produk</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kategori</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Jumlah Pembelian</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Jumlah Diterima</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Status</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{data.code_product}</td>
                                                    <td>{data.product}</td>
                                                    <td>{data.category}</td>
                                                    <td>{data.qty}</td>
                                                    <td>{data.accept_qty}</td>
                                                    <td>
                                                        {
                                                            data.status === 'sesuai' ? 'Sesuai' : 'Tidak Sesuai'
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    <tr>
                                        <th>
                                            Catatan
                                        </th>
                                        <td colSpan={6}>
                                            : { report.desc }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Warehouse>
    )
}

export default WarehousePenerimaanDataDetail
