import React, { useEffect, useState } from 'react'
import Delivery from '../Delivery'
import { ReplyAll } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDetailDelivery, updateDeliveryStatusPengiriman } from '../../../models/delivery/delivery_m';
import Moment from 'react-moment';
import { Button } from '@mui/material';
import toast from 'react-hot-toast';

const DeliveryDataDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { username, code } = useParams();

    const handleBack = () => {
        return navigate(`/${username}/delivery/data`);
    }

    const [delivery, setDelivery] = useState([]);
    const [deliveryLists, setDeliveryLists] = useState([]);

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailDelivery(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setDelivery(response.delivery)
                setDeliveryLists(response.delivery_lists)
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail()
    }, []);

    const handleDetailSalesMarketing = (code_sales_marketing) => {
        return navigate(`${code_sales_marketing}`)
    }

    const handleDeliveryDiterima =(code_sales_marketing) => {
        return navigate(`/${username}/delivery/diterima/${code}/${code_sales_marketing}`);
    }

    const onUpdateStatusPengiriman = async(e) => {
        e.preventDefault();
        try {
            const data = {
                status_pengiriman: 'perjalanan',
                code: code
            }

            const response = await dispatch(updateDeliveryStatusPengiriman(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Data berhasil diupdate');
                setTimeout(() => {
                   window.location.reload(); 
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const onUpdateStatusPengirimanSelesai = async(e) => {
        e.preventDefault();
        try {
            const data = {
                status_pengiriman: 'selesai',
                code: code
            }

            const response = await dispatch(updateDeliveryStatusPengiriman(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Data berhasil diupdate');
                setTimeout(() => {
                   window.location.reload(); 
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <Delivery>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>{ delivery.code }</h3>
                                <span>Detail Pengiriman</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            <table className="table table-borderless table-sm align-middle" style={{minWidth: '100%'}}>
                                <tbody>
                                    <tr>
                                        <th>Kode Pengiriman</th>
                                        <td>: { delivery.code }</td>

                                        <td className="text-end">
                                            {
                                                delivery.status === 'perjalanan' ?
                                                <form onSubmit={onUpdateStatusPengirimanSelesai}>
                                                    <Button size="small" variant="contained" type="submit" color="primary">
                                                        Update Pengiriman Selesai
                                                    </Button>
                                                </form> : 
                                               delivery.status === 'selesai' ? 
                                                <Button size="small" disabled variant="contained" type="submit" color="success">
                                                    Selesai
                                                </Button> : 
                                                <form onSubmit={onUpdateStatusPengiriman}>
                                                    <Button size="small" variant="contained" type="submit" color="success">
                                                        Update Ke Perjalanan
                                                    </Button>
                                                </form>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Tanggal Pengiriman</th>
                                        <td>
                                            : <Moment format="dddd, DD MMMM YYYY">{delivery.tgl_pengiriman}</Moment>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Petugas</th>
                                        <td>: {delivery.employee}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-borderless align-middle">
                                <thead>
                                    <tr>
                                        <th className="text-center table-border-start" style={{width: '5%'}}>
                                            <span className="form-control fw-bold">
                                                No
                                            </span>
                                        </th>
                                        <th style={{width: '15%'}}>
                                            <span className="form-control fw-bold">
                                                Kode Penjualan
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Marketing
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Alamat
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Nomor Telephone
                                            </span>
                                        </th>
                                        <th className="text-center">
                                            <span className="form-control fw-bold">
                                                Status
                                            </span>
                                        </th>
                                        <th className="table-border-end text-center" style={{width: '15%'}}>
                                            <span className="form-control fw-bold">
                                                Action
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        deliveryLists.map((data, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>{data.code_sales_marketing}</td>
                                                <td>{data.marketing}</td>
                                                <td>{data.address}</td>
                                                <td>{data.phone}</td>
                                                <td className="text-center">
                                                    {
                                                        data.status_pengiriman === 'proses' ? 
                                                        <span className="badge bg-orange">Proses</span> :
                                                        data.status_pengiriman === 'perjalanan' ? 
                                                        <span className="badge bg-pink">Perjalanan</span> :
                                                        <span className="badge bg-green">Diterima</span>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        data.status_pengiriman === 'diterima' ? 
                                                        <Button onClick={() => handleDeliveryDiterima(data.code_sales_marketing)} size="small" variant="contained" sx={{backgroundColor: '#fb0fcb', ':hover' : { backgroundColor: '#e305b6'}}}>
                                                            Detail
                                                        </Button> : 
                                                        <Button onClick={() => handleDetailSalesMarketing(data.code_sales_marketing)} size="small" variant="contained" color="primary">
                                                            Validasi Pengiriman
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-3">
                            <span className="fw-bold">CATATAN</span> :
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam accusamus corrupti nesciunt voluptas iure neque modi officiis vitae inventore voluptate obcaecati, consectetur sunt nostrum repellendus beatae corporis optio repellat perferendis.
                        </div>
                    </div>
                </div>
            </div>
        </Delivery>
    )
}

export default DeliveryDataDetail
