import React from 'react'
import RkapVariableForm from '../../views/rkap/variable/form/RkapVariableForm'
import RkapVariableData from '../../views/rkap/variable/data/RkapVariableData'

function RkapVariableController({ view }) {
    if (view === 'form') {
        return (
            <RkapVariableForm />
        )
    } else if (view === 'detail') {
        
    } else {
        return (
            <RkapVariableData />
        )
    }
}

export default RkapVariableController
