import React, { useState } from 'react'
import Layouts from '../layouts/Layouts'
import { TableView } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { Button, TextField } from '@mui/material'

import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

import { imageResizer } from '../../utils'
import { useDispatch } from 'react-redux'
import { storeOtherIncome } from '../../models/other_income/other_income_m'
import toast, { Toaster } from 'react-hot-toast'

import Loading from '../loading/Loading'

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="Rp. "
        />
    );
});

NumericFormatCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const OtherIncomeForm = () => {
    const { username } = useParams();
    const dispatch = useDispatch();

    const dataIncome = () => {
        return window.location.href = `/${username}/other-income/data`
    }

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const time = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

    const [loading, setLoading] = useState(false);

    const [input, setInput] = useState({
        code: `OT${day}${month}${year}${time}` , name: '', desc: '', file: '', nominal: ''
    })

    const handleFile = async(e) => {
        const file = e.target.files[0];
        const resizer = await imageResizer(file, 480, 480);
        setInput({...input, file: resizer})
    }

    const onSubmit = async(e) => {
        e.preventDefault();

        setLoading(true);
        const data = {
            ...input,
            date: `${year}-${month}-${day}`,
            month: `${month}`,
            year: `${year}`
        }

        try {
            const response = await dispatch(storeOtherIncome(data)).unwrap().catch((err) => {});
            if (response.status === 201) {
                toast.success('Data berhasil disimpan')
                setTimeout(() => {
                   return window.location.href = `/${username}/other-income/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <Layouts>
            <Toaster position="top-center" />
            { loading ? <Loading /> : '' }
            <div>
                <h1>PENDAPATAN LAINNYA</h1>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>Form Pendapatan Lainnya</h3>
                                <span>List Form Pendapatan Lainnya</span>
                            </div>
                            <div onClick={dataIncome} className="text-center me-2" style={{ cursor: 'pointer' }}>
                                <span className="btn btn-icon btn-primary" style={{ padding: '.4rem .4rem' }}>
                                    <TableView sx={{ width: '1.25rem', height: '1.25rem' }} />
                                </span>
                                <span style={{ fontSize: '12px' }} className="d-block">Data</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onSubmit}>
                    <div className="row row-deck">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Kode Pendapatan</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            value={input.code}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Jenis Pendapatan</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Jenis Pendapatan"
                                            onChange={(e) => setInput({...input, name: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="div">
                                        <label className="form-label form-label-m-0">Keterangan</label>
                                        <textarea style={{height:'80px'}} placeholder="Tulis Keterangan" onChange={(e) => setInput({...input, desc: e.target.value})} className="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Nominal</label>
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            onChange={(e) => setInput({...input, nominal: e.target.value})}
                                            placeholder="Rp. "
                                            InputProps={{
                                                inputComponent: NumericFormatCustom,
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label form-label-m-0">Upload Bukti</label>
                                        <input type="file" accept="image/png, image/jpg, image/jpeg" required onChange={handleFile} className="form-control" />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button type="submit" variant="contained" color="primary">
                                            Simpan Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Layouts>
    )
}

export default OtherIncomeForm
