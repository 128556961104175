import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from "../../instanceapi";

const initialState = {

}

export const getSalesMarketing = createAsyncThunk('sales-marketing/getSalesMarketing', async(page) => {
    try {
        const response = await instanceapi.get(`/sales/marketing?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const storeSalesMarketing = createAsyncThunk('sales-marketing/storeSalesMarketing', async(data) => {
    try {
        const response = await instanceapi.post('/sales/marketing/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getDetailSalesMarketing = createAsyncThunk('sales-marketing/getDetailSalesMarketing', async(code) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/detail/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

// get by status
export const getSalesMarketingByStatusGudang = createAsyncThunk('sales-marketing/getSalesMarketingByStatusGudang', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/status/gudang/${data.status_gudang}?page=${data.page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingByStatusTempo = createAsyncThunk('sales-marketing/getSalesMarketingByStatusTempo', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/status/tempo/${data.status_tempo}?page=${data.page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingByStatusJadwalPengiriman = createAsyncThunk('sales-marketing/getSalesMarketingByStatusJadwalPengiriman', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/status/jadwal/${data.status_jadwal_pengiriman}?page=${data.page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})


// tempo
export const getSalesMarketingDetailTempo = createAsyncThunk('sales-marketing/getSalesMarketingDetailTempo', async(code) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/tempo/detail/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const storeSalesMarketingTempo = createAsyncThunk('sales-marketing/storeSalesMarketingTempo', async(data) => {
    try {
        const response = await instanceapi.post(`/sales/marketing/tempo/store/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})



// delete if clear
export const getSalesMarketingByStatusValidasi = createAsyncThunk('sales-marketing/getSalesMarketingByStatusValidasi', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/status/validasi/${data.status_validasi}?page=${data.page}`).catch((err) => {})
        return response.data;
    } catch (error) {
        
    }
})

export const updateSalesMarketingByStatusValidasi = createAsyncThunk('sales-marketing/updateSalesMarketingByStatusValidasi', async(data) => {
    try {
        const response = await instanceapi.post(`/sales/marketing/validasi/status/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingByStatusJadwal = createAsyncThunk('sales-marketing/getSalesMarketingByStatusJadwal', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/status/jadwal/${data.status_jadwal}?page=${data.page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

// tempo
export const getSalesMarketingTempo = createAsyncThunk('sales-marketing/getSalesMarketingTempo', async(code_sales_marketing) => {
    try {
        const response = await instanceapi.get(`/sales/tempo/marketing/${code_sales_marketing}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingTempoData = createAsyncThunk('sales-marketing/getSalesMarketingTempoData', async(page) => {
    try {
        const response = await instanceapi.get(`/sales/tempo?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const updateSalesMarketingTempoData = createAsyncThunk('sales-marketing/updateSalesMarketingTempoData', async(data) => {
    try {
        const response = await instanceapi.post(`/sales/tempo/update/data/${data.code_sale_marketing}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingByCodeMarketing = createAsyncThunk('sales-marketing/getSalesMarketingByCodeMarketing', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/code/marketing/${data.code}/${data.code_marketing}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const postSalesMarketingCancel = createAsyncThunk('sales-marketing/postSalesMarketingCancel', async(data) => {
    try {
        const response = await instanceapi.post(`/sales/marketing/validasi/cancel/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getSalesMarketingFilterData = createAsyncThunk('sales-marketing/getSalesMarketingFilterData', async(data) => {
    try {
        const response = await instanceapi.get(`/sales/marketing/filter/year/month/name/${data.year}/${data.month}/${data.name}?page=${data.page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const sales_marketing_m = createSlice({
    name: 'sales-marketing',
    initialState
})

export default sales_marketing_m.reducer;