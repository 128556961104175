import React, { useEffect, useState } from 'react'
import Sales from '../Sales'
import { ArrowBackIosNew, ReplyAll } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDetailSalesMarketing } from '../../../models/sales/marketing/sales_marketing_m';
import Moment from 'react-moment';


import { NumericFormat } from 'react-number-format';
import SalesDataTempo from './SalesDataTempo';

const SalesDataDetail = () => {
    const navigate = useNavigate();
    const { username, code } = useParams();
    const dispatch = useDispatch();

    const handleBack = () => {
        return navigate(-1);
    }

    const [sales, setSales] = useState([]);
    const [list, setList] = useState([]);
    const [marketing, setMarketing] = useState([]);

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailSalesMarketing(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setSales(response.sales);
                setMarketing(response.marketing)
                setList(response.list)
            }

        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail()
    }, []);

    return (
        <Sales>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>TRANSAKSI { sales.code }</h3>
                                <span>Detail Transaksi Penjualan</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    <table className="table table-borderless align-middle table-sm" style={{minWidth: '100%'}}>
                                        <tbody>
                                            <tr>
                                                <th>Kode Transaksi</th>
                                                <td>: { sales.code }</td>

                                                <th>Nama Mitra</th>
                                                <td>: { marketing.name }</td>
                                                {
                                                    sales.status === 'not' ? 
                                                    <th>Status Transaksi</th> : <th>Status Pengiriman</th>
                                                }
                                                <td>  
                                                    : {
                                                        sales.status === 'not' ? 
                                                        <span className="badge bg-danger">Di Tolak</span> : 
                                                        sales.status_pengiriman === 'proses' ?
                                                        <span className="badge bg-orange">Proses</span> : 
                                                        sales.status_pengiriman === 'perjalanan' ? 
                                                        <span className="badge bg-info">Perjalanan</span> : 
                                                        <span className="badge bg-green">Diterima</span>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Tanggal Transaksi</th>
                                                <td>
                                                    : <Moment format="dddd, DD MMMM YYYY">{ sales.tgl_transaksi }</Moment>
                                                </td>

                                                <th>Alamat</th>
                                                <td>: { marketing.address }</td>
                                            </tr>
                                            <tr>
                                                <th>Petugas</th>
                                                <td>: {sales.petugas}</td>
                                                
                                                <th>Nomor HP</th>
                                                <td>: { marketing.contact_primary }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="table-responsive  mb-3">
                                    <table className="table table-borderless align-middle">
                                        <thead>
                                            <tr>
                                                <th className="text-center table-border-start" style={{width: '5%'}}>
                                                    <span className="form-control fw-bold">
                                                        No
                                                    </span>
                                                </th>
                                                <th style={{width: '15%'}}>
                                                    <span className="form-control fw-bold">
                                                        Kode Produk
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">
                                                        Nama Produk
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">
                                                        Harga Satuan
                                                    </span>
                                                </th>
                                                <th className="text-center">
                                                    <span className="form-control fw-bold">
                                                        Jumlah
                                                    </span>
                                                </th>
                                                <th className="text-center table-border-end">
                                                    <span className="form-control fw-bold">
                                                        Sub Total
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                list.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{data.code_product}</td>
                                                        <td>{data.product}</td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.unit_price } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td className="text-center">{data.qty}</td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.sub_total } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                            {/* total */}
                                            <tr>
                                                <th colSpan={5}>PPN</th>
                                                <th>
                                                    <NumericFormat 
                                                        value={ sales.ppn } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colSpan={5}>TOTAL PEMBAYARAN</th>
                                                <th>
                                                    <NumericFormat 
                                                        value={ sales.total_pembayaran } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    sales.sistem_pembayaran === 'tempo' && sales.status !== 'not' ? 
                                    <SalesDataTempo code_sales_marketing={code} /> : ''
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <label className="form-label form-label-m-0">Keterangan</label>
                                <span className="form-control">
                                    { sales.desc }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Sales>
    )
}

export default SalesDataDetail
