import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instanceapi from '../instanceapi'

const initialState = {

}

export const getDelivery = createAsyncThunk('delivery/getDelivery', async(page) => {
    try {
        const response = await instanceapi.get(`/delivery?page=${page}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const storeDelivery = createAsyncThunk('delivery/storeDelivery', async(data) => {
    try {
        const response = await instanceapi.post('/delivery/store', data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const getDetailDelivery = createAsyncThunk('delivery/getDetailDelivery', async(code) => {
    try {
        const response = await instanceapi.get(`/delivery/detail/${code}`).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const updateDeliveryStatusPengiriman = createAsyncThunk('delivery/updateDeliveryStatusPengiriman', async(data) => {
    try {
        const response = await instanceapi.post(`/delivery/update/pengiriman/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

export const updateDeliveryValidasiPenerimaan = createAsyncThunk('delivery/updateDeliveryValidasiPenerimaan', async(data) => {
    try {
        const response = await instanceapi.post(`/delivery/update/validasi/penerimaan/${data.code}`, data).catch((err) => {});
        return response.data;
    } catch (error) {
        
    }
})

const delivery_m = createSlice({
    name: 'delivery',
    initialState
})

export default delivery_m.reducer;