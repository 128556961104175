import React, { useEffect, useState } from 'react'
import WarehouseSpoil from '../WarehouseSpoil'
import { useDispatch } from 'react-redux'

import Paginate from '../../../pagination/Paginate'
import { getSpoilByStatus } from '../../../../models/warehouse/warehouse_spoil_m'
import Moment from 'react-moment'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const WarehouseSpoilData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [spoil, setSpoil] = useState([]);
    const [pagination, setPagination] = useState([]);

    const getData = async(page) => {
        try {
            const data = {
                status: 'validasi',
                page: page
            }
            const response = await dispatch(getSpoilByStatus(data)).unwrap().catch((err) => {});
            setSpoil(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);

    const handlePagination = (_, page) => {
        return getData(page);
    }

    const onDetailData = (code) => {
        return navigate(`${code}`);
    }

    return (
        <WarehouseSpoil>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DATA SPOIL PERGUDANGAN</h3>
                                <span>Data Spoil Pergudangan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode Spoil</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Tanggal Spoil</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Petugas</span>
                                        </th>
                                        <th className="text-center">
                                            <span className="form-control">Status</span>
                                        </th>
                                        <th className="text-center table-border-end">
                                            <span className="form-control">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        spoil.map((data) => (
                                            <tr key={data.no}>
                                                <td className="text-center">{data.no}</td>
                                                <td>{data.code}</td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{data.date}</Moment>
                                                </td>
                                                <td>{data.employee}</td>
                                                <td className="text-center">
                                                    <span className="badge bg-green">Tervalidasi</span>
                                                </td>
                                                <td className="text-center">
                                                    <Button onClick={() => onDetailData(data.code)} variant="contained" size="small" color="success">
                                                        Detail
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </WarehouseSpoil>
    )
}

export default WarehouseSpoilData
