import React, { useEffect, useState } from 'react'
import Financial from '../Financial'
import { useDispatch } from 'react-redux'

import { getPurchaseTotalBySistemPembayaran } from '../../../models/purchase/purchase_total_m';
import { Button } from '@mui/material';
import Moment from 'react-moment';

import { NumericFormat } from 'react-number-format';
import Paginate from '../../pagination/Paginate'
import { useParams } from 'react-router-dom';

const FinancialHutang = () => {
    const dispatch = useDispatch();
    const { username } = useParams();

    const [hutang, setHutang] = useState([]);
    const [pagination, setPagination] = useState([]);

    const getData = async(page) => {
        try {
            const data = {
                status: 'tempo',
                page: page
            }
            const response = await dispatch(getPurchaseTotalBySistemPembayaran(data)).unwrap().catch((err) => {});
            setHutang(response.data);
            setPagination(response.pagination)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);

    const handlePagination = (_, page) => {
        return getData(page);
    }

    const handleDetail = (code) => {
        return window.location.href = `/${username}/financial/hutang/${code}`
    }

    return (
        <Financial>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>Data Laporan Hutang</h3>
                                <span>Rekap Laporan Hutang</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode Transaksi</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Tanggal Transaksi</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Supplier</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nominal</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Terbayar</span>
                                        </th>
                                        <th className="table-border-end text-center" style={{width: '10%'}}>
                                            <span className="form-control">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        hutang.map((data) => (
                                            <tr key={data.no}>
                                                <td className="text-center">{ data.no }</td>
                                                <td>{data.code}</td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{data.tgl_transaksi}</Moment>
                                                </td>
                                                <td>
                                                    {data.supplier}
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.total_pembayaran } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.nominal_bayar } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <Button onClick={() => handleDetail(data.code)} size="small" variant="contained" color="success">
                                                        Detail
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        {/* paginatio */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Halaman : { pagination.current_page }</span>
                            </div>
                            {
                                pagination.pagination ? 
                                <Paginate count={ pagination.total } page={pagination.current_page} onChange={ handlePagination } /> : ''
                            }
                            <div>
                                <span>Total : { pagination.total } List</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Financial>
    )
}

export default FinancialHutang
