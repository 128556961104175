import React from 'react'
import OperasionalBisnis from '../../views/operasional/bisnis/OperasionalBisnis'
import OperasionalNonBisnis from '../../views/operasional/non/OperasionalNonBisnis'
import OperasionalBisnisDetail from '../../views/operasional/bisnis/OperasionalBisnisDetail'
import OperasionalBisnisForm from '../../views/operasional/bisnis/OperasionalBisnisForm'
import OperasionalNonBisnisForm from '../../views/operasional/non/OperasionalNonBisnisForm'

function OperasionalController({ view }) {
    if (view === 'bisnis') {
        return (
            <OperasionalBisnis />
        )
    } else if(view === 'bisnis-detail') {
        return (
            <OperasionalBisnisDetail />
        )
    } else if(view === 'bisnis-form') {
        return (
            <OperasionalBisnisForm />
        )
    } else if (view === 'non-bisnis-form') {
        return (
            <OperasionalNonBisnisForm />
        )
    } else if (view === 'non-bisnis-data') {
        return (
            <OperasionalNonBisnis />
        )
    }
}

export default OperasionalController
