import React, { useEffect, useState } from 'react'
import RkapRealisasi from '../RkapRealisasi'
import { useDispatch } from 'react-redux'
import { NumericFormat } from 'react-number-format';
import { getRkapVariableRkap } from '../../../../models/rkap/rkap_variable_m';

const RkapComparasi = () => {
    const dispatch = useDispatch();
    const date = new Date();

    const [rkap, setRkap] = useState([]);

    const getRkap = async() => {
        try {
            const response = await dispatch(getRkapVariableRkap()).unwrap().catch((err) => {});
            setRkap(response.data)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getRkap()
    }, [])

    const total_pendapatan_bisnis = rkap.filter(rkap => rkap.status_bisnis === 'bisnis' && rkap.status_kas === 'masuk').reduce((i, j) => i + parseFloat(j.total_target), 0);
    const total_pengeluaran_bisnis = rkap.filter(rkap => rkap.status_bisnis === 'bisnis' && rkap.status_kas === 'keluar').reduce((i, j) => i + parseFloat(j.total_target), 0);
    const laba_kotor = total_pendapatan_bisnis - total_pengeluaran_bisnis;

    const total_pendapatan_non_bisnis = rkap.filter(rkap => rkap.status_bisnis === 'non' && rkap.status_kas === 'masuk').reduce((i, j) => i + parseFloat(j.total_target), 0);
    const total_pengeluaran_non_bisnis = rkap.filter(rkap => rkap.status_bisnis === 'non' && rkap.status_kas === 'keluar').reduce((i, j) => i + parseFloat(j.total_target), 0);

    const laba_bersih = laba_kotor + total_pendapatan_non_bisnis - total_pengeluaran_non_bisnis;


    // realisasi
    const r_total_pendapatan_bisnis = rkap.filter(rkap => rkap.status_bisnis === 'bisnis' && rkap.status_kas === 'masuk').reduce((i, j) => i + parseFloat(j.total_realisasi), 0);
    const r_total_pengeluaran_bisnis = rkap.filter(rkap => rkap.status_bisnis === 'bisnis' && rkap.status_kas === 'keluar').reduce((i, j) => i + parseFloat(j.total_realisasi), 0);
    const r_laba_kotor = r_total_pendapatan_bisnis - r_total_pengeluaran_bisnis;

    const r_total_pendapatan_non_bisnis = rkap.filter(rkap => rkap.status_bisnis === 'non' && rkap.status_kas === 'masuk').reduce((i, j) => i + parseFloat(j.total_realisasi), 0);
    const r_total_pengeluaran_non_bisnis = rkap.filter(rkap => rkap.status_bisnis === 'non' && rkap.status_kas === 'keluar').reduce((i, j) => i + parseFloat(j.total_realisasi), 0);

    const r_laba_bersih = r_laba_kotor + r_total_pendapatan_non_bisnis - r_total_pengeluaran_non_bisnis;
    return (
        <RkapRealisasi>
            <div className="row mt-3">
                <div className="col-md-1 mb-3">
                    
                </div>
                <div className="col-md-10 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-center mb-3">
                                <h3>
                                    KOMPARASI RKAP DAN REALISASI RKAP <br />
                                    BIDANG BIDANG PERDAGANGAN <br />
                                    TAHUN { date.getFullYear() }
                                </h3>
                            </div>

                            <div className="table-responsive">
                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th className="table-border-start text-center" style={{width: '5%'}}>
                                                <span className="form-control fw-bold">No</span>
                                            </th>
                                            <th>
                                                <span className="form-control fw-bold">Uraian</span>
                                            </th>
                                            <th style={{width: '30%'}}>
                                                <span className="form-control fw-bold">RKAP Tahun { date.getFullYear() }</span>
                                            </th>
                                            <th className="table-border-end" style={{width: '30%'}}>
                                                <span className="form-control fw-bold">Realisasi RKAP Tahun { date.getFullYear() }</span>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            rkap.filter(rkap => rkap.status_bisnis === 'bisnis').map((data, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <th className="text-center">{ index + 1 }</th>
                                                        <th colSpan={2}>{ data.name }</th>
                                                    </tr>
                                                    {
                                                        data.rkap_list.map((list, index) => (
                                                            <tr key={index}>
                                                                <td></td>
                                                                <td>{ list.name }</td>
                                                                <td>
                                                                    <NumericFormat 
                                                                        value={ list.total_target } 
                                                                        allowLeadingZeros 
                                                                        thousandSeparator="," 
                                                                        valueIsNumericString
                                                                        prefix="Rp. "
                                                                        displayType="text"
                                                                        renderText={(value) => <span>{value}</span>}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumericFormat 
                                                                        value={ list.total_realisasi } 
                                                                        allowLeadingZeros 
                                                                        thousandSeparator="," 
                                                                        valueIsNumericString
                                                                        prefix="Rp. "
                                                                        displayType="text"
                                                                        renderText={(value) => <span>{value}</span>}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    
                                                    {/* total per variable */}
                                                    <tr>
                                                        <th></th>
                                                        <th>Jumlah { data.name }</th>
                                                        <th>
                                                            <NumericFormat 
                                                                value={ data.total_target } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </th>
                                                        <th>
                                                            <NumericFormat 
                                                                value={ data.total_realisasi } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        }

                                        {/* laba kotor */}
                                        <tr>
                                            <th></th>
                                            <th className="text-uppercase">LABA KOTOR PERUSAHAAN</th>
                                            <th>
                                                <NumericFormat 
                                                    value={ laba_kotor } 
                                                    allowLeadingZeros 
                                                    thousandSeparator="," 
                                                    valueIsNumericString
                                                    prefix="Rp. "
                                                    displayType="text"
                                                    renderText={(value) => <span>{value}</span>}
                                                />
                                            </th>
                                            <th>
                                                <NumericFormat 
                                                    value={ r_laba_kotor } 
                                                    allowLeadingZeros 
                                                    thousandSeparator="," 
                                                    valueIsNumericString
                                                    prefix="Rp. "
                                                    displayType="text"
                                                    renderText={(value) => <span>{value}</span>}
                                                />
                                            </th>
                                        </tr>

                                        {
                                            rkap.filter(rkap => rkap.status_bisnis === 'non').map((data, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <th className="text-center">{ rkap.filter(rkap => rkap.status_bisnis).length + (index + 1) }</th>
                                                        <th colSpan={2}>{ data.name }</th>
                                                    </tr>
                                                    {
                                                        data.rkap_list.map((list, index) => (
                                                            <tr key={index}>
                                                                <td></td>
                                                                <td>{ list.name }</td>
                                                                <td>
                                                                    <NumericFormat 
                                                                        value={ list.total_target } 
                                                                        allowLeadingZeros 
                                                                        thousandSeparator="," 
                                                                        valueIsNumericString
                                                                        prefix="Rp. "
                                                                        displayType="text"
                                                                        renderText={(value) => <span>{value}</span>}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumericFormat 
                                                                        value={ list.total_realisasi } 
                                                                        allowLeadingZeros 
                                                                        thousandSeparator="," 
                                                                        valueIsNumericString
                                                                        prefix="Rp. "
                                                                        displayType="text"
                                                                        renderText={(value) => <span>{value}</span>}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    
                                                    {/* total per variable */}
                                                    <tr>
                                                        <th></th>
                                                        <th>Jumlah { data.name }</th>
                                                        <th>
                                                            <NumericFormat 
                                                                value={ data.total_target } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </th>
                                                        <th>
                                                            <NumericFormat 
                                                                value={ data.total_realisasi } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        }

                                        {/* laba bersih */}
                                        <tr>
                                            <th></th>
                                            <th className="text-uppercase">LABA BERSIH PERUSAHAAN SEBELUM PAJAK</th>
                                            <th>
                                                <NumericFormat 
                                                    value={ laba_bersih } 
                                                    allowLeadingZeros 
                                                    thousandSeparator="," 
                                                    valueIsNumericString
                                                    prefix="Rp. "
                                                    displayType="text"
                                                    renderText={(value) => <span>{value}</span>}
                                                />
                                            </th>
                                            <th>
                                                <NumericFormat 
                                                    value={ r_laba_bersih } 
                                                    allowLeadingZeros 
                                                    thousandSeparator="," 
                                                    valueIsNumericString
                                                    prefix="Rp. "
                                                    displayType="text"
                                                    renderText={(value) => <span>{value}</span>}
                                                />
                                            </th>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-1 mb-3">
                    
                </div>
            </div>
        </RkapRealisasi>
    )
}

export default RkapComparasi
