import axios from "axios";

const instanceapi = axios.create({
    baseURL: 'https://api-dhspdg.perumdajepara.co.id/api',
    withCredentials: true
})

instanceapi.defaults.headers.common['token_access'] = '$2y$12$VQ5nBJwty/0Lbb2YTPXL9ujrln8LFyXFnjcOGsXLTFjPqqX477H3u';
instanceapi.defaults.headers.common['Content-Type'] = 'multipart/form-data';

export default instanceapi;