import React, { useEffect, useState } from 'react'
import WarehousePenerimaan from '../WarehousePenerimaan'
import { useDispatch } from 'react-redux'
import { getReportPenerimaan } from '../../../../models/penerimaan/report_penerimaan_m';
import Moment from 'react-moment';
import { NumericFormat } from 'react-number-format';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Paginate from '../../../pagination/Paginate';

const WarehousePenerimaanData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [penerimaan, setPenerimaan] = useState([]);
    const [pagination, setPagination] = useState([]);

    const getData = async(page) => {
        try {
            const response = await dispatch(getReportPenerimaan(page)).unwrap().catch((err) => {});
            setPenerimaan(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);

    const handlePagination = (_, page) => {
        return getData(page);
    }

    const onDetail = (code) => {
        return navigate(`${code}`)
    }

    return (
        <WarehousePenerimaan>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DATA PENERIMAAN PEMBELIAN</h3>
                                <span>List Data Penerimaan Pembelian</span>
                            </div>
                        </div>

                        <div className="mt-3">
                            <div className="table-responsive mb-3">
                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th className="table-border-start text-center" style={{width: '5%'}}>
                                                <span className="form-control">No</span>
                                            </th>
                                            <th>
                                                <span className="form-control">Kode Pembelian</span>
                                            </th>
                                            <th>
                                                <span className="form-control">Tanggal Penerimaan</span>
                                            </th>
                                            <th>
                                                <span className="form-control">Supplier</span>
                                            </th>
                                            <th>
                                                <span className="form-control">Total Pembayaran</span>
                                            </th>
                                            <th className="text-center">
                                                <span className="form-control">Status</span>
                                            </th>
                                            <th className="table-border-end text-center" style={{width: '10%'}}>
                                                <span className="form-control">Action</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            penerimaan.map((data) => (
                                                <tr key={data.no}>
                                                    <td className="text-center">{data.no}</td>
                                                    <td>{data.code}</td>
                                                    <td>
                                                        <Moment format="dddd, DD MMMM YYYY">{data.tgl_penerimaan}</Moment>
                                                    </td>
                                                    <td>{data.supplier}</td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ data.total_pembayaran } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        {
                                                            data.retur === 'iya' ? 
                                                            <span className="badge bg-danger">Tidak Sesuai</span> : 
                                                            <span className="badge bg-success">Sesuai</span>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        <Button onClick={() => onDetail(data.code)} size="small" variant="contained" color="success">
                                                            Detail
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* pagination */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <span>Halaman : { pagination.current_page }</span>
                                </div>
                                {
                                    pagination.pagination ? 
                                    <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} /> : ''
                                }
                                <div>
                                    <span>Total : { pagination.total } Data</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WarehousePenerimaan>
    )
}

export default WarehousePenerimaanData
