import React, { useEffect, useState } from 'react'
import SalesSpoil from '../SalesSpoil'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getSalesMarketingSpoilByValidasi } from '../../../../models/sales/marketing/sales_marketing_spoil_m';

import Paginate from '../../../pagination/Paginate'
import Moment from 'react-moment';

import { NumericFormat } from 'react-number-format';
import { Button } from '@mui/material';

const SalesSpoilValidation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [spoil, setSpoil] = useState([]);
    const [pagination, setPagination] = useState([]);

    const getData = async(page) => {
        try {
            const data = { status: 'tidak', page: page }
            const response = await dispatch(getSalesMarketingSpoilByValidasi(data)).unwrap().catch((err) => {});
            setSpoil(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);

    const handlePagination = (_, page) => {
        return getData(page)
    }

    const onDetail = (code) => {
        return navigate(`${code}`)
    }   

    return (
        <SalesSpoil>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th className="table-border-start text-center" style={{width: '5%'}}>
                                            <span className="form-control">No</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Kode Spoil</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Tanggal</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Marketing</span>
                                        </th>
                                        <th>
                                            <span className="form-control">Nominal</span>
                                        </th>
                                        <th className="text-center" style={{width: '13%'}}>
                                            <span className="form-control">Status</span>
                                        </th>
                                        <th className="table-border-end text-center" style={{width: '10%'}}>
                                            <span className="form-control">Action</span>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        spoil.map((data) => (
                                            <tr key={data.no}>
                                                <td className="text-center">{data.no}</td>
                                                <td>{data.code}</td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{data.date}</Moment>
                                                </td>
                                                <td>{data.marketing}</td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.nominal } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <span className="badge bg-orange">Belum Tervalidasi</span>
                                                </td>
                                                <td className="text-center">
                                                    <Button onClick={() => onDetail(data.code)} size="small" variant="contained" color="primary">
                                                        validasi
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        {/* pagination */}
                        <div className="d-flex justify-content-between align-middle">
                            <div>
                                <span>Halaman : { pagination.current_page }</span>
                            </div>
                            {
                                pagination.pagination ? 
                                <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} /> : ''
                            }
                            <div>
                                <span>Total : { pagination.total } List</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SalesSpoil>
    )
}

export default SalesSpoilValidation
