import React from 'react'
import DeliveryScheduleForm from '../../views/delivery/schedule/form/DeliveryScheduleForm'

function DeliveryScheduleController({ view }) {
    if (view === 'form') {
        return (
            <DeliveryScheduleForm />
        )
    }
}

export default DeliveryScheduleController
