import React from 'react'
import WarehousePenerimaanValidasi from '../../views/warehouse/penerimaan/validasi/WarehousePenerimaanValidasi'
import WarehousePenerimaanData from '../../views/warehouse/penerimaan/data/WarehousePenerimaanData'
import WarehousePenerimaanValidasiDetail from '../../views/warehouse/penerimaan/validasi/WarehousePenerimaanValidasiDetail'
import WarehousePenerimaanDataDetail from '../../views/warehouse/penerimaan/data/WarehousePenerimaanDataDetail'

function WarehousePenerimaanController({ view }) {
    if (view === 'validasi') {
        return (
            <WarehousePenerimaanValidasi />
        )
    } else if (view === 'validasi-detail') {
        return (
            <WarehousePenerimaanValidasiDetail />
        )
    } else if (view === 'data-detail') {
        return (
            <WarehousePenerimaanDataDetail />
        )
    } else {
        return (
            <WarehousePenerimaanData />
        )
    }
}

export default WarehousePenerimaanController
