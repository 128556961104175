import React from 'react'
import WarehouseSpoilForm from '../../views/warehouse/spoil/form/WarehouseSpoilForm'
import WarehouseSpoilData from '../../views/warehouse/spoil/data/WarehouseSpoilData'
import WarehouseSpoilValidasi from '../../views/warehouse/spoil/validasi/WarehouseSpoilValidasi'
import WarehouseSpoilValidasiDetail from '../../views/warehouse/spoil/validasi/WarehouseSpoilValidasiDetail'
import WarehouseSpoilDataDetail from '../../views/warehouse/spoil/data/WarehouseSpoilDataDetail'

function WarehouseSpoilController({ view }) {
    if (view === 'form') {
        return (
            <WarehouseSpoilForm />
        )
    } else if (view === 'validasi') {
        return (
            <WarehouseSpoilValidasi />
        )
    } else if (view === 'validasi-detail') {
        return (
            <WarehouseSpoilValidasiDetail />
        )
    } else if (view === 'data-detail') {
        return (
            <WarehouseSpoilDataDetail />
        )
    } else {
        return (
            <WarehouseSpoilData />
        )
    }
}

export default WarehouseSpoilController
