import React from 'react'
import RequestSale from '../../views/warehouse/permintaan/RequestSale'
import RequestSaleDetail from '../../views/warehouse/permintaan/RequestSaleDetail'

function WarehouseRequestController({ view }) {
    if (view === 'request-sale') {
        return (
            <RequestSale />
        )
    } else if (view === 'request-sale-detail') {
        return (
            <RequestSaleDetail />
        )
    }
}

export default WarehouseRequestController
