import React from 'react'
import ScreenSelectRole from '../../views/screen/ScreenSelectRole'

function ScreenSelectRoleController() {
    return (
        <ScreenSelectRole />
    )
}

export default ScreenSelectRoleController
