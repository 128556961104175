import React, { useEffect, useState } from 'react'
import { ReplyAll } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import Mitra from '../../Mitra'
import { useDispatch } from 'react-redux'
import { getStoreDetail, updateStoreData, updateStoreImage } from '../../../../models/store/store_m'
import { Button, TextField } from '@mui/material'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Camera, CameraResultType } from '@capacitor/camera'
import { b64toBlob, imageResizer } from '../../../../utils'
import toast from 'react-hot-toast'
import Loading from '../../../loading/Loading'

const StoreDataDetail = () => {
    const { username, code } = useParams();
    const dispatch = useDispatch();

    const handleBack = () => {
        return window.location.href = `/${username}/mitra/toko/data`
    }

    const [store, setStore] = useState([]);
    const [input, setInput] = useState({
        code: code, name: '', address: '', phone: '', lat: '', long: '', docs: '', desc: ''
    })
    const [picture, setPicture] = useState('');
    const [error, setError] = useState([]);
    const [loading, setLoadig] = useState(false);

    const getDetail = async() => {
        try {
            const response = await dispatch(getStoreDetail(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                const data = response.data;
                setStore(data);
                setInput({
                    code: code, name: data.name, address: data.address, phone: data.phone, desc: data.desc,
                    lat: `${data.lat ? data.lat : ''}`, long: `${data.long ? data.long : ''}`,
                })
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail();
    }, []);

    const takePhoto = async() => {
        try {
            const image = await Camera.getPhoto({
                quality: 30,
                allowEditing: true,
                resultType: CameraResultType.Base64
            })

            const imageUrl = image.base64String;
            setPicture('data:image/png;base64,' + imageUrl);

            const blob = b64toBlob(imageUrl, 'image/png');
            const file = new File([blob], 'image.png', {
                type: blob.type,
                lastModified: new Date().getTime()
            })

            const resize = await imageResizer(file, 240, 240);

            const data = {
                code: code,
                image: resize
            }

            const response = await dispatch(updateStoreImage(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Data berhasil diupdate');
                setTimeout(() => {
                    return window.location.reload();
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const onUpdate = async(e) => {
        e.preventDefault();
        setLoadig(true);

        const data = {...input}
        try {
            const response = await dispatch(updateStoreData(data)).unwrap().catch((err) => {});
            if (response.status === 200) {
                toast.success('Data berhasil diupdate');
                setTimeout(() => {
                    return window.location.reload();
                }, 1200);
            }

            if (response.status === 400) {
                setLoadig(false);
                toast.error('Periksa kembali data Anda')
                setError(response.message);
                setTimeout(() => {
                    setError([])
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <Mitra>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3> { store.name ? store.name : '' } </h3>
                                <span>Detail Data Toko</span>
                            </div>
                            <div>
                                <span onClick={handleBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <form onSubmit={onUpdate}>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <a href={ store.image } target="_blank">
                                            <img src={ picture ? picture : store.image } alt="" className="photo-profile" />
                                        </a>
                                        <div className="mb-3">
                                            <span onClick={takePhoto} className="btn-update-foto">
                                                Update Foto
                                            </span>
                                        </div>
                                        <div className="mb-3">
                                            <span style={{fontSize: '16px', fontWeight: 'bold'}} >{ store.name ? store.name : '' }</span> <br />
                                            <span>( { store.category } ) </span>
                                        </div>
                                        <div className="mb-3">
                                            <a href={ store.docs } target="_blank">
                                                Lihat Dokumen
                                            </a>
                                        </div>
                                        <div>
                                            <a href={`https://wa.me/${store.phone}`} target="_blank" className="badge bg-success">
                                                { store.phone }
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Nama Toko <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Nama Toko"
                                            value={input.name}
                                            onChange={(e) => setInput({...input, name: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Alamat <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Alamat"
                                            value={input.address}
                                            onChange={(e) => setInput({...input, address: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Nomor Handphone <span className="text-danger">*</span></label>
                                        <PhoneInput
                                            country={'id'}
                                            value={input.phone}
                                            onChange={phone => setInput({...input, phone: phone})}
                                            inputProps={{
                                                required: true,
                                                autoFocus: true
                                            }}
                                            placeholder='+62 858 *** *** ***'
                                        />
                                        <small className="text-danger">{error.phone ? error.phone : ''}</small>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Latitude</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Latitude"
                                            value={input.lat}
                                            onChange={(e) => setInput({...input, lat: e.target.value})}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Longitude</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Longitude"
                                            value={input.long}
                                            onChange={(e) => setInput({...input, long: e.target.value})}
                                        />
                                    </div>
                                    <div>
                                        <label className="form-label form-label-m-0">Keterangan</label>
                                        <textarea placeholder="Tulis Keterangan" style={{height: '45px'}} value={input.desc} onChange={(e) => setInput({...input, desc:e.target.value})} className="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2">
                                        <label className="form-label form-label-m-0">Upload Dokumen</label>
                                        <input type="file" className="form-control" onChange={(e) => setInput({...input, docs: e.target.files[0]})} />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button variant="contained" color="success" type="submit">
                                            Update Data
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Mitra>
    )
}

export default StoreDataDetail
