import React from 'react'

import Layouts from '../layouts/Layouts'
import { Link, useLocation, useParams } from 'react-router-dom'
import { PlaylistAddCheckCircle, PlaylistAddCircle } from '@mui/icons-material';
import { Toaster } from 'react-hot-toast';

const Rkap = ({ children }) => {
    const { username } = useParams();
    const location = useLocation();
    const path = location.pathname;
    const split = path.split('/');

    return (
        <Layouts>
            <Toaster position="top-center" />
             <div>
                <h1>RKAP</h1>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="sub-menu">
                            <ul>
                                <li>
                                    <Link to={`/${username}/rkap/variable/data`} className={` sub-menu-item ${split[3] === 'variable'? 'active' : ''} `}>
                                        <div className="me-2">
                                            <PlaylistAddCircle />
                                        </div>
                                        <span>Variabel RKAP</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${username}/rkap/input/data`} className={` sub-menu-item ${split[3] === 'input'? 'active' : ''} `}>
                                        <div className="me-2">
                                            <PlaylistAddCircle />
                                        </div>
                                        <span>Input RKAP</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${username}/rkap/realisasi/rkap`} className={` sub-menu-item ${split[3] === 'realisasi'? 'active' : ''} `} >
                                        <div className="me-2">
                                            <PlaylistAddCheckCircle />
                                        </div>
                                        <span>Realisasi</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            { children }
        </Layouts>
    )
}

export default Rkap
