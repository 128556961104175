import React, { useEffect, useState } from 'react'
import Sales from '../Sales'
import { Button, MenuItem, Select, TextField } from '@mui/material'
import { Alarm, BackupTable, CloudDownload, CloudUpload } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getSalesMarketing, getSalesMarketingFilterData } from '../../../models/sales/marketing/sales_marketing_m'

import Paginate from '../../pagination/Paginate'
import Moment from 'react-moment'
import { NumericFormat } from 'react-number-format';

import { listYearNext, listYearPrev } from './date/year'
import months from './date/month'

const SalesData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { username } = useParams();

    const onDetail = (code) => {
        return window.location.href = `data/${code}`
    }

    const clickKasir = () => {
        return navigate(`/${username}/sales/report/form`)
    }

    const [sales, setSales] = useState([]);
    const [pagination, setPagination] = useState([]);

    const date = new Date();
    const year = date.getFullYear();
    const monthNow = date.getMonth();

    const [statusFilter, setStatuFilter] = useState(false);

    const [filter, setFilter] = useState({
        year: year, month: monthNow + 1 , name: ' '
    })

    const getData = async(page) => {
        try {
            const response = await dispatch(getSalesMarketing(page)).unwrap().catch((err) => {});
            setSales(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    const filterData = async(page) => {
        try {
            if (filter.year === 'all') {
                filter.year = year;
            }
            const data = {
                ...filter,
                page: page
            }

            const response = await dispatch(getSalesMarketingFilterData(data)).unwrap().catch((err) => {});
            setSales(response.data);
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    // filter

    useEffect(() => {
        getData(1)
    }, []);

    const handlePagination = (_, page) => {
        if (statusFilter === false) {
            return getData(page);
        } else {
            return filterData(page)
        }
    }

    const goTempo = () => {
        return navigate(`/${username}/sales/tempo/data`)
    }

    const handleChangeYear = (e) => {
        if (e.target.value === 'all') {
            setFilter({...filter, year: 'all', month: 'all'})
        } else {
            setFilter({...filter, year: e.target.value, month: 1})
        }
    }

    const onFilter = async(e) => {
        e.preventDefault();
        setStatuFilter(true);
        return filterData(1);
    }

    const unlockFilter = () => {
        return window.location.reload()
    }

    return (
        <Sales>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DATA PENJUALAN</h3>
                                <span>List Data Penjualan</span>
                            </div>
                            <div className="d-flex">
                                <div onClick={clickKasir} className="text-center me-2" style={{cursor: 'pointer'}}>
                                    <span className="btn btn-icon btn-orange" style={{padding: '.4rem .4rem'}}>
                                        <BackupTable sx={{width: '1.25rem', height: '1.25rem'}} />
                                    </span>
                                    <span style={{fontSize: '12px'}} className="d-block">Kasir Marketing</span>
                                </div>

                                <div onClick={goTempo} className="text-center" style={{cursor: 'pointer'}}>
                                    <span className="btn btn-icon btn-info" style={{padding: '.4rem .4rem'}}>
                                        <Alarm sx={{width: '1.25rem', height: '1.25rem'}} />
                                    </span>
                                    <span style={{fontSize: '12px'}} className="d-block">Tempo</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-start mb-3">
                            <div></div>
                            <div>
                                <div style={{lineHeight: '60%'}} className="text-center">
                                    <h2>
                                        DATA PENJUALAN PERUMDA ANEKA USAHA <br /> 
                                        KABUPATEN JEPARA <br />
                                        Tahun { filter.year }
                                    </h2>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="text-center me-2" style={{ cursor: 'pointer' }}>
                                    <span className="btn btn-icon btn-success" style={{ padding: '.4rem .4rem' }}>
                                        <CloudDownload sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </span>
                                    <span style={{ fontSize: '12px' }} className="d-block">Import</span>
                                </div>

                                <div className="text-center me-2" style={{ cursor: 'pointer' }}>
                                    <span className="btn btn-icon btn-pink" style={{ padding: '.4rem .4rem' }}>
                                        <CloudUpload sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </span>
                                    <span style={{ fontSize: '12px' }} className="d-block">Ekspor</span>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={onFilter}>
                            <div className="row align-items-end">
                                <div className="col-md-3 mb-3">
                                    <label className="form-label form-label-m-0">Tahun</label>
                                    <Select
                                        size="small"
                                        fullWidth
                                        placeholder="Tahun"
                                        displayEmpty
                                        value={filter.year}
                                        onChange={handleChangeYear}
                                        required
                                        disabled={ statusFilter ? true : false }
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {
                                            listYearPrev.map((year, index) => (
                                                <MenuItem key={index} value={year}>{ year }</MenuItem>
                                            ))
                                        }
                                        {
                                            listYearNext.map((year, index) => (
                                                <MenuItem key={index} value={year}>{year}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="form-label form-label-m-0">Bulan</label>
                                    <Select
                                        size="small"
                                        fullWidth
                                        displayEmpty
                                        value={filter.month}
                                        onChange={(e) => setFilter({...filter, month: e.target.value})}
                                        disabled={ filter.year === 'all' || statusFilter === true ? true : false }
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {
                                            months.map((month, index) => (
                                                <MenuItem key={index} value={month.id} >{ month.label }</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </div>

                                <div className="col-md-5 mb-3">
                                    <label className="form-label form-label-m-0">Cari Marketing</label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Cari Nama Marketing"
                                        onChange={(e) => setFilter({...filter, name: e.target.value})}
                                        disabled={ statusFilter ? true : false }
                                    />
                                </div>

                                <div className="col-md-1 mb-3">
                                    {
                                        statusFilter ? 
                                        <Button onClick={unlockFilter} sx={{padding: '8.5px 14px'}} variant="contained" fullWidth color="error">
                                            Unlock
                                        </Button> : 
                                        <Button type="submit" sx={{padding: '8.5px 14px'}} variant="contained" fullWidth color="primary">
                                            Filter
                                        </Button>
                                    }
                                </div>
                            </div>
                        </form>
                        
                        <div className="table-responsive mb-3">
                            <table className="table table-borderless align-middle">
                                <thead>
                                    <tr>
                                        <th className="text-center table-border-start" style={{width: '5%'}}>
                                            <span className="form-control fw-bold">
                                                No
                                            </span>
                                        </th>
                                        <th style={{width: '15%'}}>
                                            <span className="form-control fw-bold">
                                                Kode Transaksi
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Tanggal Transaksi
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Marketing
                                            </span>
                                        </th>
                                        <th>
                                            <span className="form-control fw-bold">
                                                Nominal
                                            </span>
                                        </th>
                                        <th className="text-center">
                                            <span className="form-control fw-bold">
                                                Validasi Gudang
                                            </span>
                                        </th>
                                        <th className="text-center">
                                            <span className="form-control fw-bold">
                                                Status
                                            </span>
                                        </th>
                                        <th style={{width: '15%'}} className="text-center table-border-end">
                                            <span className="form-control fw-bold">
                                                Action
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sales.map((data) => (
                                            <tr key={data.no}>
                                                <td className="text-center">{data.no}</td>
                                                <td>{data.code}</td>
                                                <td>
                                                    <Moment format="dddd, DD MMMM YYYY">{data.tgl_transaksi}</Moment>
                                                </td>
                                                <td>{data.marketing}</td>
                                                <td>
                                                    <NumericFormat 
                                                        value={ data.total_pembayaran } 
                                                        allowLeadingZeros 
                                                        thousandSeparator="," 
                                                        valueIsNumericString
                                                        prefix="Rp. "
                                                        displayType="text"
                                                        renderText={(value) => <span>{value}</span>}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        data.status_validasi === 'tidak' ? 
                                                        <span className="badge bg-orange">Menunggu</span> :
                                                        <span className="badge bg-green">Tervalidasi</span>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        data.status === 'process' ? 
                                                        <span className="badge bg-orange">Proses</span> : 
                                                        data.status === 'success' ? 
                                                        <span className="badge bg-green">Berhasil</span> : 
                                                        <span className="badge bg-danger">Di tolak</span>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    <Button disabled={ data.status_accept === 'wait' ? true: false } onClick={() => onDetail(data.code)} size="small" variant="contained" color="primary" sx={{mr: 2}}>
                                                        Detail
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        {/* pagination */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Halaman : { pagination.current_page ? pagination.current_page : '' }</span>
                            </div>
                            {
                                pagination.pagination ? 
                                <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} /> : ''
                            }
                            <div>
                                <span>Total : { pagination.total } Transaksi</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Sales>
    )
}

export default SalesData
