import React from 'react'
import RkapInputForm from '../../views/rkap/input/form/RkapInputForm'
import RkapInputData from '../../views/rkap/input/data/RkapInputData'
import RkapVariable from '../../views/rkap/variable/RkapVariable'

function RkapController({ view }) {
    if (view === 'input-form') {
        return (
            <RkapInputForm />
        )
    } else if (view === 'input-variable') {
        return (
            <RkapVariable />
        )
    } else {
        return (
            <RkapInputData />
        )
    }
}

export default RkapController
