import React, { useEffect, useState } from 'react'
import WarehouseRetur from '../WarehouseRetur'
import { useDispatch } from 'react-redux'
import { getPurchaseRetur } from '../../../../models/purchase/purchase_retur_m';

import Paginate from '../../../pagination/Paginate'
import Moment from 'react-moment';
import { NumericFormat } from 'react-number-format';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const WarehouseReturData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [retur, setRetur] = useState([]);
    const [pagination, setPagination] = useState([]);

    const getData = async(page) => {
        try {
            const response = await dispatch(getPurchaseRetur(page)).unwrap().catch((err) => {});
            setRetur(response.data)
            setPagination(response.pagination);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1);
    }, []);
    
    const handlePagination = (_, page) => {
        return getData(1)
    }

    const onDetail = (code) => {
        return navigate(`${code}`)
    }

    return (
        <WarehouseRetur>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3>DATA RETUR PEMBELIAN</h3>
                                <span>List Data Retur Pembelian</span>
                            </div>
                        </div>
                        
                        <div className="mt-3">
                            <div className="table-responsive mb-3">
                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th className="table-border-start text-center" style={{width: '5%'}}>
                                                <span className="form-control">No</span>
                                            </th>
                                            <th>
                                                <span className="form-control">Kode Retur</span>
                                            </th>
                                            <th>
                                                <span className="form-control">Tanggal Validasi</span>
                                            </th>
                                            <th>
                                                <span className="form-control">Supplier</span>
                                            </th>
                                            <th>
                                                <span className="form-control">Nominal</span>
                                            </th>
                                            <th className="table-border-end text-center" style={{width: '10%'}}>
                                                <span className="form-control">Action</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            retur.map((data) => (
                                                <tr key={data.no}>
                                                    <td className="text-center">{data.no}</td>
                                                    <td>{data.code}</td>
                                                    <td>
                                                        <Moment format="dddd, DD MMMM YYYY">{data.tgl_validasi}</Moment>
                                                    </td>
                                                    <td>{data.supplier}</td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ data.nominal } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <Button onClick={() => onDetail(data.code)} size="small" variant="contained" color="success">
                                                            Detail
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* pagination */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <span>Halaman : { pagination.current_page }</span>
                                </div>
                                {
                                    pagination.pagination ? 
                                    <Paginate count={pagination.total} page={pagination.current_page} onChange={handlePagination} /> : ''
                                }
                                <div>
                                    <span>Total : { pagination.total } Data</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WarehouseRetur>
    )
}

export default WarehouseReturData
