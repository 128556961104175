import React, { useEffect, useState } from 'react'
import SalesMarketing from '../SalesMarketing'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { ReplyAll } from '@mui/icons-material';
import { getDetailSalesMarketing } from '../../../../models/sales/marketing/sales_marketing_m';
import toast from 'react-hot-toast';
import Loading from '../../../loading/Loading';
import Sales from '../../Sales';
import Moment from 'react-moment';

import { NumericFormat } from 'react-number-format';

const SalesMarketingDataDetail = () => {
    const dispatch = useDispatch();
    const { username, code } = useParams();
    const url_back = `/${username}/sales/marketing/data`

    const onBack = () => {
        return window.location.href = url_back
    }
    
    const [loading, setLoading] = useState(false)
    const [sales, setSales] = useState([]);
    const [salesList, setSalesList] = useState([]);

    const getDetail = async() => {
        try {
            const response = await dispatch(getDetailSalesMarketing(code)).unwrap().catch((err) => {});
            if (response.status === 200) {
                setSales(response.data['sales']);
                setSalesList(response.data['sales_list'])
            }

            if (response.status === 404) {
                setLoading(true)
                toast.error('Data yang Anda Cari Tidak Ditemukan');
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDetail()
    }, [])

    const sub_total = salesList.reduce((i, j) => i + parseFloat(j.sub_total), 0)

    let ppn = 0;
    if (sales.status_ppn === 'ppn') {
        ppn = (11 / 100) * sub_total
    }

    return (
        <Sales>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: '60%'}}>
                                <h3 className="text-uppercase">Detail Penjualan { code }</h3>
                                <span>Detail Penjualan Marketing</span>
                            </div>
                            <div>
                                <span onClick={onBack} className="px-1 py-2 rounded-circle" style={{backgroundColor: 'orange', cursor: 'pointer'}}>
                                    <ReplyAll  sx={{color: '#ffffff'}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row mt-3">
                <div className="col-md-9 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive mb-3">
                                <table className="table align-middle table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <th>Kode Transaksi</th>
                                            <td>: { code }</td>

                                            <th>Nama Marketing</th>
                                            <td>: { sales.marketing }</td>

                                            <th>Tempo</th>
                                            <td>
                                                : {
                                                    sales.status_tempo === 'true' ? 
                                                    <span className="badge bg-pink">Tempo</span> :
                                                    <span className="badge bg-info">Tunai</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Transaksi</th>
                                            <td>
                                                : <Moment format="dddd, DD MMMM YYYY">{ sales.date }</Moment>
                                            </td>

                                            <th>Validasi Gudang</th>
                                            <td>
                                                : {
                                                    sales.status_gudang === 'acc' ?
                                                    <span className="badge bg-green">Diterima</span> :
                                                    sales.status_gudang === 'reject' ? 
                                                    <span className="badge bg-danger">Ditolak</span> :
                                                    <span className="badge bg-orange">Proses</span>
                                                }
                                            </td>
                                            
                                            <th>Metode Pembayaran</th>
                                            <td>
                                                : {
                                                    sales.payment_method === 'cash' ? 
                                                    <span className="badge bg-green">Cash</span> :
                                                    <span className="badge bg-info">Transfer</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Petugas Kasir</th>
                                            <td>: { sales.employee }</td>

                                            <th>Pengiriman</th>
                                            <td>
                                                : {
                                                    sales.status_jadwal_pengiriman === 'acc' ?
                                                    <span className="badge bg-green">Diterima</span> :
                                                    sales.status_jadwal_pengiriman === 'reject' ? 
                                                    <span className="badge bg-danger">Ditolak</span> :
                                                    <span className="badge bg-orange">Proses</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Waktu</th>
                                            <td>: { sales.time } WIB</td>

                                            <th>Pembayaran</th>
                                            <td>
                                                : {
                                                    sales.status_pembayaran === 'lunas' ? 
                                                    <span className="badge bg-green">Lunas</span> :
                                                    <span className="badge bg-orange">Belum Lunas</span>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="table-responsive">
                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th className="table-border-start text-center" style={{width: '5%'}}>
                                                <span className="form-control fw-bold">No</span>
                                            </th>
                                            <th>
                                                <span className="form-control fw-bold">Kode Produk</span>
                                            </th>
                                            <th>
                                                <span className="form-control fw-bold">Produk</span>
                                            </th>
                                            <th className="text-center" style={{width: '15%'}}>
                                                <span className="form-control fw-bold">Status Harga</span>
                                            </th>
                                            <th className="text-center" style={{width: '10%'}}>
                                                <span className="form-control fw-bold">Jumlah</span>
                                            </th>
                                            <th>
                                                <span className="form-control fw-bold">Harga Satuan</span>
                                            </th>
                                            <th className="table-border-end">
                                                <span className="form-control fw-bold">Sub Total</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            salesList.map((data, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{ index + 1 }</td>
                                                    <td>{ data.code_product }</td>
                                                    <td>{ data.product }</td>
                                                    <td className="text-center">
                                                        {
                                                            data.status_harga === 'harga_1' ? 
                                                            <span className="badge bg-green">Harga 1</span> :
                                                            data.status_harga === 'harga_2' ? 
                                                            <span className="badge bg-green">Harga 2</span> : 
                                                            data.status_harga === 'harga_3' ? 
                                                            <span className="badge bg-green">Harga 3</span> : 
                                                            <span className="badge bg-green">Harga 4</span>
                                                        }
                                                    </td>
                                                    <td className="text-center">{ data.qty }</td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ data.unit_price } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        /> 
                                                    </td>
                                                    <td>
                                                        <NumericFormat 
                                                            value={ data.sub_total } 
                                                            allowLeadingZeros 
                                                            thousandSeparator="," 
                                                            valueIsNumericString
                                                            prefix="Rp. "
                                                            displayType="text"
                                                            renderText={(value) => <span>{value}</span>}
                                                        /> 
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                        <tr>
                                            <th colSpan={6} className="text-uppercase">Sub Total Transaksi</th>
                                            <th>
                                                <NumericFormat 
                                                    value={ sub_total } 
                                                    allowLeadingZeros 
                                                    thousandSeparator="," 
                                                    valueIsNumericString
                                                    prefix="Rp. "
                                                    displayType="text"
                                                    renderText={(value) => <span>{value}</span>}
                                                /> 
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-price mb-2">
                                <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                    <span>
                                        Total Transaksi
                                    </span>
                                    <span>
                                        { code }
                                    </span>
                                </div>

                                <div className="text-center price-total">
                                    <NumericFormat 
                                        value={ sales.total_pembayaran } 
                                        allowLeadingZeros 
                                        thousandSeparator="," 
                                        valueIsNumericString
                                        prefix="Rp. "
                                        displayType="text"
                                        renderText={(value) => <span>{value}</span>}
                                    />
                                </div>
                            </div>

                            <div className="mb-2">
                                <label className="form-label">PPN</label>
                                <span className="form-control fw-bold">
                                    <NumericFormat 
                                        value={ ppn } 
                                        allowLeadingZeros 
                                        thousandSeparator="," 
                                        valueIsNumericString
                                        prefix="Rp. "
                                        displayType="text"
                                        renderText={(value) => <span>{value}</span>}
                                    />
                                </span>
                            </div>

                            <div className="mb-2">
                                <label className="form-label">File</label>
                                <a href={ sales.file } target="_blank" className="form-control">
                                    Lihat File Transaksi
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Sales>
    )
}

export default SalesMarketingDataDetail
